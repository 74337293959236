import React from "react";

import Practices from "../components/Practice";
import axios from "axios";
import PracticeForm from "../components/FormPractice";

import BestForm from "../components/FormBest";

class PracticeList extends React.Component {
  state = {
    practices: [],
  };

  componentDidMount() {
    axios.get("https://www.sptapp.eu/api2/v1/Prac/").then((res) => {
      this.setState({
        practices: res.data,
      });
      console.log(res.data);
    });
  }

  render() {
    return (
      <div>
        <Practices data={this.state.practices} />
        <br />
      </div>
    );
  }
}

export default PracticeList;

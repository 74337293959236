// ContactForm.js

import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import axios from "axios";
//import Swal from "sweetalert2";
import { axiosApiInstance } from "../services/axiosAPI";
import { useTranslation } from "react-i18next";
import { EnvironmentOutlined, PhoneOutlined } from "@ant-design/icons";

const Contact = () => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  /* const handleSubmit = async () => {
    try {
      console.log("vhje ve", new URLSearchParams(formData).toString());
      const response = await axiosApiInstance.post(
        "api2/v1/chat/send_message/",
        new URLSearchParams(formData).toString()
      );
      if (response.status === 200) {
        alert("Message sent successfully");
        // Swal.fire("Message sent successfully!", "", "success");

        // Clear form after successful submission
        setFormData({ full_name: "", email: "", message: "" });
      } else {
        alert("Failed to send message");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }; */

  const handleSubmit = async () => {
    try {
      console.log("Form Data:", formData); // For debugging purposes
      const response = await axiosApiInstance.post(
        "api2/v1/chat/send_message/",
        JSON.stringify(formData), // Send formData directly as JSON
        {
          headers: {
            "Content-Type": "application/json", // Specify the content type
          },
        }
      );
      if (response.status === 200) {
        alert("Message sent successfully");
        // Swal.fire("Message sent successfully!", "", "success");

        // Clear form after successful submission
        setFormData({ full_name: "", email: "", message: "" });
      } else {
        alert("Failed to send message");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onFinish = (values) => {
    setSubmitting(true);
    // Simulate API call (replace with actual API call)
    setTimeout(() => {
      console.log("Received values:", values);
      form.resetFields();
      setSubmitting(false);
    }, 1000);
  };

  return (
    <Form form={form} layout="vertical">
      <Form.Item label={<b>{t("SPT Information")}</b>}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <EnvironmentOutlined style={{ marginRight: 8 }} />
          <span style={{ marginRight: 16 }}>
            {"2 rue Louis Blériot Les Mureaux 78130"}
          </span>
          {/* <PhoneOutlined style={{ marginRight: 8 }} />
          <span>{"+33 (0)6.49.25.60.16"}</span> */}
        </div>
      </Form.Item>
      <Form.Item
        label={<b>{t("Name")}</b>}
        name="full_name"
        rules={[{ required: true, message: "Please enter your name" }]}
      >
        <Input
          name="full_name"
          style={{ width: "250px" }}
          value={formData.full_name}
          onChange={handleChange}
        />{" "}
      </Form.Item>

      <Form.Item
        label={<b>{t("email")}</b>}
        name="email"
        rules={[
          { required: true, message: "Please enter your email" },
          { type: "email", message: "Please enter a valid email" },
        ]}
      >
        <Input
          name="email"
          value={formData.email}
          onChange={handleChange}
          style={{ width: "250px" }}
        />
      </Form.Item>

      <Form.Item
        label={<b>{t("message")}</b>}
        name="message"
        rules={[{ required: true, message: "Please enter a message" }]}
      >
        <Input.TextArea
          name="message"
          value={formData.message}
          onChange={handleChange}
          style={{ width: "550px" }}
          rows={4}
        />
      </Form.Item>

      <Form.Item>
        <Button onClick={handleSubmit}>{<b>{t("submit")}</b>}</Button>
      </Form.Item>
    </Form>
  );
};

export default Contact;

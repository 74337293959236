import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import MyDocument from "../components/pdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import axios from "axios";
import { Dropdown, Menu, Button, message } from "antd";
import {
  PlaySquareOutlined,
  VerticalAlignBottomOutlined,
  HomeTwoTone,
  DiffTwoTone,
  InteractionTwoTone,
  CalendarTwoTone,
  ThunderboltTwoTone,
  YoutubeOutlined,
  FundTwoTone,
  EuroTwoTone,
} from "@ant-design/icons";
import { axiosApiInstance } from "../services/axiosAPI";

class Planning extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      selectedConfiguration: null,
      selectedSim: null,
      selectedOpt: null,
      selectedOptN: null,

      simulations: [],
      results: [],

      resultsid: {},
      resultsO: [],
      filteredResultsO: [],
      filteredResults: [],

      resultsR: [],
      resultsC: [],
      resultsOid: {},
      configurations: [],
      optimizations: [],
      optimizationsN: [],

      actions: [],
      itemr: 0,
      itemro: 0,
      itemron: 0,

      itemrc: 0,

      displayr: false,
      simulationRID: null,
      optimizationOID: null,
      optimizationOIDN: null,

      configurationID: null,

      verrou: null,
      verrouN: null,

      verrouu: null,

      verrouPDF: false,
      verrouConfig: false,
      verrouSim: false,
      verrouOPT: false,
      verrouOPTN: false,

      verrouO: false,
      verrouON: false,

      verrouS: false,
      verrouX: false,
      verrouY: false,
      verrouAuth: false,
      nsga: false,
    };
  }

  async componentDidMount() {
    // 			// const configurationID =this.props.match.params.configurationID; // ca blo

    await axiosApiInstance
      .get(`api/v1/?username=${localStorage.getItem("user")}`)
      .then((res) => {
        this.setState({ resultsC: res.data });
      })
      .catch((error) => {
        console.log("ERROR::: ", error.res);
      });

    await axiosApiInstance
      .get(`api/v1/res/?username=${localStorage.getItem("user")}`)
      .then((res) => {
        this.setState({ results: res.data });
        console.log("result", this.state.results["0"]);
      })
      .catch((error) => {
        console.log("ERROR::: ", error.res);
      });

    await axiosApiInstance
      .get(`api/v1/resO/?username=${localStorage.getItem("user")}`)
      .then((resO) => {
        this.setState({ resultsO: resO.data });
        console.log("resultOPT", this.state.resultsO["0"]);
      })
      .catch((error) => {
        console.log("ERROR::: ", error.resO);
      });

    await axiosApiInstance
      .get(`api2/v1/?username=${localStorage.getItem("user")}`)
      .then((act) => {
        this.setState({ actions: act.data });
        console.log("actionssss", this.state.actions);
      })
      .catch((error) => {
        console.log("ERROR::: ", error.act);
      });

    await axiosApiInstance
      .get(`api2/v1/Prac/?username=${localStorage.getItem("user")}`)
      .then((act) => {
        this.setState({ practices: act.data });
        console.log("actionssss", this.state.practices);
      })
      .catch((error) => {
        console.log("ERROR::: ", error.act);
      });
  }

  findArrayElementByTitle(array, id) {
    return array.find((element) => {
      return element.resultID === id;
    });
  }

  async handleClickC(e, itemc, displayr) {
    //this.props.configurationID= items.configurationID
    this.state.itemrc = itemc.configurationID;

    const configurationID = itemc.configurationID;
    this.state.configurationID = itemc.configurationID;
    this.setState({ selectedConfiguration: itemc.name });

    this.setState({ verrouConfig: true });
    this.setState({ verrouX: true });
    this.setState({ verrouS: false });
    this.setState({ verrouO: false });
    this.setState({ verrouON: false });

    await axiosApiInstance
      .get(`api/v1/${configurationID}`)
      .then((res) => {
        this.setState({ configurations: res.data });
      })
      .catch((error) => {
        console.log("ERROR::: config", error.res);
      });

    // this.setState ({verrouConfig : true});
    //this.setState ({verrouO : false});

    //this.state.filteredResultsO=this.state.resultsO.filter(item => item["configurationID"] === itemc.configurationID);
    //this.state.filteredResults=this.state.results.filter(item => item["configurationID"] === itemc.configurationID);
  }

  async handleClick(e, items) {
    //this.props.configurationID= items.configurationID
    this.state.itemr = items.resultID;
    this.setState({ selectedSim: items.name });

    this.setState({ displayr: true });
    //this.setState ({verrouConfig : false});
    const resultID = items.resultID;
    this.setState({ verrouPDF: true });
    await axiosApiInstance
      .get(`api/v1/res/${resultID}`)
      .then((resid) => {
        this.setState({
          resultsR: resid.data,
          simulationRID: resid.data["simulationID"],
        });
        console.log("resid", resid.data["simulationID"]);
        //sessionStorage.setItem('data', JSON.stringify(resid)) ;
        //console.log("storga", sessionStorage);
      })

      .catch((error) => {
        console.log("ERROR::: ", error.resid);
      });

    this.setState({ verrouS: true });
  }

  async handleClickO(e, itemo, displayr) {
    //this.props.configurationID= items.configurationID
    this.state.itemro = itemo.resultOPTID;
    this.setState({ selectedOpt: itemo.name });

    this.setState({ displayr: true });
    const resultOPTID = itemo.resultOPTID;
    console.log("result o", this.state.displayr);
    this.setState({ verrouPDF: true });

    await axiosApiInstance
      .get(`api/v1/resO/${resultOPTID}`)
      .then((resoid) => {
        this.setState({ optimizationOID: resoid.data["optimizationID"] });
        console.log("resid", resoid.data["optimizationID"]);
        sessionStorage.setItem("data", JSON.stringify(resoid));
        console.log("storga", sessionStorage);
      })
      .catch((error) => {
        console.log("ERROR::: ", error.resoid);
      });

    this.setState({ verrouO: true });
  }

  async handleClickNSGA(e, itemon, displayr) {
    //this.props.configurationID= items.configurationID
    this.state.itemron = itemon.resultOPTID;
    this.setState({ selectedOptN: itemon.name, nsga: true });

    this.setState({ displayr: true });
    const resultOPTID = itemon.resultOPTID;
    console.log("result o", this.state.displayr);
    this.setState({ verrouPDF: true });

    await axiosApiInstance
      .get(`api/v1/resO/${resultOPTID}`)
      .then((resoid) => {
        this.setState({ optimizationOIDN: resoid.data["optimizationID"] });
        console.log("resid", resoid.data["optimizationID"]);
        sessionStorage.setItem("dataN", JSON.stringify(resoid));
        console.log("storga", sessionStorage);
      })
      .catch((error) => {
        console.log("ERROR::: ", error.resoid);
      });

    this.setState({ verrouON: true });
  }

  containerStyle = {
    display: "flex",
    justifyContent: "space-between",
  };

  columnStyle = {
    width: "45%", // Adjust the width as needed
  };

  async handleOpt(x) {
    await axiosApiInstance
      .get(`api/v1/Opt/${x}`)
      .then((reso) => {
        this.setState({ optimizations: reso.data });
        // console.log("storga3", ress.data) ;
      })
      .catch((error) => {
        console.log("ERROR::: ::::", error.reso);
      });
    this.setState({ verrouOPT: true });

    this.setState({ displayr: true });
    this.setState({ verrouPDF: true });
    this.setState({ verrou: this.state.optimizationOID });
    // console.log("verrou", this.state.verrou)
    console.log("sim id", this.state.optimizations["configurationID"]);
    console.log("plots handle", this.state.optimizations["plots"]);
  }

  async handleOptN(x) {
    await axiosApiInstance
      .get(`api/v1/Opt/${x}`)
      .then((reso) => {
        this.setState({ optimizationsN: reso.data });
        // console.log("storga3", ress.data) ;
      })
      .catch((error) => {
        console.log("ERROR::: ::::", error.reso);
      });
    this.setState({ verrouOPTN: true });

    this.setState({ displayr: true });
    this.setState({ verrouPDF: true });
    this.setState({ verrouN: this.state.optimizationOIDN });
    // console.log("verrou", this.state.verrou)
    console.log("sim id", this.state.optimizations["configurationID"]);
    console.log("plots handle", this.state.optimizations["plots"]);
  }

  async handlePDF() {
    this.setState({ verrouAuth: true });
    this.state.verrouX = false;
    this.state.verrouY = false;

    message.success("PDF is creating  ......");
  }

  async handleSim(y) {
    await axiosApiInstance
      .get(`api/v1/dss/${y}`)
      .then((res) => {
        this.setState({ simulations: res.data });
        console.log("simulation ", res.data);
        console.log(" config id", this.state.simulations["configurationID"]);
      })
      .catch((error) => {
        console.log("ERROR::: config", error.res);
      });
    this.setState({ verrouu: this.state.simulationRID });

    this.setState({ verrouSim: true });
  }

  async handleConfig(y) {
    await axiosApiInstance
      .get(`api/v1/${y}`)
      .then((res) => {
        this.setState({ configurations: res.data });
        console.log("configuration3", res.data);
      })
      .catch((error) => {
        console.log("ERROR::: config", error.res);
      });

    this.setState({ verrouConfig: true });
    this.setState({ verrouO: false });
    this.setState({ verrouON: false }); //to be checked

    console.log("trueeeeee", this.state.verrouConfig);
  }

  async handleConfig(y) {
    await axiosApiInstance
      .get(`api/v1/${y}`)
      .then((res) => {
        this.setState({ configurations: res.data });
        console.log("configuration3", res.data);
      })
      .catch((error) => {
        console.log("ERROR::: config", error.res);
      });

    this.setState({ verrouConfig: true });
    this.setState({ verrouO: false });
    this.setState({ verrouON: false }); //to be checked

    console.log("trueeeeee", this.state.verrouConfig);
  }

  render() {
    const { configurations, selectedConfiguration } = this.state;
    const { results, selectedSim } = this.state;
    const { resultsO, selectedOpt, selectedOptN } = this.state;
    // const filteredResultsO = this.state.resultsO.filter(item => item["configurationID"] === this.state.configurationID);

    // const filteredResults = this.state.results.filter(item => item["configurationID"] === this.state.configurationID);

    const displayr = this.state.displayr;
    const verrou = this.state.verrou;
    const resultPDF = this.state.resultsO;
    const optimizationOID = this.state.optimizationOID;
    //const optimizations = this.state.optimizations;

    if (this.state.verrouO) {
      if (this.state.verrou !== this.state.optimizationOID) {
        this.handleOpt(this.state.optimizationOID);
        console.log("to check OPT", this.state.optimizationOID);
      }

      /* if((this.state.optimizations["configurationID"])!==undefined && (this.state.verrouSim===false)) {this.handleSim(this.state.optimizations["configurationID"]);
   console.log("to check SIM", this.state.verrouConfig)  
    } 
   
    if((this.state.simulations["configurationID"])!==undefined && (this.state.verrouConfig===false)) {this.handleConfig(this.state.simulations["configurationID"]);
 
    console.log("to check Conf", this.state.simulations["configurationID"]) }  */
    }

    if (this.state.verrouS) {
      if (this.state.verrouu !== this.state.simulationRID) {
        this.handleSim(this.state.simulationRID);
        console.log("to check SIM", this.state.verrouConfig);
      }
    }

    if (this.state.verrouON) {
      if (this.state.verrouN !== this.state.optimizationOIDN) {
        this.handleOptN(this.state.optimizationOIDN);
        console.log("to check OPT", this.state.optimizationOIDN);
      }

      /* if((this.state.optimizations["configurationID"])!==undefined && (this.state.verrouSim===false)) {this.handleSim(this.state.optimizations["configurationID"]);
     console.log("to check SIM", this.state.verrouConfig)  
      } 
     
      if((this.state.simulations["configurationID"])!==undefined && (this.state.verrouConfig===false)) {this.handleConfig(this.state.simulations["configurationID"]);
   
      console.log("to check Conf", this.state.simulations["configurationID"]) }  */
    }

    return (
      <div style={{ width: "170vh", marginBottom: 10, padding: 5 }}>
        <br></br>
        <div
          style={{
            display: "block",
            width: 700,
            padding: 30,
          }}
        >
          <div style={this.containerStyle}>
            <div style={this.columnStyle}>
              <h4> Select a configuration </h4>

              <Dropdown
                overlay={
                  <Menu>
                    <div>
                      {" "}
                      {this.state.resultsC.map((itemc) => (
                        <Menu.Item
                          onClick={(e) => this.handleClickC(e, itemc)}
                          style={{ minWidth: 0, flex: "auto" }}
                          key={itemc.configurationID}
                        >
                          {" "}
                          Config {String(itemc.name)}
                        </Menu.Item>
                      ))}{" "}
                    </div>
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button
                  className="ant-dropdown-link"
                  icon={<VerticalAlignBottomOutlined />}
                  onClick={(e) => e.preventDefault()}
                >
                  {selectedConfiguration
                    ? `Config ${selectedConfiguration}`
                    : "Configuration ID"}
                </Button>
              </Dropdown>
            </div>
          </div>
          <br></br>
          <br></br>

          <div style={this.containerStyle}>
            <div style={this.columnStyle}>
              <h4> Select a simulation result </h4>

              <Dropdown
                overlay={
                  <Menu>
                    <div>
                      {" "}
                      {this.state.results
                        .filter(
                          (item) =>
                            item["configurationID"] ===
                            this.state.configurationID
                        )
                        .map((items) => (
                          <Menu.Item
                            onClick={(e) => {
                              this.state.verrouX
                                ? this.handleClick(e, items)
                                : message.error("select a configuration");
                            }}
                            key={items.resultID}
                          >
                            {" "}
                            Result {String(items.name)}
                          </Menu.Item>
                        ))}{" "}
                    </div>
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button
                  className="ant-dropdown-link"
                  icon={<VerticalAlignBottomOutlined />}
                  onClick={(e) => e.preventDefault()}
                >
                  {selectedSim ? `Result ${selectedSim}` : "SIM result ID"}
                </Button>
              </Dropdown>
            </div>

            <div style={this.columnStyle}>
              <h4> Select a Nelder optimisation </h4>

              <Dropdown
                overlay={
                  <Menu>
                    <div>
                      {" "}
                      {this.state.resultsO
                        .filter(
                          (item) =>
                            item["configurationID"] ===
                              this.state.configurationID && item.type != "AI"
                        )
                        .map((itemo) => (
                          <Menu.Item
                            onClick={(e) => {
                              this.state.verrouX
                                ? this.handleClickO(e, itemo)
                                : message.error("select a configuration");
                            }}
                            key={itemo.resultOPTID}
                          >
                            {" "}
                            Result {String(itemo.name)}
                          </Menu.Item>
                        ))}{" "}
                    </div>
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button
                  className="ant-dropdown-link"
                  icon={<VerticalAlignBottomOutlined />}
                  onClick={(e) => e.preventDefault()}
                >
                  {selectedOpt ? `Result ${selectedOpt}` : "OPT result ID"}
                </Button>
              </Dropdown>
              <br></br>
            </div>

            <div style={this.columnStyle}>
              <h4> Select NSGA result </h4>

              <Dropdown
                overlay={
                  <Menu>
                    <div>
                      {" "}
                      {this.state.resultsO
                        .filter(
                          (item) =>
                            item["configurationID"] ===
                              this.state.configurationID && item.type === "AI"
                        )
                        .map((itemon) => (
                          <Menu.Item
                            onClick={(e) => {
                              this.state.verrouX
                                ? this.handleClickNSGA(e, itemon)
                                : message.error("select a configuration");
                            }}
                            key={itemon.resultOPTID}
                          >
                            {" "}
                            Result {String(itemon.name)}
                          </Menu.Item>
                        ))}{" "}
                    </div>
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button
                  className="ant-dropdown-link"
                  icon={<VerticalAlignBottomOutlined />}
                  onClick={(e) => e.preventDefault()}
                >
                  {selectedOptN ? `Result ${selectedOptN}` : "OPT result ID"}
                </Button>
              </Dropdown>
              <br></br>
            </div>
          </div>
          <br></br>

          <div style={this.columnStyle}>
            <button onClick={() => this.handlePDF()}> Generate a PDF </button>
          </div>
        </div>
        <br></br>
        {this.state.displayr &&
        this.state.verrouPDF &&
        this.state.verrouAuth &&
        this.state.verrouConfig &&
        (this.state.verrouOPT ||
          this.state.verrouOPTN ||
          this.state.verrouSim) ? (
          <div>
            <PDFViewer height={500} width={1000}>
              <MyDocument
                configurations={this.state.configurations}
                actions={this.state.actions}
                practices={this.state.practices}
                resultOPTID={this.state.itemro}
                resultOPTIDN={this.state.itemron}
                optimizations={this.state.optimizations}
                optimizationsN={this.state.optimizationsN}
                simulationID={this.state.simulationRID}
                opt={this.state.verrouOPT}
                sim={this.state.verrouSim}
                resultID={this.state.itemr}
                results={this.state.resultsR}
                simulations={this.state.simulations}
                nsga={this.state.verrouOPTN}
              />
            </PDFViewer>
            <PDFDownloadLink
              document={
                <MyDocument
                  configurations={this.state.configurations}
                  actions={this.state.actions}
                  practices={this.state.practices}
                  resultOPTID={this.state.itemro}
                  resultOPTIDN={this.state.itemron}
                  optimizations={this.state.optimizations}
                  optimizationsN={this.state.optimizationsN}
                  simulationID={this.state.simulationRID}
                  opt={this.state.verrouOPT}
                  sim={this.state.verrouSim}
                  resultID={this.state.itemr}
                  results={this.state.resultsR}
                  simulations={this.state.simulations}
                  nsga={this.state.verrouOPTN}
                />
              }
              fileName={"Planning Result"}
            >
              <button> Download </button>
            </PDFDownloadLink>{" "}
          </div>
        ) : (
          console.log(
            "verousss",
            this.state.displayr,
            this.state.verrouPDF,
            this.state.verrouConfig,
            this.state.verrouSim,
            this.state.verrouOPT
          )
        )}{" "}
      </div>
    );
  }
}

export default Planning;

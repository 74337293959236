// register.js

import React, { useState } from 'react';
import axios from 'axios';
import { Form, Input, Button, message } from 'antd';
import { axiosApiInstance } from '../services/axiosAPI';



const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const onFinish = (values) => {
    console.log('Received values:', values);
  };

  const handleRegister = async () => {
    try {
      const response = await axiosApiInstance.post('accounts/register/', {
        username: username,
        email: email,
        password: password,
      });

      console.log(response.data);
      message.success("Registration successuful")
      // Handle registration success, e.g., redirect to login page or show a success message
    } catch (error) {
      if (error.response.data.error) {
        if(error.response.data.error.email){
           message.error("this email exists already or wrong");
        }
        if(error.response.data.error.username) {
       
          message.error("this username exists already or wrong");
      }
      } else {
        message.error('Registration failed');
      }
    }
  };

  return (
    <div>
      <h2>User Registration</h2>
     {/*  <label>Username:</label>
      <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
      <label>Email:</label>
      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      <label>Password:</label>
      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      <button onClick={handleRegister}>Register</button> */}
    
      <Form
      name="register-form"
      onFinish={onFinish}
      layout="vertical"
      style={{ maxWidth: '300px', margin: 'auto' }}
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[{ required: true, message: 'Please enter your username' }]}
      >
        <Input value={username} onChange={(e) => setUsername(e.target.value)} />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: 'Please enter your email' },
          { type: 'email', message: 'Please enter a valid email' }
        ]}
      >
        <Input value={email} onChange={(e) => setEmail(e.target.value)} />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please enter your password' }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label="Confirm Password"
        name="confirmPassword"
        dependencies={['password']}
        rules={[
          { required: true, message: 'Please confirm your password' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords do not match'));
            },
          }),
        ]}
      >
        <Input value={password} onChange={(e) => setPassword(e.target.value)}/>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: '100%' }} onClick={handleRegister}>
          Register
        </Button>
      </Form.Item>
    </Form>
    
    
    </div>
  );
};

export default Register;

import React, { useState, useEffect } from "react";
import { List, Space, Form, Input, Button, Modal } from "antd";
import axios from "axios";
import { axiosApiInstance } from "../services/axiosAPI";

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);

const Actions = () => {
  const [deletepracticeID, setDeletepracticeID] = useState(null);
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    title_pr: "",
    content_pr: "",
  });
  const [editpracticeID, setEditpracticeID] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axiosApiInstance.get(
        `api2/v1/Prac/?username=${localStorage.getItem("user")}`
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = (practiceID) => {
    setDeletepracticeID(practiceID);
  };

  const handleConfirmDelete = async () => {
    try {
      await axiosApiInstance.delete(`api2/v1/${deletepracticeID}/deleteP/`);
      await fetchData();
      setDeletepracticeID(null); // Reset deletepracticeID after successful deletion
    } catch (error) {
      console.error("Error deleting practice:", error);
    }
  };

  const handleCancelDelete = () => {
    setDeletepracticeID(null); // Reset deletepracticeID if cancellation occurs
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editpracticeID) {
        await axiosApiInstance.put(`api2/v1/${editpracticeID}/updateP/`, {
          title_pr: formData.title_pr,
          content_pr: formData.content_pr,
        });
        setEditpracticeID(null); // Reset editpracticeID after successful update
      } else {
        await axiosApiInstance.post("api2/v1/createP/", {
          title_pr: formData.title_pr,
          content_pr: formData.content_pr,
          username: localStorage.getItem("user"),
        });
      }
      await fetchData();
      setFormData({
        title_pr: "",
        content_pr: "",
      });
    } catch (error) {
      console.error("Error creating/editing practice:", error);
    }
  };

  const handleEdit = (practiceID, title, content) => {
    setEditpracticeID(practiceID);
    setFormData({
      title_pr: title,
      content_pr: content,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <>
        <br />
        <div>
          <b>Follow these best practices for your project decarbonization</b>
        </div>

        <List
          itemLayout="Horizontal"
          size="large"
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 4,
          }}
          dataSource={data}
          footer={
            <div>
              <b> </b>
            </div>
          }
          renderItem={(item) => (
            <List.Item key={item.practiceID}>
              <Space direction="horizontal">
                <label>
                  <a>{item.title_pr}</a> {item.practiceID}: {item.content_pr}{" "}
                  <Button onClick={() => handleDelete(item.practiceID)}>
                    Delete
                  </Button>{" "}
                  <Button
                    onClick={() =>
                      handleEdit(
                        item.practiceID,
                        item.title_pr,
                        item.content_pr
                      )
                    }
                  >
                    Edit
                  </Button>
                </label>
              </Space>
            </List.Item>
          )}
        />
        <Form.Item label="Practice Title">
          <Input
            name="title_pr"
            value={formData.title_pr}
            onChange={handleChange}
            style={{ maxWidth: "206px" }}
          />
        </Form.Item>
        <Form.Item label="Content">
          <Input.TextArea
            name="content_pr"
            value={formData.content_pr}
            onChange={handleChange}
            style={{
              maxWidth: "206px",
              marginLeft: "21px",
              gap: "18px",
              maxHeight: "200px",
            }}
          />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          {editpracticeID ? "Update" : "Add practice"}
        </Button>

        <Modal
          title="Confirm Delete"
          visible={deletepracticeID !== null}
          width={300}
          onOk={handleConfirmDelete}
          onCancel={handleCancelDelete}
        >
          <p>Are you sure you want to delete this Practice?</p>
        </Modal>
      </>
    </form>
  );
};

export default Actions;

import axios from "axios";
//import {setToken} from "./auth.js"

const prod = "https://www.sptapp.eu/";
const dev = "http://127.0.0.1:8000/";
export const axiosApiInstance = axios.create({
  baseURL: prod,
});
// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    /*     const value = await redisClient.get(rediskey)
    const keys = JSON.parse(value) */
    const access = localStorage.getItem("token");
    const refresh = localStorage.getItem("refresh");
    console.log("content type ", config.headers);
    config.headers = {
      Authorization: `Bearer ${access ?? ""}`,
      "X-Refresh": refresh ?? "",
      Accept: "application/json",
      "Content-Type": config.headers["Content-Type"], // ?? 'application/json' /// TOBECHECKED
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    console.log("response ", response);
    return response;
  },

  async function (error) {
    const originalRequest = error.config;
    console.log("errorr", error.response);
    if (
      error.response.status === 403 &&
      !originalRequest._retry &&
      error.response.data["detail"] === "token_expired"
    ) {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      axiosApiInstance.defaults.headers.common["Authorization"] =
        "Bearer " + access_token;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

async function refreshAccessToken() {
  try {
    // Make a request to your server to refresh the token
    const response = await axiosApiInstance.post("accounts/refresh/", {
      // Include any necessary data for refreshing the token
      // e.g., refresh token, user ID, etc.
    });

    // Extract and return the new token from the response
    setToken(response.data.access);
    localStorage.setItem("refresh", response.data.refresh);

    return response.data.access;
  } catch (error) {
    // Handle token refresh failure
    console.error("Token refresh failed:", error);
    throw error; // Rethrow the error for the calling function to handle
  }
}

export const setToken = (token) => {
  localStorage.setItem("token", token);
  console.log("settoken", token);
};

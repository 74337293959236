import { Button, Input } from "antd";
import React, { Component } from "react";
import Papa from "papaparse";
import axios from "axios";
import { Chart, registerables } from "chart.js/auto";
import zoomPlugin from "chartjs-plugin-zoom";
import { DownloadOutlined } from "@ant-design/icons";
import ReactPaginate from "react-paginate";
import "./styles.css"; // Import your CSS file
import { Table } from "antd";

Chart.register(...registerables, zoomPlugin);

export class Cleaning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cleanDataState: false,
      defaultData: [],
      defaultLabels: [],
      data: [],
      chartLabel: [],
      chartData: [],
      rawData: [],
      errors: [],
      cleanedData: [],
      minThreshold: 0,
      maxThreshold: 1000,
      columnInfo: [],
      loading: false,

      currentPage: 0,
      itemsPerPage: 5,
      generatorList: [
        {
          type: "pvSolar",
          name: "Name1",
          capacity: "Capacity",
          panelPowerW: "panelPowerW",
          tiltAngle: "tiltAngle",
          nameW: "nameW",
          capacityW: "capacityW",
          std: "std",
          hubHeightm: "heigh",
        },
      ],
      types: ["pvSolar", "windturbine", "Diesel*"],
      buildingList: [
        {
          type: "hospital",
          nameH: "Name1",
          nameHp: "NameHp",
          yearlykWh: "yearlykWh",
          nameC: "NameC",
          cop: "cop",
          weekdaykWh: "weekday",
          weekendkWh: "weekend",
          monthlykWh: "monthly",
          yearlyloads: "Yearlyloads",
        },
      ],
      buildingtypes: [
        "custom",
        "hospital",
        "hotel",
        "office",
        "residential",
        "school",
        "commercial",
        "industrial",
        "Heatpump",
      ],
      storageList: [
        {
          type: "Battery",
          nameB: "NameB",
          capacityB: "Capacity",
          nameR: "NameR",
          fuelCellPowerkW: "fuel",
          electrolyserPowerkW: "fuelkW",
        },
      ],
      storageTypes: ["Battery", "FuelCell", "Thermal*"],
      selectedStd: "IEC_1",
      yearlyloads: [],
      selectedFile: [],
      configurations: [],
      files: {},
      loads: {},
      isChecked: true,
      isCheckedS: false,
      isCheckedB: true,
      isCheckedC: false,
      isCheckedT: false,
      isCheckedA: true,
      dataCSV: [],
      labelsCSV: [],
      csvFile: null,
      error: "",
      rowCount: 0,
      columnCount: 0,
    };
    this.chartRef = React.createRef();
    this.myChart = null;
    this.getData = this.getData.bind(this);
  }

  resetZoom = () => {
    if (this.myChart) {
      this.myChart.resetZoom();
    }
  };

  handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  default = () => {
    console.log("Resetting to default data");
    console.log("Default Data:", this.state.defaultData);
    console.log("Default Labels:", this.state.defaultLabels);

    this.setState(
      {
        chartData: this.state.defaultData,
        chartLabel: this.state.defaultLabels,
      },
      () => {
        // Update the chart after the state has been set
        if (this.myChart) {
          this.myChart.data.labels = this.state.chartLabel;
          this.myChart.data.datasets[0].data = this.state.chartData;
          this.myChart.update();
        }
      }
    );
  };

  async componentDidMount() {
    this.setState({
      defaultData: [
        /* your default data values */
      ],
      defaultLabels: [
        /* your default label values */
      ],
    });

    this.setState({ loading: false });

    try {
      console.log("logiii+++++++++++++++", this.state.chartData);
      const ctx = this.chartRef.current.getContext("2d");
      this.myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: this.state.chartLabel,
          datasets: [
            {
              label: "Data chart",
              data: this.state.chartData,
              fill: false,
              borderColor: "rgb(75, 192, 192)",
              tension: 0.1,
            },
          ],
        },
        options: {
          plugins: {
            zoom: {
              zoom: {
                wheel: {
                  enabled: true,
                },
                pinch: {
                  enabled: true,
                },
                mode: "xy",
              },
            },
          },
        },
      });
    } catch (error) {
      console.error("Error occurred while initializing chart:", error);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    // chart updates
    if (
      prevState.chartData !== this.state.chartData ||
      prevState.chartLabel !== this.state.chartLabel
    ) {
      if (this.myChart) {
        this.myChart.data.labels = this.state.chartLabel;
        this.myChart.data.datasets[0].data = this.state.chartData;
        this.myChart.update();
      }
    }
  }

  componentWillUnmount() {
    if (this.myChart) {
      this.myChart.destroy();
    }
  }

  prepareChartData = () => {
    const { rawData } = this.state;
    const chartLabels = [];
    const chartData = [];

    rawData.forEach((row) => {
      const label = row["Label"]; // Adjust 'Label' to your actual column name
      const value = parseFloat(row["Value"]); // Adjust 'Value' to your actual column name

      if (!isNaN(value)) {
        chartLabels.push(label);
        chartData.push(value);
      }
    });

    console.log("rawdata", chartData);

    this.setState({ chartLabels, chartData }, this.initializeChart);
  };

  updateChart = (cleanedDT) => {
    const labels = cleanedDT.map((_, index) => index + 1); // Example labels
    const data = cleanedDT.map((row) => row[0]); // Assuming the first column is your data

    const ctx = this.chartRef.current.getContext("2d");

    if (this.myChart) {
      this.myChart.data.labels = labels;
      this.myChart.data.datasets[0].data = data;
      this.myChart.update();
    } else {
      this.myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Cleaned Data Chart",
              data: data,
              fill: false,
              borderColor: "rgb(75, 192, 192)",
              tension: 0.1,
            },
          ],
        },
        options: {
          plugins: {
            zoom: {
              zoom: {
                wheel: {
                  enabled: true,
                },
                pinch: {
                  enabled: true,
                },
                mode: "xy",
              },
            },
          },
        },
      });
    }
  };

  initializeChart = () => {
    const { chartLabels, chartData } = this.state;
    const ctx = this.chartRef.current.getContext("2d");

    // Destroy previous chart instance if it exists
    /* if (this.myChart) {
      this.myChart.destroy();
    } */

    this.myChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: chartLabels,
        datasets: [
          {
            label: "Data Chart",
            data: chartData,
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          zoom: {
            zoom: {
              wheel: { enabled: true },
              pinch: { enabled: true },
              mode: "xy",
            },
          },
        },
      },
    });

    this.setState({ loading: false }); // Update loading state
  };

  getData(results) {
    this.setState({ data: results.data });
  }

  async handleDelete(configurationID) {
    // Perform deletion logic using the configurationID

    console.log("Deleting configuration with ID:", configurationID);
    // Here you can implement the logic to delete the configuration

    await axios
      .delete(`http://127.0.0.1:8000/api/v1/${configurationID}/deleteC/`)
      .then()
      .catch((error) => {
        console.log("ERROR::: ", error.res);
      });

    await axios
      .get(
        `http://127.0.0.1:8000/api/v1/?username=${localStorage.getItem("user")}`
      )
      .then((res) => {
        this.setState({ configurations: res.data, loading: false });
        console.log("conf", this.state.configurations);
      })
      .catch((error) => {
        console.log("ERROR::: ", error.res);
      });
  }

  handleFileChange = (event) => {
    this.setState({
      chartData: [],
      chartLabel: [],
    });

    const file = event.target.files[0];

    if (file) {
      this.setState({ loading: true });

      Papa.parse(file, {
        header: false,
        skipEmptyLines: true,
        complete: (results) => {
          const columnInfo = this.getColumnInfo(results.data);
          this.setState({
            rawData: results.data,
            errors: [],
            cleanedData: [],
            columnInfo,
          });

          let data = results.data;
          console.log("datao", data);

          if (typeof data[0] === "string") {
            // Skip the first row if it's a header
            data = data.slice(1);
            console.log("Data without header:", data);
          } else {
            console.log(
              "Original data does not contain a header:",
              data.length
            );
          }
          if (data && data.length > 0) {
            const rowCount = data.length;
            const columnCount = data[0].length;
            // Update state with row and column counts
            this.setState({ rowCount, columnCount });

            let errorMessage = "";
            if (data.length !== 8760 || data[0].length !== 1) {
              console.log("Rows length:", data.length);
              console.log("Columns length:", data[0].length);
              errorMessage =
                "CSV file must contain exactly 1 column and 8760 rows";
            }
            // Set default data and labels
            const defaultData = data.map((row) => parseFloat(row[0])); // Assuming the first column
            const defaultLabels = data.map((_, index) => index); // Row index as label

            this.setState(
              {
                csvFile: file,
                defaultData,
                defaultLabels,
                chartData: defaultData,
                chartLabel: defaultLabels,
                error: "",
              },
              () => {
                console.log("After setting state:");
                console.log("Default Data:", this.state.defaultData);
                console.log("Default Labels:", this.state.defaultLabels);
              }
            );

            // Set error message if any
            if (errorMessage) {
              this.setState({ error: errorMessage });
            }
          } else {
            console.log("There is no result");
          }
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
          this.setState({ loading: false });
        },
      });
    }
  };

  getColumnInfo(data) {
    const columnInfo = {};

    // Initialize column information
    Object.keys(data[0] || {}).forEach((key) => {
      columnInfo[key] = {
        min: Infinity,
        max: -Infinity,
        mean: 0,
        median: 0,
        mode: [],
        count: 0,
        values: [],
      };
    });

    // Calculate min, max, mean, median, mode, and collect values for each column
    data.forEach((row) => {
      Object.keys(row).forEach((key) => {
        const value = parseFloat(row[key]);
        if (!isNaN(value)) {
          if (value < columnInfo[key].min) columnInfo[key].min = value;
          if (value > columnInfo[key].max) columnInfo[key].max = value;

          columnInfo[key].values.push(value);
          columnInfo[key].count++;
        }
      });
    });

    // Calculate mean, median, and mode
    Object.keys(columnInfo).forEach((key) => {
      const values = columnInfo[key].values;
      const count = columnInfo[key].count;

      if (count > 0) {
        // Mean
        columnInfo[key].mean = values.reduce((a, b) => a + b, 0) / count;

        // Median
        values.sort((a, b) => a - b);
        if (count % 2 === 0) {
          columnInfo[key].median =
            (values[count / 2 - 1] + values[count / 2]) / 2;
        } else {
          columnInfo[key].median = values[Math.floor(count / 2)];
        }

        // Mode
        const frequency = {};
        values.forEach((v) => {
          frequency[v] = (frequency[v] || 0) + 1;
        });
        const maxFreq = Math.max(...Object.values(frequency));
        columnInfo[key].mode = Object.keys(frequency).filter(
          (k) => frequency[k] === maxFreq
        );
      }
    });
    console.log("colum info", columnInfo);
    return columnInfo;
  }
  detectErrors = () => {
    const { rawData, minThreshold, maxThreshold } = this.state;
    const detectedErrors = rawData
      .map((row, index) => {
        const rowErrors = {};

        Object.keys(row).forEach((key) => {
          const value = row[key];
          if (isNaN(value)) {
            rowErrors[key] = "Not a number";
          }
          if (value === "NA") {
            rowErrors[key] = "NA value";
          }
          if (value === "") {
            rowErrors[key] = "Empty row";
          }
          if (
            parseFloat(value) < minThreshold ||
            parseFloat(value) > maxThreshold
          ) {
            rowErrors[key] = "Outlier";
          }
        });

        return Object.keys(rowErrors).length
          ? { row: index + 1, errors: rowErrors }
          : null;
      })
      .filter(Boolean);

    this.setState({ errors: detectedErrors, currentPage: 0 });
  };

  cleanData = () => {
    const { rawData, minThreshold, maxThreshold } = this.state;

    // Calculate mean values for each column
    const means = {};
    const counts = {};

    rawData.forEach((row) => {
      Object.keys(row).forEach((key) => {
        const value = parseFloat(row[key]);
        if (!isNaN(value) && value !== null && value !== "NA" && value !== "") {
          if (value > minThreshold && value < maxThreshold) {
            if (!means[key]) {
              means[key] = 0;
              counts[key] = 0;
            }
            means[key] += value;
            counts[key]++;
          }
        }
      });
    });

    // Finalize mean calculations
    Object.keys(means).forEach((key) => {
      means[key] /= counts[key];
    });
    console.log("means", means);
    const cleaned = rawData.map((row) => {
      const cleanedRow = {};

      Object.keys(row).forEach((key) => {
        let value = row[key];

        // Clean data
        if (
          value === "NA" ||
          value === "" ||
          isNaN(value) ||
          parseFloat(value) < minThreshold ||
          parseFloat(value) > maxThreshold
        ) {
          // Replace with mean if error detected
          value = means[key] || null; // Use mean or null if no valid values
        } else {
          value = parseFloat(value);
        }

        cleanedRow[key] = value;
      });
      //const labels = cleanedRow.map((_, index) => index);
      //console.log("Labels:", labels);

      // Update the chart after the state has been set

      return cleanedRow;
    });

    this.setState({
      cleanedData: cleaned,
    });
    this.updateChart(cleaned);
    /* const labels = cleaned.map((_, index) => index);

    this.setState({
      cleanedData: cleaned,
      chartData: cleaned,
      chartLabel: labels,
    });
    if (this.myChart) {
      this.myChart.data.labels = labels;
      this.myChart.data.datasets[0].data = cleaned;
      this.myChart.update();
    }
    console.log("i am here");
  }; */
  };

  handlePageClick = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage });
  };

  handleMinChange = (event) => {
    this.setState({ minThreshold: parseFloat(event.target.value) });
  };

  handleMaxChange = (event) => {
    this.setState({ maxThreshold: parseFloat(event.target.value) });
  };

  downloadCSV = () => {
    const { cleanedData } = this.state;

    const csv = Papa.unparse(cleanedData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "cleaned_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    const {
      loading,
      errors,
      cleanedData,
      currentPage,
      itemsPerPage,
      minThreshold,
      maxThreshold,
      columnInfo,
    } = this.state;
    const offset = currentPage * itemsPerPage;
    const currentErrors = errors.slice(offset, offset + itemsPerPage);
    const pageCount = Math.ceil(errors.length / itemsPerPage);

    //const { columnInfo } = this.props; // Assuming columnInfo is passed as a prop

    // Define the columns for the Ant Design Table
    const columns = [
      {
        title: "Column Name",
        dataIndex: "key",
        key: "key",
      },
      {
        title: "Min Value",
        dataIndex: "min",
        key: "min",
        render: (text) => (text === Infinity ? "N/A" : text.toFixed(2)),
      },
      {
        title: "Max Value",
        dataIndex: "max",
        key: "max",
        render: (text) => (text === -Infinity ? "N/A" : text.toFixed(2)),
      },
      {
        title: "Mean Value",
        dataIndex: "mean",
        key: "mean",
        render: (text) => text.toFixed(2),
      },
      {
        title: "Median Value",
        dataIndex: "median",
        key: "median",
        render: (text) => text.toFixed(2),
      },
      {
        title: "Mode of Data",
        dataIndex: "mode",
        key: "mode",
        render: (text) => (text.length ? text.join(", ") : "N/A"),
      },
      {
        title: "Count of Valid Floats",
        dataIndex: "count",
        key: "count",
      },
    ];

    // Transform columnInfo into the format required by the Table
    const dataSource = Object.entries(columnInfo).map(([key, info]) => ({
      key: key,
      min: info.min,
      max: info.max,
      mean: info.mean,
      median: info.median,
      mode: info.mode,
      count: info.count,
    }));

    const columnEs = [
      {
        title: "Row",
        dataIndex: "row",
        key: "row",
      },
      {
        title: "Errors",
        dataIndex: "errors",
        key: "errors",
        render: (errors) => (
          <ul>
            {Object.entries(errors).map(([key, message]) => (
              <li key={key}>{message}</li>
            ))}
          </ul>
        ),
      },
    ];

    // Transform currentErrors into the format required by the Table
    const dataSourceE = currentErrors.map((error) => ({
      key: error.row, // Use row as the key
      row: error.row,
      errors: error.errors,
    }));

    return (
      <>
        <div style={{ width: "170vh", marginBottom: 0, padding: 5 }}>
          {/* Clear the values for Battery when changing to FuelCell */}
          <Input.Group
            style={{
              display: "flex",
              gap: "8px",
              width: "20%",
              marginTop: 20,
              fontFamily: "Poppins",
              fontWeight: "500",
            }}
          >
            <Input
              name={`yearlyloads`}
              style={{ flex: 1, borderRadius: 5 }}
              type="file"
              accept=".csv"
              id={`csvfile`}
              onChange={this.handleFileChange}
            />
          </Input.Group>
          <br></br>
          <br></br>
          {/*           <input type="file" accept=".csv" onChange={this.handleFileChange} />
           */}
          {/* Display error message */}
          {this.state.columnCount > 0 ? (
            <b>
              Your file contain {this.state.columnCount} Clomuns &{" "}
              {this.state.rowCount} Rows without empty rows
            </b>
          ) : (
            <></>
          )}
          {this.state.error && (
            <p style={{ color: "red" }}>{this.state.error}</p>
          )}
          <br />
          <div style={{ width: "170vh", marginBottom: 0, padding: 5 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                fontFamily: "Poppins",
                fontWeight: "500",
              }}
            >
              <h3> Min Outlier Threshold:</h3>
              <Input
                type="number"
                value={minThreshold}
                onChange={this.handleMinChange}
                style={{ width: "120px" }} // Adjust width as needed
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                fontFamily: "Poppins",
                fontWeight: "500",
              }}
            >
              <h3>Max Outlier Threshold:</h3>
              <Input
                type="number"
                value={maxThreshold}
                onChange={this.handleMaxChange}
                style={{ width: "120px" }} // Adjust width as needed
              />
            </div>
          </div>
          <br></br>
          <h3 style={{ fontWeight: "bold", color: "blue" }}>
            Raw CSV Information :{" "}
          </h3>
          <p>Number of Columns: {Object.keys(columnInfo).length}</p>
          <p>Number of Rows: {this.state.rawData.length}</p>
          <br></br>
          {/* <table style={{ borderCollapse: "collapse", width: "70%" }}>
            <thead>
              <tr>
                <th>Column Name | </th>
                <th>Min Value | </th>
                <th>Max Value | </th>
                <th>Mean Value | </th>
                <th>Median Value | </th>
                <th>Mode of Data | </th>
                <th>Count of Valid Floats | </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(columnInfo).map(([key, info]) => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{info.min === Infinity ? "N/A" : info.min.toFixed(2)}</td>
                  <td>
                    {info.max === -Infinity ? "N/A" : info.max.toFixed(2)}
                  </td>
                  <td>{info.mean.toFixed(2)}</td>
                  <td>{info.median.toFixed(2)}</td>
                  <td>{info.mode.length ? info.mode.join(", ") : "N/A"}</td>
                  <td>{info.count}</td>
                </tr>
              ))}
            </tbody>
          </table> */}
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            style={{ width: "70%", borderCollapse: "collapse" }}
          />
          <br></br>
          <h3 style={{ fontWeight: "bold", color: "blue" }}>
            Detected Errors:
          </h3>{" "}
          {errors.length > 0 && (
            <Table
              dataSource={dataSourceE}
              columns={columnEs}
              pagination={false}
              style={{ width: "70%", borderCollapse: "collapse" }}
            />
          )}
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"} // Use your custom class
            activeClassName={"active"} // Use your active class
          />
          {/*   <h3>Cleaned Data:</h3>
          <pre>{JSON.stringify(cleanedData, null, 2)}</pre> */}
          <br />
          <div style={{ display: "flex", marginBottom: 5 }}>
            <Button
              type="primary"
              style={{
                borderRadius: 5,
                color: "white",
                marginBottom: 5,
                fontFamily: "Poppins",
                marginLeft: 5,
              }}
              onClick={this.detectErrors}
            >
              Detect Errors
            </Button>
            <Button
              type="primary"
              style={{
                borderRadius: 5,
                color: "white",
                marginBottom: 5,
                fontFamily: "Poppins",
                marginLeft: 5,
              }}
              onClick={this.cleanData}
            >
              Clean Data
            </Button>
          </div>
          <div
            style={{
              boxShadow: "0 2px 2px rgba(0, 0, 0, 0.2)",
              display: "flex",
              flexDirection: "column",
              width: "70%",

              padding: 20,
            }}
          >
            <div>
              {/*  {loading && <p>Loading...</p>} */}

              <canvas ref={this.chartRef} />
            </div>
          </div>
          <br />
          <Button onClick={this.downloadCSV} disabled={!cleanedData.length}>
            Download Cleaned Data
          </Button>
        </div>
      </>
    );
  }
}

import React from "react";
import {Button, Input} from 'antd'; 
import './App.css';
import ActionList from "../containers/ActionSListView";
import ActionDetail from "../containers/ActionDetailView";
import BaseRouter from "../routes";



function Actions () {

    return (
       <div>
         <ActionList/> 
         
         
          
         </div>
    )
}

export default Actions;
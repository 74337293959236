import {React, useState , useRef, useEffect} from "react";
import {Input, Form, Button} from 'antd';
import ConfigurationForm from "../components/FormConfiguration";
//import script from './DSS/main.py'
import Papa from 'papaparse';
import axios from "axios";

   


function Config () {
   
    const [file, setFile] = useState();
    const [array, setArray] = useState([]);
    const [arrayend, setArrayend] = useState([]);
    const [arrayM, setArrayM] = useState([]);
    const formRef = useRef(null);
      
           
          

    const fileReader = new FileReader();
  
    const handleOnChange = (e, x, y) => {
        e.preventDefault();
     // setFile(e.target.files[0]);
      const results = Papa.parse(e.target.files[0], { 
        header: false,
        skipEmptyLines: true,
        complete: function (results) {
        const valuesArray=[];
       // console.log(results.data) 
        const x= results.data.map((values)=>Number(values[0]))
        console.log(x)
        y(x)
        },
       
    });
            
    };
  



    return (

        <div>
             
        <form>

        {/* <input
                    type="file"
                    //id={""}
                    accept=".csv"
                    id={"csvFileInput"}
                    onChange={(e)=>{handleOnChange(e,array,setArray)}}
                    //style={{ display: "block", margin: "10px auto" }}
                     
                />
                   <input
                    type="file"
                    //id={""}
                    
                    accept=".csv"
                    id={"csvFileInputend"}
                    onChange={(e)=>{handleOnChange(e,arrayend,setArrayend)}}
                   
                   // style={{ display: "block", margin: "10px auto" }}
                     
                />
                <input
                    type="file"
                    //id={""}
                    accept=".csv"
                    id={"csvFileInputM"}
                    onChange={(e)=>{handleOnChange(e,arrayM,setArrayM)}}
                    //style={{ display: "block", margin: "10px auto" }}
                     
                /> */}
                <br></br>
</form>
        <br></br>
        <ConfigurationForm 
        requestType="post"
        configurationID={null}
        btnText="Configure"
        datacsv={array}      
        datacsvend={arrayend} 
        datacsvM={arrayM}          />


  
        <br />
      
        </div>
        
    );
    
}

export default Config;


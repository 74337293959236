import React, { useState, useEffect } from "react";
import { List, Space, Form, Input, Button, Modal } from "antd";
import axios from "axios";

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);

const Actions = () => {
  const [deleteActionID, setDeleteActionID] = useState(null);
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    title_ac: "",
    content_ac: "",
  });
  const [editActionID, setEditActionID] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://www.sptapp.eu/api2/v1/?username=${localStorage.getItem(
          "user"
        )}`
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = (actionID) => {
    setDeleteActionID(actionID);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(
        `https://www.sptapp.eu/api2/v1/${deleteActionID}/deleteA/`
      );
      await fetchData();
      setDeleteActionID(null); // Reset deleteActionID after successful deletion
    } catch (error) {
      console.error("Error deleting action:", error);
    }
  };

  const handleCancelDelete = () => {
    setDeleteActionID(null); // Reset deleteActionID if cancellation occurs
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editActionID) {
        await axios.put(
          `https://www.sptapp.eu/api2/v1/${editActionID}/updateA/`,
          {
            title_ac: formData.title_ac,
            content_ac: formData.content_ac,
          }
        );
        setEditActionID(null); // Reset editActionID after successful update
      } else {
        await axios.post("https://www.sptapp.eu/api2/v1/createA/", {
          title_ac: formData.title_ac,
          content_ac: formData.content_ac,
          username: localStorage.getItem("user"),
        });
      }
      await fetchData();
      setFormData({
        title_ac: "",
        content_ac: "",
      });
    } catch (error) {
      console.error("Error creating/editing action:", error);
    }
  };

  const handleEdit = (actionID, title, content) => {
    setEditActionID(actionID);
    setFormData({
      title_ac: title,
      content_ac: content,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <>
        <br />
        <div>
          <b>Follow these actions for your project decarbonization</b>
        </div>

        <List
          itemLayout="Horizontal"
          size="large"
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 4,
          }}
          dataSource={data}
          footer={
            <div>
              <b> </b>
            </div>
          }
          renderItem={(item) => (
            <List.Item key={item.actionID}>
              <Space direction="horizontal">
                <label>
                  <a>{item.title_ac}</a> {item.actionID}: {item.content_ac}{" "}
                  <Button onClick={() => handleDelete(item.actionID)}>
                    Delete
                  </Button>{" "}
                  <Button
                    onClick={() =>
                      handleEdit(item.actionID, item.title_ac, item.content_ac)
                    }
                  >
                    Edit
                  </Button>
                </label>
              </Space>
            </List.Item>
          )}
        />
        <Form.Item label="Action Title">
          <Input
            name="title_ac"
            value={formData.title_ac}
            onChange={handleChange}
            style={{ maxWidth: "206px" }}
          />
        </Form.Item>
        <Form.Item label="Content">
          <Input.TextArea
            name="content_ac"
            value={formData.content_ac}
            onChange={handleChange}
            style={{
              maxWidth: "206px",
              marginLeft: "21px",
              gap: "18px",
              maxHeight: "200px",
            }}
          />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          {editActionID ? "Update" : "Add action"}
        </Button>

        <Modal
          title="Confirm Delete"
          visible={deleteActionID !== null}
          width={300}
          onOk={handleConfirmDelete}
          onCancel={handleCancelDelete}
        >
          <p>Are you sure you want to delete this action?</p>
        </Modal>
      </>
    </form>
  );
};

export default Actions;

import React, { useState, useEffect, useRef, createRef } from "react";
import {
  Row,
  Col,
  Divider,
  Button,
  DatePicker,
  Form,
  Input,
  ConfigProvider,
  Select,
  Checkbox,
  notification,
  message,
  Modal,
} from "antd";
import axios from "axios";
import { Space, Table, Tag } from "antd";
//import { FileDrop } from 'react-file-drop';
import Papa from "papaparse";
import {
  UploadOutlined,
  HomeTwoTone,
  ThunderboltTwoTone,
  HddTwoTone,
  InfoCircleTwoTone,
  StarOutlined,
} from "@ant-design/icons";
import { ReactComponent as BatteryIcon } from "./battery.svg";
//const [packagingFields, setPackagingFields] = useState(['']);
import { ConfigService } from "../services/configuration";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSolarPanel,
  faWind,
  faBatteryFull,
  faFlask,
  faGenerator,
} from "@fortawesome/free-solid-svg-icons";
import Chart from "chart.js";
import "chartjs-plugin-zoom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { withTranslation } from "./WithTranslation";
// Now you can use moment in your code
import { axiosApiInstance } from "../services/axiosAPI";
import "./App.css";

const { Option } = Select;

class ConfigurationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      generatorList: [
        {
          type: "pvSolar",
          name: "Name1",
          capacity: "Capacity",
          panelPowerW: "Orientation",
          tiltAngle: "tiltAngle",
          nameW: "nameW",
          capacityW: "capacityW",
          std: "std",
          hubHeightm: "heigh",
        },
      ],
      types: ["pvSolar", "windturbine", "Diesel*"],
      buildingList: [
        {
          type: "hospital",
          nameH: "Name1",
          yearlykWh: "yearlykWh",
          nameC: "NameC",
          nameHP: "NameHP",
          weekdaykWh: "weekday",
          weekendkWh: "weekend",
          monthlykWh: "monthly",
          yearlyloads: "Yearlyloads",
          COP: "COP",
          yearlyHP: "YearlyHP",
        },
      ],
      buildingtypes: [
        "custom",
        "hospital",
        "hotel",
        "office",
        "residential",
        "school",
        "commercial",
        "industrial",
        "heatpump",
        "EVs",
      ],
      storageList: [
        {
          type: "Battery",
          nameB: "NameB",
          capacityB: "Capacity",
          nameR: "NameR",
          fuelCellPowerkW: "fuel",
          electrolyserPowerkW: "fuelkW",
        },
      ],
      storageTypes: ["Battery", "FuelCell", "Thermal*"],
      selectedStd: "IEC_1",
      yearlyloads: [],
      selectedFile: [],
      configurations: [],
      files: {},
      loads: {},
      isPopupVisible: false,

      isChecked: true,
      isCheckedS: false,
      isCheckedB: true,
      isCheckedC: false,
      isCheckedT: false,
      isCheckedA: true,
      loading: true,
      visibleModal: false,
      deleteconfigID: null,
      errorMessage: "",
      verrourHPC: false,
      COF: 1,
    };
    this.popupRef = createRef();

    this.getData = this.getData.bind(this);
  }

  async componentDidMount() {
    try {
      const response = await axiosApiInstance.get(
        `api/v1/?username=${localStorage.getItem("user")}`
      );
      const configurations = response.data;

      // Set configurations in state
      this.setState({ configurations, loading: false });
    } catch (error) {
      console.error("Error fetching configurations:", error);
      this.setState({ loading: false });
      message.error("Error fetching configurations. Please try again later.");
    }

    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    // Close the WebSocket connection when the component is unmounted
    // this.socket.close();
    /*  if (this.chart) {
    this.chart.destroy();
  } */
    document.removeEventListener("mousedown", this.handleClickOutside);
    this.setState = (state, callback) => {
      return;
    };
    // this.socket.disconnect();
  }

  async componentDidUpdate() {
    if (this.state.loading) {
      await axiosApiInstance
        .get(`api/v1/?username=${localStorage.getItem("user")}`)
        .then((res) => {
          this.setState({ configurations: res.data, loading: false });
          console.log("conf", this.state.configurations);
        })
        .catch((error) => {
          console.log("ERROR::: ", error.res);
        });
      this.setState({ loading: false });
    }
  }

  handleIconClick = () => {
    this.setState({ isPopupVisible: true });
  };

  handleClickOutside = (event) => {
    if (
      this.popupRef.current &&
      !this.popupRef.current.contains(event.target)
    ) {
      this.setState({ isPopupVisible: false });
    }
  };

  getData(results) {
    this.setState({ data: results.data });
  }

  handleDelete(configurationID) {
    // Perform deletion logic using the configurationID
    this.setState({ deleteconfigID: configurationID, visibleModal: true });
  }

  handleCancelDelete = () => {
    this.setState({ deleteconfigID: null, visibleModal: false });
  };

  /* console.log('Deleting configuration with ID:', configurationID);
  // Here you can implement the logic to delete the configuration

  await axios.delete(`https://www.sptapp.eu/api/v1/${configurationID}/deleteC/`)
  .then ()
    .catch(error => {
      console.log("ERROR::: ",error.res);});

  await axios.get(`https://www.sptapp.eu/api/v1/?username=${localStorage.getItem('user')}`)
   .then (res =>{
 this.setState({ configurations: res.data, loading:false}); console.log("conf", this.state.configurations);  
 })
 .catch(error => {
   console.log("ERROR::: ",error.res);});
};
 */

  handleConfirmDelete = async () => {
    const { deleteconfigID } = this.state;
    try {
      await axiosApiInstance.delete(`api/v1/${deleteconfigID}/deleteC/`);
      await axiosApiInstance
        .get(`api/v1/?username=${localStorage.getItem("user")}`)
        .then((res) => {
          this.setState({ configurations: res.data, loading: false });
          console.log("conf", this.state.configurations);
        });
      this.setState({ deleteconfigID: null, visibleModal: false });
    } catch (error) {
      console.error("Error deleting configuration:", error);
    }
  };

  handleSubmit = async (
    e,
    requestType,
    configurationID,
    datacsv,
    datacsvend,
    datacsvM
  ) => {
    // const country = useRef()
    // const town = useRef()
    console.log("values", e.target.elements);
    if (
      this.state.configurations.some(
        (config) => config.name === e.target.elements.name.value
      )
    ) {
      message.error("Name already exists! Please choose a different name.");
    }

    e.preventDefault();
    console.log("eee", e);

    const date = e.target.elements.date.value;
    const country = e.target.elements.country.value;
    const town = e.target.elements.town.value;
    const name = e.target.elements.name.value;
    /* const name =e.target.elements.name_0.value;
const capacity=e.target.elements.capacity_0.value;
const panelPowerW =e.target.elements.panelPowerW_0.value;
const tiltAngle =e.target.elements.tiltAngle_0.value;
 */
    //console.log("hello alert", e.target.elements.date.value)
    if (!e.target.elements.date.value) {
      //console.log("hello alert")
      // Show an alert using Ant Design's message component
      message.error("Please select a start date");
      return;
    }

    let batteryCount = 0;
    if (this.state.isCheckedS === true) {
      for (let i = 0; i < this.state.storageList.length; i++) {
        if (this.state.storageList[i].type === "Battery") {
          this.state.storageList[i].nameB =
            e.target.elements[`nameB_${i}`].value;
          this.state.storageList[i].capacityB = parseFloat(
            e.target.elements[`capacityB_${i}`].value
          );
        } else if (this.state.storageList[i].type === "FuelCell") {
          this.state.storageList[i].nameR =
            e.target.elements[`nameR_${i}`].value;
          this.state.storageList[i].fuelCellPowerkW = parseFloat(
            e.target.elements[`fuelCellPowerkW_${i}`].value
          );
          this.state.storageList[i].electrolyserPowerkW = parseFloat(
            e.target.elements[`electrolyserPowerkW_${i}`].value
          );
        }
      }
    } else {
      this.state.storageList = [];
    }

    // Generators list filling

    if (this.state.isChecked === true) {
      for (let i = 0; i < this.state.generatorList.length; i++) {
        if (this.state.generatorList[i].type === "pvSolar") {
          this.state.generatorList[i].name =
            e.target.elements[`name_${i}`].value;
          this.state.generatorList[i].capacity = parseFloat(
            e.target.elements[`capacity_${i}`].value
          );
          this.state.generatorList[i].panelPowerW = parseFloat(
            e.target.elements[`panelPowerW_${i}`].value
          );
          this.state.generatorList[i].tiltAngle = parseFloat(
            e.target.elements[`tiltAngle_${i}`].value
          );

          // console.log("nothinggg", e.target.elements.std_1.value)
        } else if (this.state.generatorList[i].type === "windturbine") {
          this.state.generatorList[i].nameW =
            e.target.elements[`nameW_${i}`].value;
          this.state.generatorList[i].capacityW = parseFloat(
            e.target.elements[`capacityW_${i}`].value
          );
          // this.state.generatorList[i].std = this.state.selectedStd//e.target.elements[`std_${i}`].value;  be carefull .............
          this.state.generatorList[i].hubHeightm = parseFloat(
            e.target.elements[`hubHeightm_${i}`].value
          );
        }
      }
    } else {
      this.state.generatorList = [];
    }

    if (this.state.isCheckedB === true) {
      for (let i = 0; i < this.state.buildingList.length; i++) {
        if (
          this.state.buildingList[i].type != "custom" &&
          this.state.buildingList[i].type != "heatpump"
        ) {
          this.state.buildingList[i].nameH =
            e.target.elements[`nameH_${i}`].value;
          this.state.buildingList[i].yearlykWh = parseFloat(
            e.target.elements[`yearlykWh_${i}`].value
          );
        } else if (this.state.buildingList[i].type === "custom") {
          this.state.buildingList[i].nameC =
            e.target.elements[`nameC_${i}`].value;
          const loadsArray = this.state.loads[i].map((load) =>
            parseFloat(load)
          );
          const COF = parseFloat(e.target.elements[`COF_${i}`].value);
          console.log("New COF:", COF);

          //this.state.buildingList[i].COP =e.target.elements[`COP_${i}`].value;
          if (!isNaN(COF)) {
            const newLoadArrayC = loadsArray.map((load) =>
              parseFloat((load * COF).toFixed(2))
            );
            console.log("New load:", newLoadArrayC);
            this.state.buildingList[i].yearlyloads = newLoadArrayC;
          } else {
            this.state.buildingList[i].yearlyloads = this.state.loads[i];
            console.log("==1", this.state.loads[i]);
          }
        } else if (this.state.buildingList[i].type === "heatpump") {
          const loadsArray = this.state.loads[i].map((load) =>
            parseFloat(load)
          );
          const COP = parseFloat(e.target.elements[`COP_${i}`].value);

          // Check if COP is not 0 to avoid division by zero
          if (COP !== 0) {
            const newLoadArray = loadsArray.map((load) =>
              parseFloat((load / COP).toFixed(2))
            );
            console.log("New load:", newLoadArray);
            this.state.buildingList[i].nameHP =
              e.target.elements[`nameHP_${i}`].value;
            this.state.buildingList[i].COP = COP;
            this.state.buildingList[i].yearlyHP = newLoadArray;
            console.log("New load:", newLoadArray);
          } else {
            console.error("COP cannot be zero.");
          }
        }
      }
    } else {
      this.state.buildingList = [];
    }

    /* const nameB =e.target.elements.nameB.value;
const capacityB =e.target.elements.capacityB.value;
const nameR =e.target.elements.nameR.value;
const fuelCellPowerkW =e.target.elements.fuelCellPowerkW.value;
const electrolyserPowerkW =e.target.elements.electrolyserPowerkW.value; */
    const csvoutput = datacsv;
    //const csvoutput =  this.props.results.data; //Papa.parse(e.target.files[0])///e.target.elements.csvFile.result);

    //const title= document.getElementById('country').value;
    //
    //const content= document.getElementById('town').value;
    //

    switch (requestType) {
      // ConfigService.create( {   axios.post('https://www.sptapp.eu/api/v1/createC/', {
      //axios.post('https://spt-vpp4islands.herokuapp.com/api/v1/createC/', {
      case "post":
        return ConfigService.create({
          date: date,
          username: localStorage.getItem("user"),
          name: name,
          location: { country: country, town: town },
          // pvSolar:{name: name, capacity: parseFloat(capacity), panelPowerW: parseFloat(panelPowerW), tiltAngle:parseFloat(tiltAngle)},
          storages: this.state.storageList,

          generators: this.state.generatorList,

          //regenerativeHydrogens:[{name: nameR, fuelCellPowerkW: parseFloat(fuelCellPowerkW), electrolyserPowerkW: parseFloat(electrolyserPowerkW)}],
          buildings: this.state.buildingList,
          weekday: csvoutput,
          weekend: datacsvend,
          monthly: datacsvM,
        })

          .then((res) => {
            console.log(res);
            this.setState({ loading: true });
            message.success("Configuration successful");
          })
          .catch((error) => console.error(error));

      // case 'put': return axios.put(`https://www.sptapp.eu/api/v1/${configurationID}/updateC/`, {
      //   country: country,
      //   town: town
      //   })
      //   .then (res =>console.log(res))
      //   .catch (error => console.error(error))

      case "delete":
        return axiosApiInstance
          .delete(`api/v1/${configurationID}/deleteC/`)
          .then((res) => console.log(res))
          .catch((error) => console.error(error));
    }
  };

  handleStorageTypeChange = (value, index) => {
    this.setState((prevState) => {
      const updatedStorageList = [...prevState.storageList];
      const newStorage = {
        type: value,
        nameB: "Name",
        capacityB: "Capacity",
        nameR: "NameR",
        fuelCellPowerkW: "fuel",
        electrolyserPowerkW: "fuelkW",
      };

      /* if (value === 'FuelCell') {
      newStorage.nameR = 'defaultNameR';
      console.log('nameR', newStorage.nameR )
      newStorage.fuelCellPowerkW = 'defaultFuelCellPowerkW';
      newStorage.electrolyserPowerkW = 'defaultPowerkW';
    } */
      console.log("nameR", newStorage.nameR);
      updatedStorageList[index] = newStorage;
      console.log("list", updatedStorageList[0]);

      return { storageList: updatedStorageList };
    });
  };

  clearInputs = (index) => {
    // Loop through inputs and clear their values
    const inputsToClear = [
      `nameH_${index}`,
      `yearlykWh_${index}`,
      `nameHP_${index}`,
      `COP_${index}`,
      `nameC_${index}`,
    ];

    console.log("i clean");

    inputsToClear.forEach((inputName) => {
      const input = document.querySelector(`input[name="${inputName}"]`);
      console.log("inputs", input);
      if (input) {
        input.value = ""; // Clear input value
      }
    });
  };

  handleAddStorage = () => {
    const newStorage = {
      type: "",
      nameB: "cc",
      capacityB: "capacity",
      nameR: "NamexR",
      fuelCellPowerkW: "fuel",
      electrolyserPowerkW: "fuelkW",
    };

    // Check if all user-entered values in the new storage are not empty

    this.setState((prevState) => ({
      storageList: [...prevState.storageList, newStorage],
    }));
  };

  renderStorageConfig = (storage, index) => {
    const { type } = storage;
    console.log("storage", storage.nameR);
    if (type === "Battery") {
      return (
        <>
          <Input.Group style={{ display: "flex", gap: "8px", width: "100%" }}>
            <Input
              name={`nameB_${index}`}
              defaultValue={storage.nameB}
              style={{ flex: 1 }}
            />
            <Input
              name={`capacityB_${index}`}
              defaultValue={storage.capacityB}
              style={{ flex: 1 }}
            />
          </Input.Group>
        </>
      );
    } else if (type === "FuelCell") {
      const defaultValues = {
        nameR: "defaultName",
        fuelCellPowerkW: "defaultFuelCellPowerkW",
        electrolyserPowerkW: "defaultElectrolyserPowerkW",
      };

      return (
        <>
          {/* Clear the values for Battery when changing to FuelCell */}
          <Input.Group style={{ display: "flex", gap: "8px", width: "100%" }}>
            <Input
              name={`nameB_${index}`}
              defaultValue=""
              style={{ flex: 1, display: "none" }}
            />
            <Input
              name={`capacityB_${index}`}
              defaultValue=""
              style={{ flex: 1, display: "none" }}
            />

            {/* Display the values for FuelCell */}
            <Input
              name={`nameR_${index}`}
              defaultValue={defaultValues.nameR}
              style={{ flex: 1 }}
            />
            <Input
              name={`fuelCellPowerkW_${index}`}
              defaultValue={defaultValues.fuelCellPowerkW}
              style={{ flex: 1 }}
            />
            <Input
              name={`electrolyserPowerkW_${index}`}
              defaultValue={defaultValues.electrolyserPowerkW}
              style={{ flex: 1 }}
            />
          </Input.Group>
        </>
        // TODO to add Capcity to FuelCELL
      );
    } else {
      // Handle other types if needed
      return null;
    }
  };

  // File load

  handleOnChange = (e, i, callback) => {
    e.preventDefault();

    Papa.parse(e.target.files[0], {
      header: false,
      skipEmptyLines: true,
      complete: function (results) {
        const valuesArray = [];
        const parsedValues = results.data.map((values) => Number(values[0]));
        console.log(parsedValues);
        callback(parsedValues);
      },
    });
  };

  // Building creation and adding

  handleAddBuilding = () => {
    this.setState((prevStateB) => ({
      buildingList: [...prevStateB.buildingList, { nameH: "", yearlykWh: "" }],
    }));
  };

  handleBuildingTypeChange = (value, index) => {
    this.setState((prevStateB) => {
      const updatedBuildingList = [...prevStateB.buildingList];
      const newBuilding = {
        type: value,
        nameH: "Name1",
        yearlykWh: "yearlykWh",
        NameC: "NameC",
        weekdaykWh: "weekday",
        weekendkWh: "weekend",
        monthlykWh: "monthly",
        yearlyloads: "yearlyloads",
      };

      console.log("nameH", newBuilding.nameH);
      updatedBuildingList[index] = newBuilding;
      console.log("list", updatedBuildingList[0]);

      return { buildingList: updatedBuildingList };
    });
  };

  renderBuildingConfig = (building, index) => {
    const { type } = building;
    console.log("Building", building.nameH);
    const defaultValuesH = {
      nameH: "defaultNameH",
      yearlykWh: "yearlykWh",
      nameC: "defaultNameC",
      nameHP: "defaultNameHP",
      COP: "COP",
      COF: 1,
    };

    if (type != "custom" && type != "heatpump") {
      this.clearInputs(index);

      return (
        <>
          <Input.Group style={{ display: "flex", gap: "8px", width: "100%" }}>
            <Input
              name={`nameH_${index}`}
              placeholder={defaultValuesH.nameH}
              style={{ flex: 1 }}
            />
            <Input
              name={`yearlykWh_${index}`}
              placeholder={defaultValuesH.yearlykWh}
              style={{ flex: 1 }}
            />
          </Input.Group>
        </>
      );
    } else if (type === "custom") {
      console.log("typpeeec", type);
      this.clearInputs(index);

      return (
        <>
          {/* Clear the values for Battery when changing to FuelCell */}
          <Input.Group style={{ display: "flex", gap: "8px", width: "100%" }}>
            <Input
              name={`nameH_${index}`}
              style={{ flex: 1, display: "none" }}
            />
            <Input
              name={`yearlykWh_${index}`}
              style={{ flex: 1, display: "none" }}
            />
            <Input
              name={`yearlyHP_${index}`}
              style={{ flex: 1, display: "none" }}
              type="file"
            />

            <Input
              name={`nameHP_${index}`}
              style={{ flex: 1, display: "none" }}
            />
            <Input name={`COP_${index}`} style={{ flex: 1, display: "none" }} />
            {/* Display the values for FuelCell */}
            <Input
              name={`nameC_${index}`}
              placeholder={defaultValuesH.nameC}
              style={{ flex: 1 }}
            />
            {/*         <Input name={`COP_${index}`} defaultValue={defaultValuesH.COP} style={{ flex: 1 }} />
             */}
            <Input
              name={`yearlyloads_${index}`}
              style={{ flex: 1 }}
              type="file"
              //id={""}
              accept=".csv"
              id={`csvfile_${index}`}
              onChange={(e) =>
                this.handleOnChange(e, index, (parsedValues) => {
                  // Do something with parsedValues, for example, set it in the state
                  this.state.loads[index] = parsedValues;
                  console.log("Parsed Values:", parsedValues);
                  // this.setState({ yourStateVariable: parsedValues });
                })
              }

              //this.handleOnChange(e, index)

              //style={{ display: "block", margin: "10px auto" }}
            />
            <Input
              name={`COF_${index}`}
              placeholder={defaultValuesH.COF}
              style={{ flex: 1 }}
            />
          </Input.Group>
        </>
      );
    } else if (type === "heatpump") {
      console.log("typpeeec", defaultValuesH.COP);
      this.clearInputs(index);

      return (
        <>
          {/* Clear the values for Battery when changing to FuelCell */}
          <Input.Group style={{ display: "flex", gap: "8px", width: "100%" }}>
            <Input
              name={`nameH_${index}`}
              style={{ flex: 1, display: "none" }}
            />
            <Input
              name={`yearlykWh_${index}`}
              style={{ flex: 1, display: "none" }}
            />

            <Input
              name={`yearlyloads_${index}`}
              style={{ flex: 1, display: "none" }}
              type="file"
            />

            <Input
              name={`nameHP_${index}`}
              placeholder={defaultValuesH.nameHP}
              style={{ flex: 1 }}
            />
            <Input
              name={`yearlyHP_${index}`}
              style={{ flex: 1 }}
              type="file"
              //id={""}
              accept=".csv"
              id={`csvfile_${index}`}
              onChange={(e) =>
                this.handleOnChange(e, index, (parsedValues) => {
                  // Do something with parsedValues, for example, set it in the state
                  this.state.loads[index] = parsedValues;
                  console.log("Parsed Values:", parsedValues);
                  // this.setState({ yourStateVariable: parsedValues });
                })
              }

              //this.handleOnChange(e, index)

              //style={{ display: "block", margin: "10px auto" }}
            />
            <Input
              name={`COP_${index}`}
              placeholder={defaultValuesH.COP}
              style={{ flex: 1 }}
            />
          </Input.Group>
        </>
      );
    } else {
      // Handle other types if needed
      return null;
    }
  };

  // generation creation and adding

  handleStdChange = (value, index) => {
    console.log("value", value);
    this.setState({ selectedStd: value });
    this.state.generatorList[index].std = value;
    console.log("stddd", this.state.generatorList[index].std);
    console.log("index", index);
  };

  //     ***********    Check boxes ***********

  handleCheckboxChange = (e) => {
    this.setState({ isChecked: e.target.checked });
  };

  handleCheckboxSChange = (e) => {
    this.setState({ isCheckedS: e.target.checked });
  };

  handleCheckboxBChange = (e) => {
    this.setState({ isCheckedB: e.target.checked });
  };

  handleCheckboxCChange = (e) => {
    this.setState({ isCheckedC: e.target.checked });
  };

  handleCheckboxTChange = (e) => {
    this.setState({ isCheckedT: e.target.checked });
  };

  handleCheckboxAChange = (e) => {
    this.setState({ isCheckedA: e.target.checked });
  };

  handleAddGenerator = () => {
    this.setState((prevStateG) => ({
      generatorList: [
        ...prevStateG.generatorList,
        { name: "", capacity: "", panelPowerW: "", tiltAngle: "" },
      ],
    }));
  };

  handleTypeChange = (value, index) => {
    console.log("valuegen", value);

    this.setState((prevStateG) => {
      const updatedGeneratorList = [...prevStateG.generatorList];
      const newGenerator = {
        type: value,
        name: "Name1",
        capacity: "Capacity",
        panelPowerW: "Orientation",
        tiltAngle: "tiltAngle",
        nameW: "nameW",
        capacityW: "capacityW",
        std: "IEC_1",
        hubHeightm: "heigh",
      };

      /* if (value === 'FuelCell') {
      newStorage.nameR = 'defaultNameR';
      console.log('nameR', newStorage.nameR )
      newStorage.fuelCellPowerkW = 'defaultFuelCellPowerkW';
      newStorage.electrolyserPowerkW = 'defaultPowerkW';
    } */
      console.log("std", newGenerator.std);
      updatedGeneratorList[index] = newGenerator;
      console.log("list", updatedGeneratorList[0]);

      return { generatorList: updatedGeneratorList };
    });
  };

  renderInputGroup = (generator, index) => {
    const { type } = generator;
    const { t } = this.props;

    if (type === "pvSolar") {
      return (
        <Input.Group style={{ display: "flex", gap: "8px", width: "100%" }}>
          <Input
            name={`name_${index}`}
            defaultValue={generator.name}
            style={{ flex: 1 }}
          />
          <Input
            name={`capacity_${index}`}
            defaultValue={generator.capacity}
            style={{ flex: 1 }}
          />
          <Input
            name={`panelPowerW_${index}`}
            defaultValue={generator.panelPowerW}
            style={{ flex: 1 }}
          />{" "}
          <Input
            name={`tiltAngle_${index}`}
            defaultValue={generator.tiltAngle}
            style={{ flex: 1 }}
          />
        </Input.Group>
      );
    } else if (type === "windturbine") {
      const defaultValues = {
        nameW: "defaultName",
        capacityW: "CapacityW",
        std: "IEC_1",
        hubHeightm: "defualtHeight",
      };
      const stdOptions = ["IEC_1", "IEC_2", "IEC_3", "offshore"];
      return (
        <>
          <Input.Group style={{ display: "flex", gap: "8px", width: "100%" }}>
            <Input
              name={`name_${index}`}
              defaultValue=""
              style={{ flex: 1, display: "none" }}
            />
            <Input
              name={`capacity_${index}`}
              defaultValue=""
              style={{ flex: 1, display: "none" }}
            />
            <Input
              name={`panelPowerW_${index}`}
              defaultValue=""
              style={{ flex: 1, display: "none" }}
            />
            <Input
              name={`tiltAngle_${index}`}
              defaultValue=""
              style={{ flex: 1, display: "none" }}
            />

            <Input
              name={`nameW_${index}`}
              defaultValue={defaultValues.nameW}
              style={{ flex: 1 }}
            />
            <Input
              name={`capacityW_${index}`}
              defaultValue={defaultValues.capacityW}
              style={{ flex: 1 }}
            />
            <Select
              name={`std_${index}`}
              defaultValue={defaultValues.std}
              onChange={(value) => this.handleStdChange(value, index)}
              style={{ flex: 1 }}
            >
              {stdOptions.map((option) => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
            <Input
              name={`hubHeightm_${index}`}
              defaultValue={defaultValues.hubHeightm}
              style={{ flex: 1 }}
            />
          </Input.Group>{" "}
        </>
      );
    } else {
      // Render other types here if needed
      return null;
    }
  };

  /*   onFinish = (values) => {
    // Check if the date field is empty
    if (!values.date) {
      // Show an alert using Ant Design's message component
      message.error("Please select a start date");
      return;
    }
  }; */

  render() {
    const {
      buildingList,
      buildingtypes,
      generatorList,
      types,
      isPopupVisible,

      storageList,
      storageTypes,
      errorMessage,
    } = this.state;

    //const {t, i18n} = useTranslation()

    const { t } = this.props;

    const paginationConfig = {
      pageSize: 5, // Set the number of rows per page
      // You can add more pagination options here if needed
    };

    const loc = [this.state.configurations.location];

    const columns = [
      {
        title: t("Configuration_name"),
        dataIndex: "name",
        key: "name",
        render: (text) => <a>{text}</a>,
      },
      {
        title: t("ConfigID"),
        dataIndex: "configurationID",
        key: "configurationID",
      },
      {
        title: t("Location"),
        dataIndex: "location",
        key: "location",
        render: (_, { location }) => (
          <Tag key={location.town} style={{ marginRight: "5px" }}>
            {location.town.toUpperCase()}
          </Tag>
        ),
      },
      {
        title: t("Creation_date"),
        dataIndex: "date_creation",
        key: "date_creation",
      },
      {
        title: t("Assets"),
        key: "generators",
        dataIndex: "generators",
        render: (_, { generators, storages }) => (
          <>
            {generators.map((generator) => {
              let color =
                generator.type === "pvSolar"
                  ? "blue"
                  : generator.type === "windturbine"
                  ? "orange"
                  : "default";
              let icon =
                generator.type === "pvSolar"
                  ? faSolarPanel
                  : generator.type === "windturbine"
                  ? faWind
                  : null;

              // Adjust color based on generator type or any other condition
              return (
                <Tag color={color} key={generator.type}>
                  {icon && (
                    <FontAwesomeIcon
                      icon={icon}
                      style={{ marginRight: "5px" }}
                    />
                  )}

                  {generator.type.toUpperCase()}
                </Tag>
              );
            })}
            {storages.map((storage) => {
              let color =
                storage.type === "Battery"
                  ? "green"
                  : storage.type === "FuelCell"
                  ? "yellow"
                  : "default";
              let icon =
                storage.type === "Battery"
                  ? faBatteryFull
                  : storage.type === "FuelCell"
                  ? faFlask
                  : null;
              return (
                <Tag color={color} key={storage.type}>
                  {icon && (
                    <FontAwesomeIcon
                      icon={icon}
                      style={{ marginRight: "5px" }}
                    />
                  )}
                  {storage.type.toUpperCase()}
                </Tag>
              );
            })}
          </>
        ),
      },
      {
        title: "Actions",
        key: "action",
        render: (_, record) => (
          <Space size="middle">
            <a onClick={() => this.handleDelete(record.configurationID)}>
              Delete
            </a>
          </Space>
        ),
      },
    ];

    console.log("conf2", this.state.configurations);
    //console.log("datac", dataC)
    /* const dataC= this.state.configurations;
  console.log("datac", dataC) */
    const { configurations, loading } = this.state;

    console.log("datac", configurations);

    const mappedConfigurations = configurations;

    console.log("datacmap", mappedConfigurations[0]);

    const validateStartDate = (rule, value) => {
      if (!value) {
        notification.error({
          message: "Validation Error",
          description: "Please select a start date.",
        });
        return Promise.reject("Please select a start date.");
      }
      return Promise.resolve();
    };

    return (
      <form
        onSubmit={(e) =>
          this.handleSubmit(
            e,
            this.props.requestType,
            this.props.configurationID,
            this.props.datacsv,
            this.props.datacsvend,
            this.props.datacsvM
            //   this.props.std
          )
        }
      >
        <div
          style={{
            display: "flex",
            width: "170vh",
            marginBottom: 10,
            backgroundColor: "#f0f0f0",
            padding: 15,
            border: "solid 1px",
            borderColor: "#cccccc",
          }}
        >
          {" "}
          <div>
            {/*  <div style={{ display: 'flex', height: '80vh' }}>
    <div style={{ flex: 1, width: '40%',  padding: '20px' }}> */}

            <Form.Item>
              <label
                htmlFor="name"
                className="country-label"
                rules={[
                  // Define validation rules
                  { required: true, message: "Please enter a name" },
                ]}
              >
                {" "}
                {t("Name")} :{" "}
              </label>
              <Input
                //value="is required"
                style={{
                  maxWidth: "206px",
                  marginLeft: "24px",
                  gap: "18px",
                  maxHeight: "200px",
                }}
                placeholder="Enter_Unique_Name"
                type="text"
                name="name"
              />
            </Form.Item>

            <Form.Item
              label={<span> {t("Start_Date")} </span>}
              namedate="date"
              rules={[{ required: true, message: "Please start data" }]}
            >
              <DatePicker
                name="date"
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
                defaultValue={moment("2022-01-01", "YYYY-MM-DD")}
                //defaultOpenValue={moment('2022-01-01 00:00:00', 'YYYY-MM-DD HH:mm:ss')}
              />
            </Form.Item>

            <div style={{ display: "flex" }}>
              <Form.Item
                label={<span> {t("Country")} </span>}
                name="country"
                //rules={[{ required: true, message: "Please enter the country" },  ]}
              >
                <Input
                  style={{
                    maxWidth: "206px",
                    gap: "18px",
                    maxHeight: "200px",
                  }}
                  placeholder="Enter country"
                  type="text"
                  name="country"
                />
              </Form.Item>
              <Form.Item
                style={{ marginLeft: 5 }}
                label={<span> {t("Town")} </span>}
                name="town"
                // rules={[{ required: true, message: "Please enter the town" }]}
              >
                <Input
                  // value={state}
                  style={{ maxWidth: "200px", maxHeight: "200px" }}
                  placeholder="Enter town"
                  type="text"
                  name="town"
                />
              </Form.Item>
            </div>
            <Divider orientation="left">
              {t("Select_your_config")}{" "}
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <InfoCircleTwoTone
                  onClick={this.handleIconClick}
                  style={{ fontSize: "24px", cursor: "pointer" }}
                />
                {isPopupVisible && (
                  <div
                    ref={this.popupRef}
                    style={{
                      position: "absolute",
                      top: "30px", // Adjust as needed
                      left: "0",
                      border: "1px solid #ccc",
                      backgroundColor: "white",
                      padding: "10px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      zIndex: 1000, // Ensures the popup is above other elements
                    }}
                  >
                    PV Orientation is for azimut: 0=south, 180=north, -90= West
                    and
                    <br></br>
                    90= east. tiltAngle is the inclination of panel.
                    <br></br> You can modify the params via the settings form.
                  </div>
                )}{" "}
              </div>
            </Divider>
            <div style={{ display: "flex", margin: 12, gap: "10px" }}>
              <div>
                <Checkbox
                  value="Generator"
                  checked={this.state.isChecked}
                  onChange={this.handleCheckboxChange}
                >
                  <ThunderboltTwoTone twoToneColor="#FFFF00" /> {t("Generator")}
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  value="Storage"
                  checked={this.state.isCheckedS}
                  onChange={this.handleCheckboxSChange}
                >
                  <HddTwoTone twoToneColor="#008000" /> {t("Storage")}
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  value="Building"
                  checked={this.state.isCheckedB}
                  onChange={this.handleCheckboxBChange}
                >
                  <HomeTwoTone twoToneColor="#eb2f96" /> {t("Load")}
                </Checkbox>
              </div>
            </div>

            <br></br>
            <br></br>

            {this.state.isChecked &&
              generatorList.map((generator, index) => (
                <Form.Item
                  label={`Generator ${index + 1}`}
                  key={index}
                  style={{ display: "flex", gap: "8px", width: "100%" }}
                >
                  <Select
                    value={generator.type}
                    style={{ width: 100 }}
                    onChange={(value) => this.handleTypeChange(value, index)}
                  >
                    {types.map((type, typeIndex) => (
                      <Option key={typeIndex} value={type}>
                        {type}
                      </Option>
                    ))}
                  </Select>

                  {this.renderInputGroup(generator, index)}
                </Form.Item>
              ))}

            {this.state.isChecked && (
              <Form.Item>
                <Button type="primary" onClick={this.handleAddGenerator}>
                  {t("Add_Generator")}
                </Button>
              </Form.Item>
            )}

            {this.state.isCheckedS &&
              storageList.map((storage, index) => (
                <Form.Item
                  label={`Storage ${index + 1}`}
                  key={index}
                  style={{ display: "flex", gap: "8px", width: "100%" }}
                >
                  <Select
                    value={storage.type}
                    style={{ width: 100 }}
                    onChange={(value) =>
                      this.handleStorageTypeChange(value, index)
                    }
                  >
                    {storageTypes.map((storageType, typeIndex) => (
                      <Option key={typeIndex} value={storageType}>
                        {storageType}
                      </Option>
                    ))}
                  </Select>

                  {this.renderStorageConfig(storage, index)}
                </Form.Item>
              ))}

            {this.state.isCheckedS && (
              <Form.Item>
                <Button type="primary" onClick={this.handleAddStorage}>
                  {t("Add_Storage")}
                </Button>
              </Form.Item>
            )}

            {this.state.isCheckedB &&
              buildingList.map((building, index) => (
                <Form.Item
                  label={`Load ${index + 1}`}
                  key={index}
                  style={{ display: "flex", gap: "8px", width: "100%" }}
                >
                  <Select
                    value={building.type}
                    style={{ width: 100 }}
                    onChange={(value) =>
                      this.handleBuildingTypeChange(value, index)
                    }
                  >
                    {buildingtypes.map((buildingType, typeIndex) => (
                      <Option key={typeIndex} value={buildingType}>
                        {buildingType}
                      </Option>
                    ))}
                  </Select>

                  {this.renderBuildingConfig(building, index)}
                </Form.Item>
              ))}

            {this.state.isCheckedB && (
              <Form.Item>
                <Button type="primary" onClick={this.handleAddBuilding}>
                  {t("Add_Load")}
                </Button>
              </Form.Item>
            )}
            <br></br>
            <button
              style={{
                marginBottom: 50,
              }}
              type="primary"
            >
              {" "}
              {t("Configure")}
            </button>

            {/* <div style={{ flex: 1, width: '600',  padding: '20px' }}>

<Table columns={columns} dataSource={mappedConfigurations} loading={loading} pagination={paginationConfig} />
<Modal
          title="Confirm Delete"
          visible={this.state.visibleModal}
          width={320}
          onOk={this.handleConfirmDelete}
          onCancel={this.handleCancelDelete}
        >
          <p>Are you sure you want to delete this configuration?</p>
        </Modal>

</div> */}
            {/* </div>
</div> */}
            <div>
              {/* <div style={{ flex: 1, width: '600',  padding: '20px' }}> */}
              <Table
                columns={columns}
                dataSource={mappedConfigurations}
                loading={loading}
                pagination={paginationConfig}
              />
              <Modal
                title="Confirm Delete"
                visible={this.state.visibleModal}
                width={320}
                onOk={this.handleConfirmDelete}
                onCancel={this.handleCancelDelete}
              >
                <p>Are you sure you want to delete this configuration?</p>
              </Modal>
            </div>
          </div>
        </div>
        {/*      </div> */}
      </form>
    );
  }
}

export default withTranslation(ConfigurationForm);

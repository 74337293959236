import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { Carousel, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
//import { useI18n } from "../i18n";
//import i18next from './i18n';
import { useTranslation } from "react-i18next";
import { UserContext } from "../utils/Usercontext";

const Home = () => {
  const navigate = useNavigate();
  const { isAuth, setisAuth } = useContext(UserContext);

  //const { i18n } = useI18n();

  const { t } = useTranslation();
  const partners = [
    { name: "Partner 1", logo: require("../containers/logo.png") },
    { name: "Partner 2", logo: require("../containers/logo_SEIN.png") },
    { name: "Partner 3", logo: require("../containers/beof_logo.png") },
    { name: "Partner 4", logo: require("../containers/Formentera.png") },
    { name: "Partner 5", logo: require("../containers/Grado.png") },
    { name: "Partner 6", logo: require("../containers/BOZCAADA.png") },

    // Add more logos as needed
  ];

  const slides = [];
  for (let i = 0; i < partners.length; i += 4) {
    slides.push(partners.slice(i, i + 4));
  }

  return (
    <div className="home">
      <section className="banner"></section>
      <br />
      <br />
      <br />
      <section className="video-section">
        <div style={{ display: "flex" }}>
          <div style={{ width: "60%" }}>
            <h2 style={{ fontFamily: "Poppins", fontSize: 28 }}>
              {t("welcome")}
            </h2>
            <p style={{ fontFamily: "Poppins" }}>{t("landingPageHeadTitle")}</p>
            <br />
            <h2 style={{ fontFamily: "Poppins", fontSize: 28 }}>
              {t("embrace")}
            </h2>
            <p style={{ fontFamily: "Poppins" }}>{t("description")}</p>
            <br />
            {!isAuth ? (
              <Button
                type="primary"
                style={{
                  width: 200,
                  height: 40,
                  borderRadius: 10,
                  fontFamily: "Poppins",
                }}
                onClick={() => navigate("/login")}
              >
                {t("getstarted")}
              </Button>
            ) : (
              <></>
            )}
          </div>
          <div className="video-wrapper">
            {/* Replace the video source with your own */}
            <iframe
              width={600}
              height={300}
              src="https://www.youtube.com/embed/O52n1VdBySU"
              title="Smart and Sustainable Planning Tool"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <Carousel autoplay dots={false} infinite className="partner-slider">
        {slides.map((slide, index) => (
          <div key={index} className="logo-slide">
            <Row gutter={16} justify="center">
              {slide.map((partner, idx) => (
                <Col key={idx} span={6} className="logo-container">
                  <img
                    src={partner.logo}
                    alt={`${partner.name} Logo`}
                    style={{
                      maxWidth: "40%",
                      maxHeight: "100px",
                      margin: "0 auto",
                      display: "block",
                    }}
                  />
                </Col>
              ))}
            </Row>
          </div>
        ))}
      </Carousel>
      {/* <div className="cta-buttons" style={{ alignItems: 'center', marginLeft: '350px' }}>
              <Link to="/contact" className="cta-button">Contact</Link>       
            </div> */}
    </div>
  );
};

export default Home;

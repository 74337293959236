import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Divider,
  Button,
  DatePicker,
  Form,
  Input,
  ConfigProvider,
  Select,
  Checkbox,
  notification,
  message,
} from "antd";
import axios from "axios";
//import { FileDrop } from 'react-file-drop';
import Papa from "papaparse";
import {
  UploadOutlined,
  HomeTwoTone,
  ThunderboltTwoTone,
  HddTwoTone,
} from "@ant-design/icons";
//import { ReactComponent as BatteryIcon } from './battery.svg';
//const [packagingFields, setPackagingFields] = useState(['']);
const { Option } = Select;

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      settings: {},
      setID: null,
      lower: "",
      upper: "",
      eprice: "",
      tprice: "",
      opexpv: "",
      capexpv: "",
      co2pv: "",
      opexw: "",
      capexw: "",
      co2w: "",
      opexfc: "",
      capexfc: "",
      co2fc: "",
      opexb: "",
      capexb: "",
      co2b: "",
      co2: "",
    };
  }

  async componentDidMount() {
    //this.socket = new WebSocket('ws://127.0.0.1:8000/app/ws/'); // Replace with your WebSocket URL
    // Open a WebSocket connection

    axios
      .get(
        `https://www.sptapp.eu/api/v1/setting/?username=${localStorage.getItem(
          "user"
        )}`
      )
      .then((res) => {
        this.setState({
          settings: res.data[0],
          lower: res.data[0]["lower"],
          upper: res.data[0]["upper"],
          eprice: res.data[0]["eprice"],
          tprice: res.data[0]["tprice"],
          capexpv: res.data[0]["capexpv"],
          opexpv: res.data[0]["opexpv"],
          co2pv: res.data[0]["co2pv"],
          capexw: res.data[0]["capexw"],
          opexw: res.data[0]["opexw"],
          co2w: res.data[0]["co2w"],
          capexb: res.data[0]["capexb"],
          opexb: res.data[0]["opexb"],
          co2b: res.data[0]["co2b"],
          capexfc: res.data[0]["capexfc"],
          opexfc: res.data[0]["opexfc"],
          co2fc: res.data[0]["co2fc"],
          co2: res.data[0]["co2"],

          setID: res.data[0]["settingID"],
        });
        console.log("result", res.data[0]["settingID"]);
      })
      .catch((error) => {
        console.log("ERROR::: ", error.res);
      });
  }

  handlechangeL = (e) => {
    this.setState({ lower: e.target.value });
  };

  handlechangeU = (e) => {
    this.setState({ upper: e.target.value });
  };
  handlechangeco2 = (e) => {
    this.setState({ co2: e.target.value });
  };

  handlechangeE = (e) => {
    this.setState({ eprice: e.target.value });
  };

  handlechangeT = (e) => {
    this.setState({ tprice: e.target.value });
  };

  handlechangecpv = (e) => {
    this.setState({ capexpv: e.target.value });
  };

  handlechangeopv = (e) => {
    this.setState({ opexpv: e.target.value });
  };

  handlechange2pv = (e) => {
    this.setState({ co2pv: e.target.value });
  };

  handlechangecw = (e) => {
    this.setState({ capexw: e.target.value });
  };

  handlechangeow = (e) => {
    this.setState({ opexw: e.target.value });
  };

  handlechange2w = (e) => {
    this.setState({ co2w: e.target.value });
  };

  handlechangecb = (e) => {
    this.setState({ capexb: e.target.value });
  };

  handlechangeob = (e) => {
    this.setState({ opexb: e.target.value });
  };

  handlechange2b = (e) => {
    this.setState({ co2b: e.target.value });
  };

  handlechangecfc = (e) => {
    this.setState({ capexfc: e.target.value });
  };

  handlechangeofc = (e) => {
    this.setState({ opexfc: e.target.value });
  };

  handlechange2fc = (e) => {
    this.setState({ co2fc: e.target.value });
  };

  handleSubmit = (e) => {
    // const country = useRef()
    // const town = useRef()
    e.preventDefault();
    console.log("eee", e);
    const upper = e.target.elements.upper.value;
    const lower = e.target.elements.lower.value;
    const opexpv = e.target.elements.opexpv.value;
    const capexpv = e.target.elements.capexpv.value;
    const co2pv = e.target.elements.co2pv.value;
    const opexw = e.target.elements.opexw.value;
    const capexw = e.target.elements.capexw.value;
    const co2w = e.target.elements.co2w.value;
    const opexfc = e.target.elements.opexfc.value;
    const capexfc = e.target.elements.capexfc.value;
    const co2fc = e.target.elements.co2fc.value;
    const opexb = e.target.elements.opexb.value;
    const capexb = e.target.elements.capexb.value;
    const co2b = e.target.elements.co2b.value;
    const eprice = e.target.elements.eprice.value;
    const tprice = e.target.elements.tprice.value;
    const co2 = e.target.elements.co2.value;

    console.log(
      "settings",
      upper,
      lower,
      opexpv,
      capexpv,
      co2pv,
      opexw,
      capexw,
      co2w,
      opexfc,
      capexfc,
      co2fc,
      opexb,
      capexb,
      co2b,
      eprice,
      tprice,
      co2,
      this.state.setID
    );
    axios
      .put(`https://www.sptapp.eu/api/v1/setting/${this.state.setID}/update/`, {
        upper: upper,
        username: localStorage.getItem("user"),
        lower: lower,
        opexpv: opexpv,
        capexpv: capexpv,
        co2pv: co2pv,
        opexw: opexw,
        capexw: capexw,
        co2w: co2w,
        opexfc: opexfc,
        capexfc: capexfc,
        co2fc: co2fc,
        opexb: opexb,
        capexb: capexb,
        co2b: co2b,
        eprice: eprice,
        tprice: tprice,
        co2: co2,
      })
      .then((res) => {
        message.success("settings updated");
      })
      .catch((error) => {
        console.log("ERROR::: ", error.res);
      });
  };

  render() {
    console.log("loog", this.state.settings[0]);

    return (
      <form
        onSubmit={(e) =>
          this.handleSubmit(
            e
            //   this.props.std
          )
        }
      >
        <br></br>
        <h3 style={{ fontWeight: "bold", color: "blue" }}>
          Default parameters
        </h3>
        <div style={{ width: "170vh", marginBottom: 10, padding: 5 }}>
          <div style={{ display: "flex", height: "100vh" }}>
            <div style={{ flex: 1, width: "40%", padding: "20px" }}>
              <div style={{ maxWidth: "400px" }}>
                <Divider orientation="center"> Constraints </Divider>{" "}
              </div>{" "}
              <Row
                gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24,
                  lg: 32,
                }}
              >
                <Col className="gutter-row" span={6}>
                  {" "}
                  <div>
                    <label htmlFor="lower" className="country-label">
                      {" "}
                      Lower kW:{" "}
                    </label>
                    <br></br>
                    <Input
                      //value="is required"
                      style={{
                        maxWidth: "206px",
                        gap: "18px",
                        maxHeight: "200px",
                      }}
                      type="text"
                      name="lower"
                      //defaultValue={this.state.settings.lower}
                      value={this.state.lower}
                      onChange={this.handlechangeL}
                    />
                  </div>{" "}
                </Col>

                <Col className="gutter-row" span={6}>
                  {" "}
                  <div>
                    <label htmlFor="upper"> Upper kW: </label>
                    <br></br>
                    <Input
                      // value={state}
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                      type="text"
                      name="upper"
                      value={this.state.upper}
                      onChange={this.handlechangeU}
                    />
                  </div>{" "}
                </Col>

                <Col className="gutter-row" span={6}>
                  {" "}
                  <div>
                    <label htmlFor="upper"> Global gCO2/kW: </label>
                    <br></br>
                    <Input
                      // value={state}
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                      type="text"
                      name="co2"
                      value={this.state.co2}
                      onChange={this.handlechangeco2}
                    />
                  </div>{" "}
                </Col>
              </Row>
              <br></br>
              <div style={{ maxWidth: "400px" }}>
                <Divider orientation="center"> Prices parameters </Divider>{" "}
              </div>
              <Row
                gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24,
                  lg: 32,
                }}
              >
                <Col className="gutter-row" span={6}>
                  {" "}
                  <div>
                    <label htmlFor="town"> Electricty price €kWh: </label>
                    <br></br>
                    <Input
                      // value={state}
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                      type="text"
                      name="eprice"
                      value={this.state.eprice}
                      onChange={this.handlechangeE}
                    />
                  </div>{" "}
                </Col>
                <Col className="gutter-row" span={6}>
                  {" "}
                  <div>
                    <label htmlFor="town"> Trading price €kWh: </label>
                    <br></br>
                    <Input
                      // value={state}
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                      type="text"
                      name="tprice"
                      value={this.state.tprice}
                      onChange={this.handlechangeT}
                    />
                  </div>{" "}
                </Col>
              </Row>
              <br></br>
              <div style={{ maxWidth: "400px" }}>
                <Divider orientation="center"> PV parameters </Divider>{" "}
              </div>
              <Row
                gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24,
                  lg: 32,
                }}
              >
                <Col className="gutter-row" span={6}>
                  {" "}
                  <div>
                    <label htmlFor="country" className="country-label">
                      CAPEX PV €/kW:{" "}
                    </label>
                    <Input
                      //value="is required"
                      style={{
                        maxWidth: "206px",
                        gap: "18px",
                        maxHeight: "200px",
                      }}
                      type="text"
                      name="capexpv"
                      value={this.state.capexpv}
                      onChange={this.handlechangecpv}
                    />
                  </div>{" "}
                </Col>
                <Col className="gutter-row" span={6}>
                  {" "}
                  <div>
                    <label htmlFor="town"> Yearly OPEX PV €/kW: </label>
                    <Input
                      // value={state}
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                      type="text"
                      name="opexpv"
                      value={this.state.opexpv}
                      onChange={this.handlechangeopv}
                    />
                  </div>{" "}
                </Col>

                <Col className="gutter-row" span={6}>
                  {" "}
                  <div>
                    <label htmlFor="town"> Emisssion CO2/kW: </label>
                    <Input
                      // value={state}
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                      type="text"
                      name="co2pv"
                      value={this.state.co2pv}
                      onChange={this.handlechange2pv}
                    />
                  </div>{" "}
                </Col>
              </Row>
              <br></br>
              <div style={{ maxWidth: "400px" }}>
                <Divider orientation="center"> Wind parameters </Divider>{" "}
              </div>
              <Row
                gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24,
                  lg: 32,
                }}
              >
                <Col className="gutter-row" span={6}>
                  {" "}
                  <div>
                    <label htmlFor="country" className="country-label">
                      CAPEX Wind:{" "}
                    </label>
                    <Input
                      //value="is required"
                      style={{
                        maxWidth: "206px",
                        gap: "18px",
                        maxHeight: "200px",
                      }}
                      type="text"
                      name="capexw"
                      value={this.state.capexw}
                      onChange={this.handlechangecw}
                    />
                  </div>{" "}
                </Col>
                <Col className="gutter-row" span={6}>
                  {" "}
                  <div>
                    <label htmlFor="town"> OPEX Wind: </label>
                    <Input
                      // value={state}
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                      type="text"
                      name="opexw"
                      value={this.state.opexw}
                      onChange={this.handlechangeow}
                    />
                  </div>{" "}
                </Col>

                <Col className="gutter-row" span={6}>
                  {" "}
                  <div>
                    <label htmlFor="town"> Emisssion Wind: </label>
                    <Input
                      // value={state}
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                      type="text"
                      name="co2w"
                      value={this.state.co2w}
                      onChange={this.handlechange2w}
                    />
                  </div>{" "}
                </Col>
              </Row>
              <br></br>
              <div style={{ maxWidth: "400px" }}>
                <Divider orientation="center"> Battery parameters </Divider>{" "}
              </div>
              <Row
                gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24,
                  lg: 32,
                }}
              >
                <Col className="gutter-row" span={6}>
                  {" "}
                  <div>
                    <label htmlFor="country" className="country-label">
                      CAPEX Battery:{" "}
                    </label>
                    <Input
                      //value="is required"
                      style={{
                        maxWidth: "206px",
                        gap: "18px",
                        maxHeight: "200px",
                      }}
                      type="text"
                      name="capexb"
                      value={this.state.capexb}
                      onChange={this.handlechangecb}
                    />
                  </div>{" "}
                </Col>
                <Col className="gutter-row" span={6}>
                  {" "}
                  <div>
                    <label htmlFor="town"> OPEX Battery: </label>
                    <Input
                      // value={state}
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                      type="text"
                      name="opexb"
                      value={this.state.opexb}
                      onChange={this.handlechangeob}
                    />
                  </div>{" "}
                </Col>

                <Col className="gutter-row" span={6}>
                  {" "}
                  <div>
                    <label htmlFor="town"> Emisssion Battery: </label>
                    <Input
                      // value={state}
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                      type="text"
                      name="co2b"
                      value={this.state.co2b}
                      onChange={this.handlechange2b}
                    />
                  </div>{" "}
                </Col>
              </Row>
              <br></br>
              <div style={{ maxWidth: "400px" }}>
                <Divider orientation="center">
                  {" "}
                  Fuel Cell (FC) parameters{" "}
                </Divider>{" "}
              </div>
              <Row
                gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24,
                  lg: 32,
                }}
              >
                <Col className="gutter-row" span={6}>
                  {" "}
                  <div>
                    <label htmlFor="country" className="country-label">
                      CAPEX FC:{" "}
                    </label>
                    <Input
                      //value="is required"
                      style={{
                        maxWidth: "206px",
                        gap: "18px",
                        maxHeight: "200px",
                      }}
                      type="text"
                      name="capexfc"
                      value={this.state.capexfc}
                      onChange={this.handlechangecfc}
                    />
                  </div>{" "}
                </Col>
                <Col className="gutter-row" span={6}>
                  {" "}
                  <div>
                    <label htmlFor="town"> OPEX FC: </label>
                    <Input
                      // value={state}
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                      type="text"
                      name="opexfc"
                      value={this.state.opexfc}
                      onChange={this.handlechangeofc}
                    />
                  </div>{" "}
                </Col>

                <Col className="gutter-row" span={6}>
                  {" "}
                  <div>
                    <label htmlFor="town"> Emisssion FC: </label>
                    <Input
                      // value={state}
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                      type="text"
                      name="co2fc"
                      value={this.state.co2fc}
                      onChange={this.handlechange2fc}
                    />
                  </div>{" "}
                </Col>
              </Row>
            </div>
          </div>
          <div
            style={{
              flex: 1,
              width: "600",
              padding: "-60px",
              marginTop: "30px",
            }}
          >
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <button type="primary"> Update settings </button>
          </div>
        </div>
      </form>
    );
  }
}

export default Settings;

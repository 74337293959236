import React from "react";
import Actions from "../components/Action";
import axios from "axios";
import ActionForm from "../components/FormAction";
import BestForm from "../components/FormBest";

class ActionList extends React.Component {
  /*   async componentDidMount() {
       await axios.get(`https://www.sptapp.eu/api2/v1/?username=${localStorage.getItem('user')}`)
        .then (res =>{
            this.setState({
                actions: res.data
            });
            console.log(res.data);
        })

    }; */

  render() {
    return (
      <div>
        <Actions />
        <br />

        {/*  <ActionForm 
                requestType="post"
                actionID={null}
                btnText="Create"
               /> */}
      </div>
    );
  }
}

export default ActionList;

import React from "react";
///import Articles from '../components/Article';
import axios from "axios";
import { Button, Card } from "antd";
import Practices from "../components/Practice";
import PracticeForm from "../components/FormPractice";

//import ObjectID from

class PracticeDetail extends React.Component {
  state = {
    practice: {}, /// object au lieu de list []
  };

  componentDidMount() {
    const practiceID = this.props.match.params.practiceID;
    //const actionID=this.props.Actions(this.props.match.params.id);

    axios
      .get(`https://www.sptapp.eu/api2/v1/Prac/${practiceID}`)
      .then((res) => {
        this.setState({
          practice: res.data,
        });
      });
  }

  handleDelete = (event) => {
    const practiceID = this.props.match.params.practiceID;
    //const actionID=this.props.Actions(this.props.match.params.id);
    axios.delete(`https://www.sptapp.eu/api2/v1/${practiceID}/deleteP/`);
    this.props.history.push("/practices");
    this.forceUpdate();
  };

  render() {
    // <a herf="http://127.0.0.1:3000/actions" >Return</a>
    return (
      <div>
        <Card title={this.state.practice.title_pr}>
          <p>{this.state.practice.content_pr}</p>
          <p>{this.state.practice.practiceID}</p>
        </Card>
        <PracticeForm
          requestType="put"
          practiceID={this.props.match.params.practiceID} //be carreful artcileID was here
          btnText="Update"
        />
        <br />
        <form onSubmit={this.handleDelete}>
          <Button type="danger" htmlType="submit">
            Delete
          </Button>
        </form>
      </div>
    );
  }
}

export default PracticeDetail;

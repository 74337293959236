import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Input } from 'antd';
import axios from 'axios';
import { axiosApiInstance } from '../services/axiosAPI';

class ActionForm extends React.Component {

 handleSubmit = async (e, requestType, actionID) => {
   // const title_ac = useRef()
   // const content_ac = useRef()
  e.preventDefault();
const title_ac = e.target.elements.title_ac.value;
const content_ac =e.target.elements.content_ac.value;

 
   //const title= document.getElementById('title_ac').value;
   // 
   //const content= document.getElementById('content_ac').value;
   // 
   console.log(title_ac, content_ac) 
    switch (requestType){

    case 'post': await axiosApiInstance.post('api2/v1/createA/', {
      title_ac: title_ac,
      content_ac: content_ac,
      username:localStorage.getItem('user'),
      }) 
      .then (res =>console.log(res))
      .catch (error => console.err(error))
    /* case 'put': return axiosApiInstance.put(`api2/v1/${actionID}/updateA/`, {
      title_ac: title_ac,
      content_ac: content_ac
      }) 
      .then (res =>console.log(res))
      .catch (error => console.err(error))
      
    
    case 'delete': return axiosApiInstance.delete(`api2/v1/${actionID}/deleteA/`) 
      .then (res =>console.log(res))
      .catch (error => console.err(error)) */
  
      } 
}
   

  render() {  

  return (
    
    <form onSubmit={(e)=>this.handleSubmit (
      e,
      this.props.requestType,
      this.props.actionID
    )}>
    <Form.Item label="Action Title"> 
    <input 
        name="title_ac"/></Form.Item>  
    <Form.Item label="Content   ">  <input
        name="content_ac"  style={{ maxWidth: "206px",  marginLeft: '21px', gap: '18px', maxHeight: "200px"}} /></Form.Item>
    <button type="primary">{this.props.btnText}</button>
</form>


  );}}
  

    
export default ActionForm;
import {axiosApiInstance} from "./axiosAPI"
 

 
export class ConfigService {
 
    static async create(data) {
        const response = await axiosApiInstance.post('api/v1/createC/', data)
        return response.data
    }

   
    static async list() {
        const response = await axiosApiInstance.get('api/v1/')
        return response.data
    }
 
   /*  static async update(documentId, data) {
        const response = await axiosApiInstance.patch(`${basePath}/${documentId}`, data)
        return response.data
    }
 
    static async retrieve(documentId) {
        const response = await axiosApiInstance.get(`${basePath}/${documentId}`)
        return response.data
    }
 
    static async delete(documentId) {
        const response = await axiosApiInstance.delete(`${basePath}/${documentId}`)
        return response.data
    }
 */
}
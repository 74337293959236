import React from "react";
import Actions from "../components/Action";
import axios from "axios";
import ActionForm from "../components/FormAction";
import BestForm from "../components/FormBest";

class ActionList extends React.Component {
  state = {
    actions: [],
  };

  componentDidMount() {
    axios.get("https://www.sptapp.eu/api2/v1/").then((res) => {
      this.setState({
        actions: res.data,
      });
      console.log("resss actions", res.data);
    });
  }

  render() {
    return (
      <div>
        <Actions data={this.state.actions} />
        <br />
      </div>
    );
  }
}

export default ActionList;

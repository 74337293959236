// login.js

import React, { useState, useContext } from 'react';
import  { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from "../utils/Usercontext.js";

import { Form, Input, Button, message  } from 'antd';

import { UserOutlined,MailOutlined,LockOutlined,GoogleOutlined} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { withTranslation } from './WithTranslation';
import { axiosApiInstance } from '../services/axiosAPI';

  
const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const {t}=useTranslation()

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const navigate = useNavigate();
  const onFinish = (values) => {
    console.log('Received values:', values);
  };
  const { isAuth, setisAuth } = useContext(UserContext);
 const setToken = (token) => {
    localStorage.setItem("token", token);
    localStorage.setItem("user", username);
    console.log("settoken", token)
  };

  const handleLogin = async () => {
    console.log(username, password);
    try {
      const response = await axiosApiInstance.post('accounts/login/', {
        username: username,
        password: password,
 
      });
    {  setToken(response.data.access);   localStorage.setItem("refresh", response.data.refresh);  message.success('Login successful'); console.log("login",response.data); setisAuth(true); navigate('/config');   }
      // Handle login success, e.g., store tokens in local storage, redirect, etc.
    } catch (error) {
        // Handle errors from API
        if (error.response) {
          // The request was made and the server responded with a status code
          // Display error message from server response
          message.error("Login failed",error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          // Display generic error message
          message.error('Could not connect to server');
        } else {
          // Something happened in setting up the request that triggered an Error
          // Display generic error message
          message.error('An error occurred');
        }
      }
      
      // Handle login failure, show error message, etc.
    };

  return (
    <div>
    {/*   <h2>User Login</h2>
      <label>Username:</label>
      <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
      <label>Password:</label>
      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      <button onClick={handleLogin}>Login</button>
    */}

    <Form
      name="login-form"
      onFinish={onFinish}
      layout="vertical"
      style={{ maxWidth: '300px', margin: 'auto' }}
    >



    {/*   
      <Form.Item
        label="Username"
        name="username"
        rules={[{ required: true, message: 'Please enter your username' }]}
      >
        <Input value={username} onChange={(e) => setUsername(e.target.value)}/>
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please enter your password' }]}
      >
        <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} visibilityToggle={showPassword}/>
      </Form.Item>
 */}



<Form.Item
      
      name="username"
      rules={[
        {
          required: true,
          message: 'Please input your Username!',
        },
      ]}
        >
          <Input style={{boxShadow: '0 1px 1px rgba(1, 1, 1, 0)'}}
            name="username"
            type="text"
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
      </Form.Item>


 

  <Form.Item
    name="password"
    rules={[{ required: true, message: 'Please enter your password' }]}
  >
    <Input.Password 
    placeholder='Password'
    style={{boxShadow: '0 1px 1px rgba(1, 1, 1, 0)'}}
    prefix={<LockOutlined className="site-form-item-icon"/>} 
    value={password}
    onChange={(e) => setPassword(e.target.value)} visibilityToggle={showPassword}
    />
  </Form.Item>

      {/* <Button type="link" onClick={togglePasswordVisibility}>
        {showPassword ? 'Hide Password' : 'Show Password'}
      </Button>   */}
      <Button type="link">
        Reset Password     </Button>
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: '100%' }} onClick={handleLogin}>
        {t('login')}
        </Button>
      </Form.Item>




      
    </Form>
 </div>
  );
};

export default withTranslation(Login);

import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Input } from 'antd';
import axios from 'axios';
import { axiosApiInstance } from '../services/axiosAPI';

class PracticeForm extends React.Component {

 handleSubmit = (e, requestType, practiceID) => {
   // const title_ac = useRef()
   // const content_ac = useRef()
  e.preventDefault();
const title_pr = e.target.elements.title_pr.value;
const content_pr =e.target.elements.content_pr.value;

 
   //const title= document.getElementById('title_ac').value;
   // 
   //const content= document.getElementById('content_ac').value;
   // 
   console.log(title_pr, content_pr) 
    switch (requestType){

    case 'post': return axiosApiInstance.post('api2/v1/createP/', {
      title_pr: title_pr,
      content_pr: content_pr,
      }) 
      .then (res =>console.log(res))
      .catch (error => console.err(error))
    case 'put': return axiosApiInstance.put(`api2/v1/${practiceID}/updateP/`, {
      title_pr: title_pr,
      content_pr: content_pr
      }) 
      .then (res =>console.log(res))
      .catch (error => console.err(error))
      
    
    case 'delete': return axiosApiInstance.delete(`api2/v1/${practiceID}/deleteP/`) 
      .then (res =>console.log(res))
      .catch (error => console.err(error))
  
      } 
}
   

  render() {  

  return (
    
    <form onSubmit={(e)=>this.handleSubmit (
      e,
      this.props.requestType,
      this.props.practiceID
    )}>
    <Form.Item label="Practice Title"> <input
        name="title_pr"/></Form.Item>  
    <Form.Item label="Content  ">  <input
        name="content_pr" style={{ maxWidth: "206px",  marginLeft: '30px', gap: '18px', maxHeight: "200px"}}/></Form.Item>
    <button type="primary">{this.props.btnText}</button>
</form>


  );}}
  

    
export default PracticeForm;
import React, { useState, useEffect, useRef, createRef } from "react";
import {
  Modal,
  Checkbox,
  Button,
  DatePicker,
  Form,
  Input,
  ConfigProvider,
  Radio,
  Dropdown,
  Menu,
  TreeSelect,
  message,
  Progress,
  Select,
} from "antd";
import plotButtonImage from "./charts.png";
import { Bar } from "react-chartjs-2";
import axios from "axios";
//import { FileDrop } from 'react-file-drop';
import Papa from "papaparse";
import "./App.css";
import MenuItem from "antd/lib/menu/MenuItem";
import {
  PlaySquareOutlined,
  VerticalAlignBottomOutlined,
  HomeTwoTone,
  DiffTwoTone,
  InteractionTwoTone,
  CalendarTwoTone,
  ThunderboltTwoTone,
  YoutubeOutlined,
  FundTwoTone,
  EuroTwoTone,
  InfoCircleTwoTone,
  CloudTwoTone,
} from "@ant-design/icons";
import randomColor from "randomcolor";
//import io from 'socket.io-client';
import { Typography, Card, Spin, Col, Grid, Divider, Row } from "antd";
import { Space, Table, Tag } from "antd";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { CategoryScale, LinearScale, BarElement } from "chart.js";
import { Chart, registerables } from "chart.js/auto";
import "chartjs-plugin-zoom";
import "./chartjs-plugin-zoom-custom.css";
import { Line } from "react-chartjs-2";
import FootprintIcon from "./Footprinticon";
import { Navigate } from "react-router-dom";
import zoomPlugin from "chartjs-plugin-zoom";
//import LineChart from './chartline.js'
import ResetZoomButton from "./ResetZoomButton";
import { axiosApiInstance } from "../services/axiosAPI";
import "./App.css";
import { withTranslation } from "./WithTranslation";

Chart.register(CategoryScale, LinearScale, BarElement);
Chart.register(zoomPlugin);
const { Option } = Select;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const styleD = {
  background: "#0092ff",
  padding: "8px 0",
};
const { Title, Text } = Typography;
//const socket = new WebSocket('ws://127.0.0.1:8000/app/ws/');

const paginationConfig = {
  pageSize: 3, // Set the number of rows per page
  // You can add more pagination options here if needed
};

const zoomOptions = {
  zoom: {
    wheel: {
      enabled: true,
    },
    pinch: {
      enabled: true,
    },
    mode: "x",
    speed: 2, //100
  },
  pan: {
    enabled: true,
    mode: "y",
    speed: 2, //0.5
  },
};
// </block>

const panStatus = () => (zoomOptions.pan.enabled ? "enabled" : "disabled");
const zoomStatus = () =>
  zoomOptions.zoom.wheel.enabled ? "enabled" : "disabled";

class SimulationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedConfiguration: null,
      selectedResult: null,

      configurations: [],
      results: [],
      resultsR: [],
      datasets: [],
      chart: null,
      isPopupVisible: false,
      //chartInstance : null,
      plotR: [],
      plotRT: [],
      CSV: [],
      csvArray: [],
      simIDR: "",
      itemr: 0,
      simID: "",
      itemm: 0,
      simulation: null,
      options: null,
      verrou: false,
      verrouS: false,
      verrouPDF: false,
      visible: false,
      selectedOption: "hourly",
      selectedOption2: "Daily",

      radioStates: {
        P1: true,
        P2: true,
        P3: true,
        P4: true,
        P5: true,
        P6: true,
      },
      Dynamics: false,
      Usage: false,
      Efficiency: false,
      Cashflow: false,
      NPV: false,
      SoC: false,
      isCheckedSC: true,
      isCheckedT: false,
      isCheckedA: false,
      country: "",
      town: "",
      capacity: "",
      orientation: "",
      inclination: "",
      IRRR: "0.0",
      SAVR: "0.0",
      LCOER: "0.0",
      BUILDR: [],
      SELF: [],
      NAMES: [],
      selectedRequest: [],
      PAYR: "0.0",
      ABATR: "0.0",
      Foot: "0.0",

      DECARBR: "0.0",
      RETR: "0.0",
      showSpin: false,
      selectedCheckboxes: [],
      chartVisible: false,
      labely: "kWh",
      staked: true,
      length: 0,
      SystemDelivered: 0,
    };
    this.popupRef = createRef();
    this.formRef = React.createRef();
    this.chartRef = React.createRef(); // Initialize the chart reference
    this.isComponentMounted = false;
    //this.socket = new WebSocket('ws://127.0.0.1:8000/app/ws/');
  }

  async componentDidMount() {
    // Replace with your WebSocket URL
    // Open a WebSocket connection
    /*   const connectPromise = new Promise((resolve) => {
  // Open a WebSocket connection
  console.log('userrr', localStorage.getItem('user'))
  this.socket = new WebSocket('ws://127.0.0.1:8000/app/ws/');
      document.addEventListener("mousedown", this.handleClickOutside);

  // WebSocket onopen event
  this.socket.onopen = () => {
    console.log('WebSocket connection opened');
    resolve();
  };

  // WebSocket onclose event

});
await connectPromise;   */

    //   this.socket = io('https://www.sptapp.eu/app/ws/');

    // Socket.io connect event
    /* this.socket.on('connect', () => {
  console.log('Socket.io connection opened');
  resolve();
}); */

    // WebSocket onclose event
    /*   this.socket.onclose = () => {
      console.log('WebSocket connection closed');
    }; */
    // });
    //await connectPromise;
    document.addEventListener("mousedown", this.handleClickOutside);

    await axiosApiInstance
      .get(`api/v1/res/?username=${localStorage.getItem("user")}`)
      .then((res) => {
        this.setState({ results: res.data, length: res.data.length });
        console.log("result", this.state.results["0"], res.data.length);
      })
      .catch((error) => {
        console.log("ERROR::: ", error.res);
      });

    // const configurationID =this.props.match.params.configurationID; // ca bloque
    await axiosApiInstance
      .get(`api/v1/?username=${localStorage.getItem("user")}`)
      .then((res) => {
        this.setState({ configurations: res.data });
      })
      .catch((error) => {
        console.log("ERROR::: ", error.res);
      });
    this.isComponentMounted = true; // Mark the component as mounted
    this.deferChartInitialization();
    // this.initializeChartB();
    console.log("i am not created");
  }

  deferChartInitialization = () => {
    requestAnimationFrame(() => {
      this.initializeChartB();
    });
  };

  async getResults() {
    //let timer = undefined;
    if (this.state.showSpin === true) {
      await new Promise((resolve, reject) => {
        const timer = setInterval(async () => {
          console.log("inetvale");
          await axiosApiInstance
            .get(`api/v1/res/?username=${localStorage.getItem("user")}`)
            .then((res) => {
              if (this.state.length < res.data.length) {
                this.setState({
                  results: res.data,
                  length: res.data.length,
                  showSpin: false,
                });
                console.log("result", this.state.results["0"], res.data.length);
                resolve();
                clearInterval(timer);
              } else {
              }
            })
            .catch((error) => {
              console.log("ERROR::: ", error.res);
            });
          reject();
          clearInterval(timer);
        }, 3000);
      });
      //clearInterval(timer);
    }
  }

  /* componentDidUpdate(prevProps, prevState) {
  // Check if showSpin has changed from false to true
  if (!prevState.showSpin && this.state.showSpin) {
    // Call handleSimulation when showSpin becomes true
    console.log("waiting for simulation")
    //this.state.verrou=true

    //this.handleSimulation();
  } 
  
}   */

  componentWillUnmount() {
    // Close the WebSocket connection when the component is unmounted
    // this.socket.close();
    /*  if (this.chart) {
    this.chart.destroy();
  } */
    this.isComponentMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
    this.setState = (state, callback) => {
      return;
    };
    // this.socket.disconnect();
  }

  handleIconClick = () => {
    this.setState({ isPopupVisible: true });
  };

  handleClickOutside = (event) => {
    if (
      this.popupRef.current &&
      !this.popupRef.current.contains(event.target)
    ) {
      this.setState({ isPopupVisible: false });
    }
  };

  handleChangeDWM = (value) => {
    this.setState({ selectedOption: value });
    console.log("value", value);
    this.handleClickBDWM(value);
  };
  handleChangeDWMU = (value) => {
    this.setState({ selectedOption2: value });
    console.log("value", value);
    this.handleClickBDWM(value);
  };

  handleSimulation = async () => {
    // this.socket = new WebSocket('wss://www.sptapp.eu/app/ws/');
    message.error("Please wait for the spining finishing");
    // Create a promise to wait for the "stop_loading" message
    const simulationFinishedPromise = new Promise((resolve) => {
      this.socket.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.type === "stop_loading") {
          // Stop loading indicator
          this.setState({ showSpin: false });
          console.log("status spining", this.state.showSpin);

          // Resolve the promise when "stop_loading" is received
          resolve();
        }
        // Handle other message types if needed
      };

      // WebSocket onmessage event
      /* this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.type === 'stop_loading') {
        // Stop loading indicator
        this.setState({ showSpin: false });
        console.log("status spining", this.state.showSpin)

        // Resolve the promise when "stop_loading" is received
        resolve();
      }
      // Handle other message types if needed
    };
  });
 */
    });
    // Wait for the "stop_loading" message
    await simulationFinishedPromise;

    //this.resulthandle()
    //return this.state.results
  };

  resulthandle = async () => {
    setTimeout(async () => {
      await axiosApiInstance
        .get(`api/v1/res/?username=${localStorage.getItem("user")}`)
        .then((res) => {
          this.setState({ results: res.data });
          console.log("result MESSAGE", this.state.results["0"]);
        })
        .catch((error) => {
          console.log("ERROR::: ", error.res);
        });

      // Continue with any code that should run after the simulation finishes

      // Continue with any code that should run after the simulation finishes
      message.success("Simulation finished, select your results");

      return this.state.results;
    }, 3000);
  };

  resetZoom = () => {
    if (this.chart) {
      this.chart.resetZoom();
    }
  };

  handleClickBDWM = async (item) => {
    console.log("eeee", this.state.resultsR["simulationID"], item);

    //this.state.simulation = item;
    this.state.options = item;
    console.log("locallllll", localStorage.getItem("user"));
    // const file = this.state.resultsR["outputCSV"] //'C:\\Users\\habib\\Desktop\\VPP4ISLANDS\\SPT\\SPT V3\\SPT\\frontend\\gui\\src\\components\\Sim_outputs\\sur_plus106.csv';
    //const reader = new FileReader();
    const filepath = `https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
      "user"
    )}/sim/${item}${this.state.resultsR["simulationID"]}.csv`;
    //const file = await require(`./Sim_outputs/${item}${this.state.resultsR["simulationID"]}.csv`);
    const rawdata = await fetch(filepath).then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.text();
    });
    /* .then(data => {
      // Handle the file content here
      console.log("File content:", data);}
    )  */
    console.log("i am a file", rawdata);
    const data = Papa.parse(rawdata);
    //reader.onload = (file) => {
    this.plotChart(data, item);
    //};
    // reader.readAsText(file);
    this.setState({ chartVisible: true });
  };

  handleClickB = async (item) => {
    console.log("eeee", this.state.resultsR["simulationID"], item);

    this.state.simulation = item;
    this.state.options = item;
    console.log("locallllll", localStorage.getItem("user"));
    // const file = this.state.resultsR["outputCSV"] //'C:\\Users\\habib\\Desktop\\VPP4ISLANDS\\SPT\\SPT V3\\SPT\\frontend\\gui\\src\\components\\Sim_outputs\\sur_plus106.csv';
    //const reader = new FileReader();
    const filepath = `https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
      "user"
    )}/sim/${item}${this.state.resultsR["simulationID"]}.csv`;
    //const file = await require(`./Sim_outputs/${item}${this.state.resultsR["simulationID"]}.csv`);
    const rawdata = await fetch(filepath).then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.text();
    });
    /* .then(data => {
      // Handle the file content here
      console.log("File content:", data);}
    )  */
    console.log("i am a file", rawdata);
    const data = Papa.parse(rawdata);
    //reader.onload = (file) => {
    this.plotChart(data, item);
    //};
    // reader.readAsText(file);
    this.setState({ chartVisible: true });
  };

  DownloadCSV = () => {
    const { resultsR } = this.state;

    const outputCSV = Object.values(resultsR.outputCSV).join("\n");
    console.log("csv csv", this.state.resultsR["outputCSV"]);

    // Transform the CSV data as needed (example: splitting rows and cells)
    const transformedData = outputCSV.split("\n").map((row) => row.split(","));

    // Convert the transformed data back to a CSV string
    const transformedCSV = transformedData
      .map((row) => row.join(","))
      .join("\n");

    // Create a Blob with the transformed CSV data
    const blob = new Blob([transformedCSV], { type: "text/csv" });

    // Convert the CSV data to a Blob

    // Create a temporary anchor element
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);

    // Set the download attribute with the desired filename
    a.download = "output.csv";

    // Append the anchor to the document body
    document.body.appendChild(a);

    // Trigger a click on the anchor to start the download
    a.click();

    // Remove the anchor from the document body
    document.body.removeChild(a);

    // Revoke the blob URL to free up resources
    URL.revokeObjectURL(a.href);
  };

  downloadLCSV = (plot, DWM) => {
    const link = document.createElement("a");

    if (plot == "Dynamics" || plot == "Usage") {
      if (
        DWM === "weeklyDyn_" ||
        DWM === "monthlyDyn_" ||
        DWM === "dailyDyn_" ||
        DWM === "weeklyUsage_" ||
        DWM === "monthlyUsage_"
      ) {
        //link.href = `C:/Users/habib/Desktop/VPP4ISLANDS/SPT/SPT V3/SPT/frontend/gui/src/components/Sim_outputs/${plot}${2}.csv`; // Provide the correct path to your CSV file
        link.href = `https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
          "user"
        )}/sim/${DWM}${this.state.resultsR["simulationID"]}.csv`; //${plot}${this.state.resultsR["simulationID"]}
        //link.download = `${plot}${this.state.resultsR["simulationID"]}.csv`; // Optional: Change the downloaded file name if needed
        //link.href = this.state.resultsR["outputCSV"]
        link.download = "output.csv"; // Optional: Change the downloaded file name if needed

        console.log("telecharge", link.href);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        //link.href = `C:/Users/habib/Desktop/VPP4ISLANDS/SPT/SPT V3/SPT/frontend/gui/src/components/Sim_outputs/${plot}${2}.csv`; // Provide the correct path to your CSV file
        link.href = `https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
          "user"
        )}/sim/${plot}${this.state.resultsR["simulationID"]}.csv`; //${plot}${this.state.resultsR["simulationID"]}
        //link.download = `${plot}${this.state.resultsR["simulationID"]}.csv`; // Optional: Change the downloaded file name if needed
        //link.href = this.state.resultsR["outputCSV"]
        link.download = "output.csv"; // Optional: Change the downloaded file name if needed

        console.log("telecharge", link.href);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      //link.href = `C:/Users/habib/Desktop/VPP4ISLANDS/SPT/SPT V3/SPT/frontend/gui/src/components/Sim_outputs/${plot}${2}.csv`; // Provide the correct path to your CSV file
      link.href = `https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
        "user"
      )}/sim/${plot}${this.state.resultsR["simulationID"]}.csv`; //${plot}${this.state.resultsR["simulationID"]}
      //link.download = `${plot}${this.state.resultsR["simulationID"]}.csv`; // Optional: Change the downloaded file name if needed
      //link.href = this.state.resultsR["outputCSV"]
      link.download = "output.csv"; // Optional: Change the downloaded file name if needed

      console.log("telecharge", link.href);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  downloadPNG = (plot) => {
    const link = document.createElement("a");
    if (plot == "Dynamics") {
      plot = "dynamics";
    }
    if (plot == "Cash") {
      plot = "cashflow";
    }
    if (plot == "Efficiency") {
      plot = "efficiency";
    }
    if (plot == "Usage") {
      plot = "usage";
    }

    //link.href = `C:/Users/habib/Desktop/VPP4ISLANDS/SPT/SPT V3/SPT/frontend/gui/src/components/Sim_outputs/${plot}${2}.csv`; // Provide the correct path to your CSV file
    link.href = `https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
      "user"
    )}/images/imageSIM/${plot}_${this.state.resultsR["simulationID"]}.png`; //${plot}${this.state.resultsR["simulationID"]}
    //link.download = `${plot}${this.state.resultsR["simulationID"]}.csv`; // Optional: Change the downloaded file name if needed
    //link.href = this.state.resultsR["outputCSV"]
    link.download = `${plot}_${this.state.resultsR["simulationID"]}.png`; // Optional: Change the downloaded file name if needed

    console.log("telecharge", link.href);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  plotChart = (csvData, y) => {
    console.log("Raw CSV Data:", csvData, y);

    if (y == "Usage") {
      this.state.csvArray = csvData.data;
    }

    if (y == "Dynamics") {
      console.log("hey hey");
      this.state.csvArray = csvData.data; //JSON.parse(csvData.Dynamics);
    }

    if (y == "dailyDyn_") {
      console.log("hey hey");
      this.state.csvArray = csvData.data; //JSON.parse(csvData.Dynamics);
    }

    if (y == "weeklyDyn_") {
      console.log("hey hey");
      this.state.csvArray = csvData.data; //JSON.parse(csvData.Dynamics);
    }
    if (y == "monthlyDyn_") {
      console.log("hey hey");
      this.state.csvArray = csvData.data; //JSON.parse(csvData.Dynamics);
    }

    if (y == "weeklyUsage_") {
      console.log("hey hey");
      this.state.csvArray = csvData.data; //JSON.parse(csvData.Dynamics);
    }
    if (y == "monthlyUsage_") {
      console.log("hey hey");
      this.state.csvArray = csvData.data; //JSON.parse(csvData.Dynamics);
    }

    if (y == "SoC") {
      console.log("hey hey");
      this.state.csvArray = csvData.data;
    }

    if (y == "Cash") {
      console.log("hey hey");
      this.state.csvArray = csvData.data;
    }

    if (y == "NPV") {
      this.state.csvArray = csvData.data;
      console.log("hey hey", this.state.csvArray);
    }

    if (y == "Efficiency") {
      console.log("hey hey");
      this.state.csvArray = csvData.data;
    }

    console.log("Parsed Data:", this.state.csvArray);
    const Arraycsv = this.state.csvArray;

    if (Arraycsv.length > 0) {
      const labels = Arraycsv.slice(1).map((row) => row[0]); // Extracting labels from the first column of each row

      // Extract values for each dataset
      const datasets = Arraycsv[0]
        .slice(1)
        .map((datasetLabel, datasetIndex) => {
          const color = randomColor();
          return {
            label: datasetLabel,
            data: Arraycsv.slice(1).map((row) => row[datasetIndex + 1]),
            fill: false,
            backgroundColor: color,
            borderColor: color,
            borderWidth: 1,
          };
        });

      console.log("Labels:", labels);
      console.log("Datasets:", datasets);

      //this.initializeChart();
      // Initialize the chart if it doesn't exist
      if (!this.chart) {
        console.log("Chart not created, initializing...");
        this.initializeChartB();
      }

      // Update the chart data if it exists
      if (this.chart) {
        // Clear existing datasets
        //this.chart.data.datasets = [];
        console.log("time oen...");

        // Add new datasets
        this.chart.data.datasets = datasets;

        // Update x-axis labels
        this.chart.data.labels = labels;
        if (
          y == "Cash" ||
          y == "Usage" ||
          y == "weeklyDyn_" ||
          y == "monthlyDyn_" ||
          y == "monthlyUsage_" ||
          y == "weeklyUsage_"
        ) {
          this.chart.config.type = "bar";
          if (y == "weeklyDyn_") {
            this.chart.options.scales.y.title.text = "kWh";
            this.chart.options.scales.x.title.text = "Weeks";
            this.chart.options.scales.y.stacked = true;
            this.chart.options.scales.x.stacked = true;
            this.chart.options.plugins.title.text = "Energy system behaviors";
          }
          if (y == "monthlyDyn_") {
            this.chart.options.scales.y.title.text = "kWh";
            this.chart.options.scales.x.title.text = "Months";
            this.chart.options.scales.y.stacked = true;
            this.chart.options.scales.x.stacked = true;
            this.chart.options.plugins.title.text = "Energy system behaviors";
          }

          if (y == "weeklyUsage_") {
            // Update the chart type to line

            this.chart.options.scales.y.title.text = "%";
            this.chart.options.scales.x.title.text = "Weeks";

            this.chart.options.scales.y.stacked = true;
            this.chart.options.scales.x.stacked = true;
            this.chart.options.plugins.title.text =
              "percentage of electricity usage";
          }
          if (y == "monthlyUsage_") {
            // Update the chart type to line

            this.chart.options.scales.y.title.text = "kWh";
            this.chart.options.scales.x.title.text = "Months";

            this.chart.options.scales.y.stacked = true;
            this.chart.options.scales.x.stacked = true;
            this.chart.options.plugins.title.text =
              "percentage of electricity usage";
          }
          if (y == "Cash") {
            this.chart.options.scales.y.title.text = "€";
            this.chart.options.scales.x.title.text = "Years";

            this.chart.options.scales.y.stacked = true;
            this.chart.options.plugins.title.text =
              "Cash flow during project life time";
          }
          if (y == "Usage") {
            // Update the chart type to line

            this.chart.options.scales.y.title.text = "%";
            this.chart.options.scales.x.title.text = "Hours";

            this.chart.options.scales.y.stacked = true;
            this.chart.options.scales.x.stacked = true;
            this.chart.options.plugins.title.text =
              "percentage of electricity usage";
          }
          //this.chart.config.options.plugins.title.display = true;
        } else {
          this.chart.config.type = "line";
          if (y == "Dynamics") {
            this.chart.options.scales.y.title.text = "kWh";
            this.chart.options.scales.x.title.text = "Hour";
            this.chart.options.scales.y.stacked = false;
            this.chart.options.plugins.title.text = "Energy system behaviors";
          }
          if (y == "dailyDyn_") {
            this.chart.options.scales.y.title.text = "kWh";
            this.chart.options.scales.x.title.text = "Days";
            this.chart.options.scales.y.stacked = false;
            this.chart.options.plugins.title.text = "Energy system behaviors";
          }

          if (y == "SoC") {
            this.chart.options.scales.y.title.text = "SoC%";
            this.chart.options.scales.x.title.text = "Hour";
            this.chart.options.scales.y.stacked = false;
            this.chart.options.plugins.title.text = "State of Charges";
          }
          if (y == "Efficiency") {
            this.chart.options.scales.y.title.text = "%";
            this.chart.options.scales.x.title.text = "Weeks";
            this.chart.options.scales.y.stacked = false;
            this.chart.options.plugins.title.text =
              "Efficiency of energy system";
          }
          if (y == "NPV") {
            this.chart.options.scales.y.title.text = "€";
            this.chart.options.scales.x.title.text = "Years";
            this.chart.options.scales.y.stacked = false;
            this.chart.options.plugins.title.text =
              "Net Present Value during project life time";
          }
        }

        // Update the chart
        this.chart.update();
      }
    } else {
      console.error("No data found in the CSV file.");
    }
  };

  initializeChartB() {
    ///const canvas = this.canvasRef.current;
    if (this.isComponentMounted && this.chartRef.current) {
      const ctx = this.chartRef.current.getContext("2d");
      this.chart = new Chart(ctx, {
        type: "bar", // Set your desired chart type
        title: "Hello",
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          plugins: {
            title: {
              display: true,
              position: "top",
              text: "Simulation charts",
              //(ctx) => 'Zoom: ' + zoomStatus() + ' , Pan: ' + panStatus()
            },

            // zoom: zoomOptions, Before

            zoom: {
              pan: {
                enabled: true,
                mode: "x", // allows panning along the x-axis
              },
              zoom: {
                wheel: {
                  enabled: true, // enables zooming with the mouse wheel
                },
                pinch: {
                  enabled: true, // enables zooming on mobile devices
                },
                mode: "x", // allows zooming along the x-axis
              },
            },
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          responsive: true,
          scales: {
            x: {
              //suggestedMax: 8760,
              beginAtZero: true,
              title: {
                display: true,
                text: "Time", // Y-axis label
              },
            },
            y: {
              beginAtZero: true,
              stacked: this.state.staked,
              //  suggestedMax: 200000,

              title: {
                display: true,
                text: "kWh", // Y-axis label
              },
            },
          },

          /*  onClick(e) {
      console.log(e.type);
    } */
        },
        /*       ,
      scales: {
        x: {
          type: 'linear', // Set the x-axis scale type to linear for zoom plugin compatibility
        },
        y: {
          beginAtZero: true
        }
      },}} */
        /*    plugins: {
        zoom: {
          pan: {
            enabled: true,
            mode: 'xy', // Enable pan in both x and y directions
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'xy', // Enable zoom in both x and y directions
          },
        },
      },
    }, */
      });

      console.log("i am created");
    } else {
      console.log("i am not created");
    }
  }

  /* handleResetZoom = () => {
  if (this.state.chart) {
    this.state.chart.resetZoom();
  }
};
 */

  handleResetZoom = () => {
    if (this.state.chart) {
      const chart = this.state.chart;
      const scales = chart.options.scales;

      // Reset x-axis zoom
      scales.x.min = undefined;
      scales.x.max = undefined;

      // Reset y-axis zoom
      scales.y.min = undefined;
      scales.y.max = undefined;
      console.log("i am rseting");
      // Update the chart
      chart.update();
    }
  };

  handleChange = async (e) => {
    // this.socket.send(JSON.stringify({ type: 'start_simulation' }));
    console.log("start");
    // this.state.verrou=true
    //await this.handleSubmit(e, this.props.requestType);
    if (this.state.verrou == true) {
      console.log("Simulation Nbr", this.state.simID);
      const configa = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'Authorization': AUTH_TOKEN,
        },

        params: {
          configurationID: this.state.itemm,
          simulationID: this.state.simID,
          username: localStorage.getItem("user"),
          //"simulationID": simulationID
        },
      };

      message.error("Please wait for the simulation finishing");

      this.setState({ showSpin: true });
      const promise = await axiosApiInstance.get("sim/v1/simulation/", configa);

      /*   console.log("testttiinnggngg", this.state.verrou)
 
  setTimeout(async() =>{

 

 /*  try {
    const response = await axios.get('https://spt-vpp4islands.herokuapp.com/opti/v1/simulation/', configa);
    const data = response.data;
    // Process data here
  } catch (error) {
    console.error('Error fetching data:', error);
  }
 */
      //console.log(configuration);
      ///message.error('Please wait for the simulation finishing');
      //setTimeout(() => { message.success('The simulation is finished succssefully');}, 12000)

      // Set state to show Spin when button is clicked

      //this.socket = new WebSocket('ws://127.0.0.1:8000/mywebsocketapp/ws/');

      // Set a timeout to hide the Spin component after 15 seconds
      /* this.timeout = setTimeout(() => {

  
   this.setState({ showSpin: false });
 }, 30000); */

      this.setState({ showSpin: true });
      await this.getResults();
      message.success("Simulation is finished");

      /*   await axiosApiInstance.get(`api/v1/res/?user_id=${localStorage.getItem('user')}`)
 .then (res =>{
  this.setState({ results: res.data}); console.log("result", this.state.results["0"]);  
  })
  .catch(error => {
    console.log("ERROR::: ",error.res);}) */

      return this.state.results;
    }

    this.state.verrou = false;
  };

  handleClick = (e, items) => {
    //this.props.configurationID= items.configurationID
    console.log("config");
    this.state.itemm = items.configurationID;
    console.log("requesttype", this.props.requestType);

    this.setState({ selectedConfiguration: items.name });
    this.formRef.current.setFieldsValue({ idconfig: items.configurationID }); // Set the form field value

    this.state.verrou = true;
    //this.handleSubmit(e, this.props.requestType);

    //you can perform setState here
  };

  production = async () => {
    console.log("hooo");
    const { country, town, capacity, orientation, inclination } = this.state;
    const configa = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // 'Authorization': AUTH_TOKEN,
      },
      params: {
        country: "France",
        town: "Paris",
        capacity: 10.1,
        orientation: 0,
        inclination: 30,
      },
    };
    await axiosApiInstance
      .get("sim/v1/pv-production/", configa)
      .then((resid) => {
        console.log("resporod", resid.data);
      });
  };

  async handleClickR(e, itemss) {
    //this.props.configurationID= items.configurationID
    this.state.itemr = itemss.resultID;
    console.log("Result", this.state.itemr);
    this.setState({ selectedResult: itemss.name });

    const resultID = itemss.resultID;
    this.setState({ verrouPDF: true });
    await axiosApiInstance
      .get(`api/v1/res/${resultID}`)
      .then((resid) => {
        this.setState({ resultsR: resid.data });
        console.log("resid", resid.data["simulationID"]);
        this.setState({ IRRR: resid.data["outcomes"]["IRR"] });
        this.setState({ DECARBR: resid.data["outcomes"]["DECRAB"] });
        this.setState({ SAVR: resid.data["outcomes"]["Savings"] });
        this.setState({ RETR: resid.data["outcomes"]["Return"] });
        this.setState({ LCOER: resid.data["outcomes"]["LCOE"] });
        this.setState({ PAYR: resid.data["outcomes"]["Payback"] });
        this.setState({ BUILDR: resid.data["outcomes"]["BuildingLCOE"] });
        this.setState({ SELF: resid.data["outcomes"]["resultsGlobal"] });
        this.setState({ NAMES: resid.data["outcomes"]["namesLoad"] });
        this.setState({ ABATR: resid.data["outcomes"]["AbatCost"] });
        this.setState({ Foot: resid.data["outcomes"]["Foot"] });
        this.setState({ simIDR: resid.data["simulationID"] });
        this.setState({ plotRT: [] });

        this.plotshandle(resid.data["simulationID"]);
        //sessionStorage.setItem('data', JSON.stringify(resid)) ;
        console.log("storga", resid.data["simulationID"]);
      })

      .catch((error) => {
        console.log("ERROR::: ", error.resid);
      });

    // this.setState({simulationRID: this.state.results[items.resultID]["simulationID"]})
    // console.log(this.state.displayr)
    // console.log("simulatRID", this.state.simulationRID)
    // console.log("verrou", this.state.simulationRID)
    this.setState({ verrouS: true });

    //simulationID=
    //     axios.get(`https://www.sptapp.eu/api/dss/`)
    //     .then (ress =>{
    //      this.setState({ simulations: ress.data});  console.log("storga2", ress.data) ;

    //      })
    //     .catch(error => {
    //        console.log("ERROR::: ",error.ress);})
    // // 			//you can perform setState here
  }

  async plotshandle(y) {
    await axiosApiInstance
      .get(`api/v1/dss/${y}`)
      .then((ressid) => {
        this.setState({ plotR: ressid.data["plots"] });
        console.log("plotR", this.state.plotR);
        this.plotRT(ressid.data["plots"]);
      })

      .catch((error) => {
        console.log("ERROR::: ", error.resid);
      });

    //await this.plotRT()
  }
  //     ***********    Check boxes ***********

  async plotRT(X) {
    if (X[0] == true) {
      this.setState((prevState) => ({
        plotRT: [...prevState.plotRT, "Dynamics"],
      }));
    }
    if (X[1] == true) {
      this.setState((prevState) => ({
        plotRT: [...prevState.plotRT, "SoC"],
      }));
    }
    if (X[2] == true) {
      this.setState((prevState) => ({
        plotRT: [...prevState.plotRT, "Cash"],
      }));
    }
    if (X[3] == true) {
      this.setState((prevState) => ({
        plotRT: [...prevState.plotRT, "NPV"],
      }));
    }
    if (X[4] == true) {
      this.setState((prevState) => ({
        plotRT: [...prevState.plotRT, "Usage"],
      }));
    }
    if (X[5] == true) {
      this.setState((prevState) => ({
        plotRT: [...prevState.plotRT, "Efficiency"],
      }));
    }
  }

  handleCheckboxChange = (checkboxName) => {
    if (
      checkboxName !== "isCheckedSC" &&
      checkboxName !== "isCheckedT" &&
      checkboxName !== "isCheckedA"
    ) {
      this.setState(
        (prevState) => ({
          [checkboxName]: !prevState[checkboxName],
        }),
        () => this.updateSelectedCheckboxes(checkboxName)
      );
    }
  };

  updateSelectedCheckboxes = (check) => {
    const selectedCheckboxes = Object.keys(this.state)
      .filter(
        (checkbox) =>
          this.state[checkbox] === true &&
          (checkbox === "Dynamics" ||
            checkbox === "SoC" ||
            checkbox === "Usage" ||
            checkbox === "Efficiency" ||
            checkbox === "Cashflow" ||
            checkbox === "NPV")
      )
      .map((value) => ({ value }));
    if (
      check !== "isCheckedSC" &&
      check !== "isCheckedT" &&
      check !== "isCheckedA"
    ) {
      this.setState({ selectedCheckboxes });
    }
  };

  handleModalOpen = () => {
    console.log("Modal open clicked");

    this.setState({ visible: true });
  };

  handleModalClose = () => {
    console.log("Modal ok clicked");

    this.setState({ visible: false });
  };
  handleModalCancel = () => {
    console.log("Modal Cancel clicked");

    this.setState({ visible: false });
  };

  handleCheckboxEChange = (e) => {
    if (e && e.target) {
      this.setState({ Dynamics: e.target.checked });
    }
  };

  handleCheckboxSChange = (e) => {
    if (e && e.target) {
      this.setState({ Usage: e.target.checked });
    }
  };

  handleCheckboxBChange = (e) => {
    if (e && e.target) {
      this.setState({ Efficiency: e.target.checked });
    }
  };

  handleCheckboxCChange = (e) => {
    if (e && e.target) {
      this.setState({ Cashflow: e.target.checked });
    }
  };

  handleCheckboxTChange = (e) => {
    if (e && e.target) {
      this.setState({ NPV: e.target.checked });
    }
  };

  handleCheckboxAChange = (e) => {
    if (e && e.target) {
      this.setState({ SoC: e.target.checked });
    }
  };

  handleCheckboxSCChange = (e) => {
    if (e && e.target) {
      this.setState({ isCheckedSC: e.target.checked });
    }
  };

  handleCheckboxTRChange = (e) => {
    if (e && e.target) {
      this.setState({ isCheckedT: e.target.checked });
    }
  };

  handleCheckboxAUChange = (e) => {
    if (e && e.target) {
      this.setState({ isCheckedA: e.target.checked });
    }
  };

  handleSubmit = async (values, requestType) => {
    //configurationID
    // const country = useRef()
    // const town = useRef()
    //e.preventDefault();
    if (this.state.verrou == true) {
      // <Progress strokeLinecap="butt" percent={75} />
      //const date_creation =e.target.elements.date.value;
      //const kpi =e.target.elements.objective.value;
      const plots1 = this.state.Dynamics; // boucle for
      const plots2 = this.state.SoC;
      const plots3 = this.state.Cashflow;
      const plots4 = this.state.NPV; // boucle for
      const plots5 = this.state.Usage;
      const plots6 = this.state.Efficiency;
      const plots = [plots1, plots2, plots3, plots4, plots5, plots6];
      const name = values.name;

      console.log("nameeeee", name);

      const usages1 = this.state.isCheckedA; // boucle for
      const usages2 = this.state.isCheckedSC;
      const usages3 = this.state.isCheckedT;

      const usages = [usages1, usages2, usages3];

      console.log("usages", usages);
      const configurationID = this.state.itemm;
      const Kpi = "findSavings";
      //const configurationID =String(itemm);
      console.log("config", this.state.itemm);
      //const csvoutput =  this.props.results.data; //Papa.parse(e.target.files[0])///e.target.elements.csvFile.result);

      //const title= document.getElementById('country').value;
      //
      //const content= document.getElementById('town').value;
      //

      //console.log(plots)
      console.log(configurationID);

      switch (requestType) {
        // JSON.stringify(datar)}

        case "get":
          //const datar = {"configurationID":"1"}

          await axiosApiInstance
            .post("api/v1/createS/", {
              //kpi: kpi,

              configurationID: configurationID,
              name: name,
              username: localStorage.getItem("user"),
              kpi: Kpi,
              plots: plots,
              usages: usages,
            })

            .then((res) => {
              this.setState({ simID: res.data["simulationID"] });
              // this.state.verrou=true

              console.log(res);

              // console.log(res.data["simulationID"]);
              // console.log(simID);
            })

            .catch((error) => console.error(error));

          message.success("Ready for simulation");
          setTimeout(() => {
            //this.state.verrou = true;
            this.handleChange();
          }, 3000);

        /* Start simulation */

        /*end code simulation*/
      }
    } else {
      message.error("Select a configuration ID, it is missing");
    }
  };

  render() {
    const {
      configurations,
      selectedResult,
      selectedConfiguration,
      isPopupVisible,
    } = this.state;
    const { t } = this.props;

    const simuID = this.state.simID;
    console.log("simID");
    console.log(simuID);
    const { radioStates } = this.state;
    //const { loading } = this.state;
    const BUILDRR = this.state.BUILDR;
    console.log("plotrrr", this.state.plotR);
    console.log("plotrrrttt", this.state.plotRT);

    const { showSpin } = this.state;
    console.log("plotsss", this.state.plotR);
    const data = {
      labels: ["Category 1", "Category 2", "Category 3"],
      datasets: [
        {
          label: "Data",
          data: [10, 20, 15],
          backgroundColor: [
            "rgba(255, 99, 132, 0.7)",
            "rgba(54, 162, 235, 0.7)",
            "rgba(255, 206, 86, 0.7)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };

    // const { BUILDR } = this.state;
    // Split the string into two numbers
    // const [firstNumber, secondNumber] = BUILDR.match(/.{1,17}/g) || [];

    const columns = [
      {
        title: "System name",
        dataIndex: "Name",
        key: "Name",
        render: (text) => <a>{text}</a>,
      },
      {
        title: "Energy-delivered kWh",
        dataIndex: "Energy-Delivered-kWh",
        key: "Energy-Delivered-kWh",
      },
      {
        title: "Capacity-factor",
        dataIndex: "Capacity-Factor",
        key: "Capacity-Factor",
      },
      {
        title: "Capital-cost €",
        dataIndex: "Capital-Cost",
        key: "Capital-Cost",
      },

      {
        title: "Operational-cost €",
        dataIndex: "Operational-Cost",
        key: "Operational-Cost",
      },

      {
        title: "Levelised-cost €kWh",
        dataIndex: "Levelised-Cost",
        key: "Levelised-Cost",
      },
    ];

    const columnl = [
      {
        title: "Load name",
        dataIndex: "System-Level",
        key: "System-Level",
        render: (text) => <a>{text}</a>,
      },
      {
        title: " Grid-kWh",
        dataIndex: "Grid-Delivered-kWh",
        key: "Grid-Delivered-kWh",
      },
      {
        title: " System-kWh",
        dataIndex: "System-Delivered-kWh",
        key: "System-Delivered-kWh",
      },
      {
        title: "Self-Consumption %",
        dataIndex: "Self-Consumption-%",
        key: "Self-Consumption-%",
      },
      {
        title: "LCOE-€kWh",
        dataIndex: "Levelised-Cost-€kWh",
        key: "Levelised-Cost-€kWh",
      },
      {
        title: "Decarb %",
        dataIndex: "Decarbonisation-%",
        key: "Decarbonisation-%",
      },

      {
        title: "Emissions-co2ekWh",
        dataIndex: "Emissions-co2ekWh",
        key: "Emissions-co2ekWh",
      },
    ];

    const dataC = this.state.resultsR["components"];
    const dataL = this.state.resultsR["loadsS"];
    if (Array.isArray(dataL) && dataL.length > 0) {
      console.log("hhh");
      this.state.SystemDelivered = dataL.reduce((total, item) => {
        return total + (item["System-Delivered-kWh"] || 0); // Fallback to 0 if undefined
      }, 0);
    }
    console.log("dataML", dataL, this.state.SystemDelivered);

    return (
      //simulationID=this.state.simID
      <>
        <Form
          onFinish={(values) =>
            this.handleSubmit(values, this.props.requestType)
          } // handle form submission with values
          //onFinish={this.handleSubmit} // Use this to handle form submission
          ref={this.formRef}
          name="configurationForm"
          initialValues={{ name: "", idconfig: null }} // Initial state for the form
          //initialValues={{ idconfig: null }}
        >
          {/*
           */}
          <div className="container">
            {/* Part 1 */}
            <div
              style={{
                display: "flex",
                width: "170vh",
                marginBottom: 10,
                backgroundColor: "#f0f0f0",
                padding: 5,
                border: "solid 1px",
                borderColor: "#cccccc",
              }}
            >
              {/* Part 1.1 */}
              <Card
                style={{
                  backgroundColor: "#ffff",
                  width: "100vh",
                  margin: "0 10px",
                }}
              >
                <div>
                  <Divider orientation="center">
                    {" "}
                    <b>Choose your Usage </b>
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                      }}
                    >
                      <InfoCircleTwoTone
                        onClick={this.handleIconClick}
                        style={{ fontSize: "24px", cursor: "pointer" }}
                      />
                      {isPopupVisible && (
                        <div
                          ref={this.popupRef}
                          style={{
                            position: "absolute",
                            top: "30px", // Adjust as needed
                            left: "0",
                            border: "1px solid #ccc",
                            backgroundColor: "white",
                            padding: "10px",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            zIndex: 1000, // Ensures the popup is above other elements
                          }}
                        >
                          Two usages are proposed to simulate your existing
                          systems (configuration).
                          <br></br>First the self-consumption of the EC or the
                          trading
                          <br></br> of the energy excess that will impact
                          financial metrics.
                          <br></br> You can modify the prices via the settings
                          form.
                        </div>
                      )}{" "}
                    </div>
                  </Divider>{" "}
                </div>
                {/* <button onClick={this.production}>Prod</button>
                 */}
                <Row
                  gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                  }}
                >
                  <Col className="gutter-row" span={8}>
                    {" "}
                    <div>
                      <Checkbox
                        value="Self"
                        checked={this.state.isCheckedSC}
                        onChange={this.handleCheckboxSCChange}
                      >
                        {" "}
                        Self-consumption{" "}
                      </Checkbox>
                    </div>{" "}
                  </Col>

                  <Col className="gutter-row" span={8}>
                    {" "}
                    <div>
                      <Checkbox
                        value="Trading"
                        checked={this.state.isCheckedT}
                        onChange={this.handleCheckboxTRChange}
                      >
                        {" "}
                        Trading{" "}
                      </Checkbox>
                    </div>{" "}
                  </Col>
                </Row>
                <br></br>
                <div style={{ maxWidth: "400px" }}>
                  <Divider orientation="center"></Divider>{" "}
                  {/*  <Button type="primary" onClick={this.handleModalOpen}>
                      Choose your plots
                    </Button> */}
                  <div
                    style={{
                      display: "inline-block",
                      border: "1px solid #cccccc", // Border color and thickness
                      borderRadius: "8px", // Rounded corners
                      padding: "5px", // Padding around the image
                      cursor: "pointer",
                      marginBottom: "20px",
                    }}
                    onClick={this.handleModalOpen}
                  >
                    <b>Select plots</b>
                    <img
                      src={plotButtonImage}
                      alt="Choose Plots"
                      //onClick={this.handleModalOpen}
                      style={{
                        cursor: "pointer",
                        width: "170px", // Set your desired size
                        height: "120px", // Set your desired size
                        marginBottom: "10px",
                      }}
                    />
                  </div>
                </div>
                <Modal
                  title="Select Options"
                  visible={this.state.visible}
                  onOk={this.handleModalClose}
                  onCancel={this.handleModalCancel}
                >
                  <Row gutter={[16, 8]}>
                    <Col className="gutter-row" span={8}>
                      {" "}
                      <div>
                        <Checkbox
                          value="EnergyDynamics"
                          checked={this.state.Dynamics}
                          onChange={(e) => [
                            this.handleCheckboxChange("Dynamics"),
                            this.handleCheckboxEChange(),
                          ]}
                        >
                          {" "}
                          Dynamics{" "}
                        </Checkbox>
                      </div>{" "}
                    </Col>
                    <Col className="gutter-row" span={8}>
                      {" "}
                      <div>
                        <Checkbox
                          value="StateOfCharge"
                          checked={this.state.SoC}
                          onChange={(e) => [
                            this.handleCheckboxChange("SoC"),
                            this.handleCheckboxAChange(),
                          ]}
                        >
                          {" "}
                          SoC{" "}
                        </Checkbox>
                      </div>{" "}
                    </Col>
                  </Row>
                  <div style={{ maxWidth: "400px" }}>
                    <Divider orientation="left"> </Divider>{" "}
                  </div>
                  <Row gutter={[16, 8]}>
                    {" "}
                    <Col className="gutter-row" span={8}>
                      {" "}
                      <div>
                        <Checkbox
                          value="Cashflow"
                          checked={this.state.Cashflow}
                          onChange={(e) => [
                            this.handleCheckboxChange("Cashflow"),
                            this.handleCheckboxCChange(),
                          ]}
                        >
                          {" "}
                          Cashflow{" "}
                        </Checkbox>
                      </div>{" "}
                    </Col>
                    <Col className="gutter-row" span={8}>
                      {" "}
                      <div>
                        <Checkbox
                          value="NPV"
                          checked={this.state.NPV}
                          onChange={(e) => [
                            this.handleCheckboxChange("NPV"),
                            this.handleCheckboxTChange(),
                          ]}
                        >
                          {" "}
                          NPV{" "}
                        </Checkbox>{" "}
                      </div>
                    </Col>
                  </Row>
                  <div style={{ maxWidth: "400px" }}>
                    <Divider orientation="left"> </Divider>{" "}
                  </div>{" "}
                  <Row gutter={[16, 8]}>
                    <Col className="gutter-row" span={8}>
                      {" "}
                      <div>
                        <Checkbox
                          value="Usage"
                          checked={this.state.Usage}
                          onChange={(e) => [
                            this.handleCheckboxChange("Usage"),
                            this.handleCheckboxSChange(),
                          ]}
                        >
                          {" "}
                          Usage{" "}
                        </Checkbox>
                      </div>{" "}
                    </Col>
                    <Col className="gutter-row" span={8}>
                      {" "}
                      <div>
                        <Checkbox
                          value="Efficiency"
                          checked={this.state.Efficiency}
                          onChange={(e) => [
                            this.handleCheckboxChange("Efficiency"),
                            this.handleCheckboxBChange(),
                          ]}
                        >
                          {" "}
                          Efficiency{" "}
                        </Checkbox>
                      </div>{" "}
                    </Col>{" "}
                  </Row>
                </Modal>
              </Card>
              {/* Part 1.2 */}
              <Card
                style={{
                  backgroundColor: "#fff",
                  width: "100vh",
                  margin: "0 10px",
                }}
              >
                <Divider orientation="center">
                  <b> Rename and select a configuration </b>
                </Divider>
                <Form.Item
                  label={t("Name")}
                  name="name"
                  rules={[{ required: true, message: "Name is required" }]}
                >
                  <Input
                    //value="is required"
                    style={{
                      maxWidth: "206px",
                      gap: "18px",
                      maxHeight: "200px",
                    }}
                    //defaultValue={`sim_${selectedConfiguration}`}
                    placeholder="Enter_Name"
                    type="text"
                    name="name"
                  />
                </Form.Item>

                <Form.Item
                  label={t("ID Config")}
                  name="idconfig"
                  rules={[
                    { required: true, message: "Configuration ID is required" },
                  ]}
                >
                  {" "}
                  <Dropdown
                    overlay={
                      <Menu>
                        <div>
                          {" "}
                          {this.state.configurations.map((items) => (
                            <Menu.Item
                              onClick={(e) => this.handleClick(e, items)}
                              style={{ minWidth: 0, flex: "auto" }}
                              key={items.configurationID}
                            >
                              {" "}
                              Config {String(items.name)}
                            </Menu.Item>
                          ))}{" "}
                        </div>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <Button
                      className="ant-dropdown-link"
                      icon={<VerticalAlignBottomOutlined />}
                      onClick={(e) => e.preventDefault()}
                    >
                      {selectedConfiguration
                        ? `Config ${selectedConfiguration}`
                        : "Configuration ID"}
                    </Button>
                  </Dropdown>
                </Form.Item>
                <br />
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#082444",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  Run simulation
                </button>
                {showSpin && <Spin size="large" />}
              </Card>
            </div>
            {/* Part 2 */}
            <div
              style={{
                display: "flex",
                width: "170vh",
                marginBottom: 10,
                backgroundColor: "#f0f0f0",
                padding: 5,
                border: "solid 1px",
                borderColor: "#cccccc",
              }}
            >
              <div
                style={{
                  flex: 1,
                  width: "600",
                  padding: "20px",
                  boxSizing: "border-box",
                }}
              >
                <h4> Result of simulation </h4>
                <Dropdown
                  overlay={
                    <Menu>
                      <div>
                        {" "}
                        {this.state.results.map((itemss) => (
                          <Menu.Item
                            onClick={(e) => this.handleClickR(e, itemss)}
                            style={{ minWidth: 0, flex: "auto" }}
                            key={itemss.resultID}
                          >
                            {" "}
                            Result {String(itemss.name)}
                          </Menu.Item>
                        ))}{" "}
                      </div>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <Button
                    className="ant-dropdown-link"
                    icon={<VerticalAlignBottomOutlined />}
                    onClick={(e) => e.preventDefault()}
                  >
                    {selectedResult ? `Result ${selectedResult}` : "Result ID"}
                  </Button>
                </Dropdown>
                <br />
                <br />
                <div
                  style={{ display: "flex", gap: "24px", marginBottom: "16px" }}
                >
                  <Card
                    title={
                      <>
                        <EuroTwoTone twoToneColor="#eb2f96" /> IRR %
                      </>
                    }
                    bordered={true}
                    style={{ height: "80px", flex: 1 }}
                    className="centered-card"
                  >
                    {typeof this.state.IRRR === "number"
                      ? this.state.IRRR.toFixed(2) + " %"
                      : "N/A"}
                  </Card>
                  <Card
                    title={
                      <>
                        {" "}
                        <FundTwoTone /> Decarb
                      </>
                    }
                    bordered={false}
                    style={{ height: "80px", flex: 1 }}
                    className="centered-card"
                  >
                    {typeof this.state.DECARBR === "number"
                      ? this.state.DECARBR.toFixed(2) + " gCO2kWh"
                      : "N/A"}
                  </Card>
                  <Card
                    title={
                      <>
                        {" "}
                        <InteractionTwoTone /> Return %
                      </>
                    }
                    bordered={false}
                    style={{ height: "80px", flex: 1 }}
                    className="centered-card"
                  >
                    {typeof this.state.RETR === "number"
                      ? this.state.RETR.toFixed(2) + " %"
                      : "N/A"}
                  </Card>
                  <Card
                    title={
                      <>
                        {" "}
                        <CloudTwoTone /> FootPrint
                      </>
                    }
                    bordered={false}
                    style={{ height: "80px", flex: 1 }}
                    className="centered-card"
                  >
                    {typeof this.state.Foot === "number"
                      ? (
                          this.state.Foot *
                          this.state.SystemDelivered *
                          0.001
                        ).toFixed(2) + " kgCO2"
                      : "N/A"}
                  </Card>
                </div>
                <div
                  style={{ display: "flex", gap: "16px", marginBottom: "16px" }}
                >
                  <Card
                    title={
                      <>
                        {" "}
                        <HomeTwoTone /> Savings €
                      </>
                    }
                    bordered={false}
                    style={{ height: "80px", flex: 1 }}
                    className="centered-card"
                  >
                    {typeof this.state.SAVR === "number"
                      ? -1 * this.state.SAVR.toFixed(0) + " €"
                      : "N/A"}
                  </Card>
                  <Card
                    title={
                      <>
                        {" "}
                        <ThunderboltTwoTone /> LCOE{" "}
                      </>
                    }
                    bordered={true}
                    style={{ height: "80px", flex: 1 }}
                    className="centered-card"
                  >
                    {typeof this.state.LCOER === "number"
                      ? this.state.LCOER.toFixed(2) + " €/kWh"
                      : "N/A"}
                  </Card>
                  <Card
                    title={
                      <>
                        {" "}
                        <CalendarTwoTone /> Payback{" "}
                      </>
                    }
                    bordered={false}
                    style={{ height: "80px", flex: 1 }}
                    className="centered-card"
                  >
                    {typeof this.state.PAYR === true //"number"
                      ? this.state.PAYR + " years" //this.state.PAYR.toFixed(2) + " years"
                      : this.state.PAYR}
                  </Card>
                  <Card
                    title={
                      <>
                        {" "}
                        <DiffTwoTone /> AbatCost
                      </>
                    }
                    bordered={false}
                    style={{ height: "80px", flex: 1 }}
                    className="centered-card"
                  >
                    {typeof this.state.ABATR === "number"
                      ? this.state.ABATR.toFixed(2) + " €/kCO2"
                      : "N/A"}
                  </Card>
                </div>
                <br></br>

                <Table columns={columns} dataSource={dataC} />
                <br></br>
                <Table columns={columnl} dataSource={dataL} />
              </div>
            </div>
            {/*
        </div> */}

            <div
              style={{
                width: "170vh",
                marginBottom: 10,
                backgroundColor: "#f0f0f0",
                padding: 5,
                border: "solid 1px",
                borderColor: "#cccccc",
              }}
            >
              <Dropdown
                overlay={
                  <Menu>
                    <div>
                      {this.state.plotRT.map((itemb) => (
                        <Menu.Item
                          onClick={() => this.handleClickB(itemb)}
                          style={{ minWidth: 0, flex: "auto" }}
                        >
                          {String(itemb)}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button
                  value={this.state.simulation}
                  className="ant-dropdown-link"
                  icon={<VerticalAlignBottomOutlined />}
                  onClick={(e) => e.preventDefault()}
                >
                  {this.state.simulation ? `${this.state.simulation}` : "Plots"}
                </Button>
              </Dropdown>
              <br />
              <br />
              {this.state.simulation == "Dynamics" && (
                <Select
                  value={this.state.selectedOption}
                  style={{ width: 200 }}
                  onChange={this.handleChangeDWM}
                >
                  <Option value="Dynamics">Hourly</Option>
                  <Option value="dailyDyn_">Daily</Option>
                  <Option value="weeklyDyn_">Weekly</Option>
                  <Option value="monthlyDyn_">Monthly</Option>
                </Select>
              )}
              {this.state.simulation == "Usage" && (
                <Select
                  value={this.state.selectedOption2}
                  style={{ width: 200 }}
                  onChange={this.handleChangeDWMU}
                >
                  <Option value="Usage">Daily</Option>
                  <Option value="weeklyUsage_">Weekly</Option>
                  <Option value="monthlyUsage_">Monthly</Option>
                </Select>
              )}
              <Card>
                {" "}
                <div className="App">
                  <canvas
                    ref={this.chartRef}
                    style={{ width: "100%", height: "400px" }}
                  />
                </div>
              </Card>
              <br />
              <Button
                onClick={(e) =>
                  this.state.options === "weeklyDyn_" ||
                  this.state.options === "monthlyDyn_" ||
                  this.state.options === "dailyDyn_" ||
                  this.state.options === "weeklyUsage_" ||
                  this.state.options === "monthlyUsage_"
                    ? this.handleClickBDWM(this.state.options)
                    : this.handleClickB(this.state.simulation)
                }
              >
                {" "}
                Change Color{" "}
              </Button>
              {/*    onWheel={(event) => event.preventDefault()} <Button onClick={()=>this.handleResetZoom}>Reset Zoom</Button>
                   <Line data={data2} options={options}   style={{ width: "100%", height: "400px" }}
            onWheel={(event) => event.preventDefault()}/>  */}
              <Button onClick={this.resetZoom}>Reset Zoom</Button>

              <Button
                className="ant-dropdown-link"
                icon={<VerticalAlignBottomOutlined />}
                onClick={(e) =>
                  this.downloadLCSV(this.state.simulation, this.state.options)
                }
              >
                Download CSV
              </Button>
              <Button
                className="ant-dropdown-link"
                icon={<VerticalAlignBottomOutlined />}
                onClick={(e) => this.downloadPNG(this.state.simulation)}
              >
                Download PNG
              </Button>
              <br></br>
            </div>
          </div>
        </Form>
      </>
    );
  }
}

export default withTranslation(SimulationForm);

import React, { Component } from "react";
import "./App.css";
import "antd/dist/antd.less";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
//import { Navigate } from 'react-router-dom';
import ActionDetail from "./containers/ActionDetailView";
import CustomLayout from "./containers/Layout";
import BaseRouter from "./routes";
import Config from "./pages/config";
import Actions from "./pages/actions";
import Kpis from "./pages/kpis";
import Optimisations from "./pages/optimisations";
import Planning from "./pages/planning";
import Practices from "./pages/practices";
import Planification from "./components/steps";
import Register from "./components/register";
import Login from "./components/login";
import NotFound from "./pages/NotFound.js";
import Home from "./pages/Home.js";
import Contact from "./pages/contact.js";
import Settings from "./pages/settings.js";
//import { Switch } from 'antd';
import ActionList from "./containers/ActionListView";
import PracticeList from "./containers/PracticeListView";
import { Switch } from "antd";
// import config from './pages/config';
// import kpis from './pages/kpis';

//import PrivateRoute from "./utils/PrivateRoute.js";
import { UserContext } from "./utils/Usercontext.js";
import { Cleaning } from "./components/Cleaning.js";

export const getToken = () => {
  const token = localStorage.getItem("token");
  console.log("status token APP", token);

  if (token) {
    return true;
  } else {
    return false;
  }
};

class App extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      isAuth: getToken(),
    };

    //this.socket = new WebSocket('ws://127.0.0.1:8000/app/ws/');
  }

  setisAuth(param) {
    this.setState({ isAuth: param });
  }
  // function App(){
  render() {
    console.log("authhhh", this.state.isAuth);
    return (
      <div className="App">
        <UserContext.Provider
          value={{
            isAuth: this.state.isAuth,
            setisAuth: (param) => this.setisAuth(param),
          }}
        >
          <CustomLayout>
            <Routes>
              {/* {this.state.isAuth ? (
        <>
       <PrivateRoute exact path="/" authed={isAuth} component={Admin} />
          <Route path="/users" component={Admin} />
          <Route path="/index" exact component={Index} />
          <Route path="/profile" exact component={Profile} />  
           <Route exact path="/config" element={<Planification/>} />
           <Route exact path='/actions' element={<Actions/>} />
           <Route exact path='/practices' element={<Practices/>} />
           <Route path="/planning" element={<Planning/>} /> 
     </>
      ) : (
        // Redirect to "/landing" if not authenticated
       // <Redirect to="/landing" exact component={Landing} />
        <Redirect to="/login" exact element={<Login/>} />
      )}
       */}
              {/*  {this.state.isAuth ? (
    <> */}

              <Route
                exact
                path="/scenarios"
                element={
                  this.state.isAuth ? (
                    <Planification />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                exact
                path="/config"
                element={
                  this.state.isAuth ? <Config /> : <Navigate to="/login" />
                }
              />
              <Route
                exact
                path="/actions"
                element={
                  this.state.isAuth ? <Actions /> : <Navigate to="/login" />
                }
              />
              <Route
                exact
                path="/practices"
                element={
                  this.state.isAuth ? <Practices /> : <Navigate to="/login" />
                }
              />
              <Route
                exact
                path="/planning"
                element={
                  this.state.isAuth ? <Planning /> : <Navigate to="/login" />
                }
              />
              <Route
                exact
                path="/settings"
                element={
                  this.state.isAuth ? <Settings /> : <Navigate to="/login" />
                }
              />
              <Route
                exact
                path="/cleaning"
                element={
                  this.state.isAuth ? <Cleaning /> : <Navigate to="/login" />
                }
              />
              <Route
                exact
                path="/optimisations"
                element={
                  this.state.isAuth ? (
                    <Optimisations />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/kpis"
                element={
                  this.state.isAuth ? <Kpis /> : <Navigate to="/login" />
                }
              />
              <Route path="*" element={<NotFound />} />
              {/* </>
      ) : (
        
        <Navigate to="/login" exact element={<Login/>}  />
     
        
      )} */}

              <Route path="/" element={<Home />} />
              <Route path="/contact" exact element={<Contact />} />
              <Route path="/login" exact element={<Login />} />
              <Route path="/register" exact element={<Register />} />

              {/*   <Route exact path="/kpis"  element={<Kpis/>} />   
 <Route path="/config" exact element={<Config/>} />
 <Route path="/kpis" exact element={<Kpis/>} />
 <Route path="/optimisations" exact element={<Optimisations/>} />
 <Route path="/actions" exact element={<Actions/>} />
 <Route path="/practices" exact element={<Practices/>} />  
 <Route path="/planning" element={<Planning/>} />   */}
            </Routes>
          </CustomLayout>
        </UserContext.Provider>
      </div>
    );
  }
}

export default App;

import React from "react";
///import Articles from '../components/Article';
import axios from "axios";
import { Button, Card } from "antd";
import Actions from "../components/Action";
import ActionForm from "../components/FormAction";
//import ObjectID from

class ActionDetail extends React.Component {
  state = {
    action: {}, /// object au lieu de list []
  };

  componentDidMount() {
    const actionID = this.props.match.params.actionID;
    //const actionID=this.props.Actions(this.props.match.params.id);

    axios.get(`https://www.sptapp.eu/api2/v1/${actionID}`).then((res) => {
      this.setState({
        action: res.data,
      });
    });
  }

  handleDelete = (event) => {
    const actionID = this.props.match.params.actionID;
    //const actionID=this.props.Actions(this.props.match.params.id);
    axios.delete(`https://www.sptapp.eu/api2/v1/${actionID}/deleteA/`);
    this.props.history.push("/actions");
    this.forceUpdate();
  };

  render() {
    // <a herf="http://127.0.0.1:3000/actions" >Return</a>
    return (
      <div>
        <Card title={this.state.action.title_ac}>
          <p>{this.state.action.content_ac}</p>
          <p>{this.state.action.actionID}</p>
        </Card>
        <ActionForm
          requestType="put"
          articleID={this.props.match.params.actionID}
          btnText="Update"
        />
        <br />
        <form onSubmit={this.handleDelete}>
          <Button type="danger" htmlType="submit">
            Delete
          </Button>
        </form>
      </div>
    );
  }
}

export default ActionDetail;

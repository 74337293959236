import React from 'react'

import Practices from '../components/Practice'
import axios from 'axios'
import PracticeForm from '../components/FormPractice'

import BestForm from '../components/FormBest'

class PracticeList extends React.Component{

    
    
    

    render (){


        return (
            <div>
            <Practices />
            <br />
           
            </div>
        )

    }

}

export default PracticeList;
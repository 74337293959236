import React from 'react';

class ResetZoomButton extends React.Component {
  handleResetZoom = () => {
    if (this.props.zoomPlugin) {
      this.props.zoomPlugin.resetZoom();
    }
  };

  render() {
    return (
      <button onClick={this.handleResetZoom}>Reset Zoom</button>
    );
  }
}

export default ResetZoomButton;

import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import { ReactDOM } from "react";
import { useState, useEffect, useRef } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  ConfigProvider,
  Radio,
  Dropdown,
  Menu,
  List,
} from "antd";
import axios from "axios";
import { PDFDownloadLink } from "@react-pdf/renderer";

// // // Create styles
import { hot } from "react-hot-loader/root";
//if (module.hot) module.hot.accept()

function renderSplitValues(data) {
  let values = [];

  // Check if the data is a string or an array
  if (typeof data === "string") {
    values = data.split(",");
  } else if (Array.isArray(data)) {
    values = data;
  } else {
    // Handle unexpected data types
    console.error("Unexpected data format:", data);
    return null; // Return null if data format is unexpected
  }

  // Map each value to a JSX element
  const renderedValues = values.map((value, index) => (
    <Text key={index}>
      {index > 0 ? ", " : ""} {/* Add comma for all values except the first */}
      {value}
    </Text>
  ));

  // Return the array of rendered values
  return renderedValues;
}

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    color: "blue",
  },
  heading: {
    fontSize: 20,
    fontWeight: 600,
    color: "#131925",
    marginBottom: 8,
  },
  statement: {
    fontSize: 20,
    color: "#131925",
    lineHeight: 1.4,
    marginBottom: 4,
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "#999999",
    margin: "24px 0 24px 0",
  },
  paragraph: {
    fontSize: 11,
    color: "#212935",
    lineHeight: 1.67,
  },
  columnParent: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  columnStart: {
    flex: 1,
  },
  columnEnd: {
    flex: 1,
    alignItems: "flex-end",
  },
  textSmall: {
    fontSize: 7,
    margin: 0,
    padding: 0,
    height: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: "bold",
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  // 	page: {
  // 		flexDirection: 'row',
  // 		backgroundColor: '#E4E4E4'
  // 	},
  // 	section: {
  // 		margin: 10,
  // 		padding: 10,
  // 		flexGrow: 1
  // 	},
  image: {
    width: "80%",
    padding: 10,
    alignItems: "center",
  },
  imagesmall: {
    width: "5%",
    padding: 10,
    alignItems: "center",
  },
});

// // // Create Document Component
class MyDocument extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      ploto: [],
      plotoN: [],

      plots: [],
    };
  }
  // constructor(props){
  // 	super(props);
  // 	// self = this;
  // }

  async handleplots() {
    if (this.props.sim) {
      this.state.plots = this.props.simulations["plots"];
    }
  }

  render() {
    //const fetchedSIM =this.props.simulations["configurationID"];
    //console.log("fetch",fetchedData)
    //const textfet= Buffer(fetchedData).toString('base64')
    //console.log("fetch",textfet)
    //console.log("plots IRR",actionsPDF)

    console.log("plots IRR", this.props.configurations["location"]["country"]);

    /// Spliting data of LCOE and Self

    const opt = this.props.opt;
    const sim = this.props.sim;
    const nsga = this.props.nsga;

    const fetchedData = JSON.parse(sessionStorage.getItem("data"));
    console.log(
      "fetcdatatata, ",
      fetchedData,
      JSON.parse(sessionStorage.getItem("data"))
    );
    const fetchedDataN = JSON.parse(sessionStorage.getItem("dataN"));

    //const ploto= []

    if (opt) {
      const fetchedData = JSON.parse(sessionStorage.getItem("data"));
      const IRR = fetchedData.data["outcomes"]["IRR"];
      const buildingLCOEData = fetchedData.data.outcomes.BuildingLCOE;

      let buildingLCOEValues = [];

      if (typeof buildingLCOEData === "string") {
        // If it's a string, split it
        buildingLCOEValues = buildingLCOEData.split(",");
      } else if (Array.isArray(buildingLCOEData)) {
        // If it's an array, assume it's already split
        buildingLCOEValues = buildingLCOEData;
      } else {
        // Handle other types accordingly, or throw an error
        console.error(
          "Unexpected data format for BuildingLCOE:",
          buildingLCOEData
        );
      }

      console.log("selfConsumptionValues", buildingLCOEValues);
      const renderedBValues = buildingLCOEValues.map((value, index) => (
        <Text key={index}>
          {index > 0 ? ", " : ""}
          {/* Add comma for all values except the first */}
          {value}
        </Text>
      ));

      const SELFData = fetchedData.data.outcomes.resultsGlobal;

      let selfConsumptionValues = [];

      if (typeof SELFData === "string") {
        // If it's a string, split it
        selfConsumptionValues = SELFData.split(",");
      } else if (Array.isArray(SELFData)) {
        // If it's an array, assume it's already split
        selfConsumptionValues = SELFData;
      } else {
        // Handle other types accordingly, or throw an error
        console.error("Unexpected data format for BuildingLCOE:", SELFData);
      }

      console.log("selfConsumptionValues", selfConsumptionValues);
      const renderedSValues = selfConsumptionValues.map((value, index) => (
        <Text key={index}>
          {index > 0 ? ", " : ""}
          {/* Add comma for all values except the first */}
          {value}
        </Text>
      ));
      console.log("ploto", this.props.optimizations);
      //console.log("ploto", ploto)
      const SIDN = fetchedData.data["optimizationID"];
      this.state.ploto = this.props.optimizations["plots"];
    }

    if (nsga) {
      const fetchedDataN = JSON.parse(sessionStorage.getItem("dataN"));
      const IRR = fetchedDataN.data["outcomes"]["IRR"];
      const buildingLCOEDataN = fetchedDataN.data.outcomes.BuildingLCOE;

      let buildingLCOEValuesN = [];

      if (typeof buildingLCOEDataN === "string") {
        // If it's a string, split it
        buildingLCOEValuesN = buildingLCOEDataN.split(",");
      } else if (Array.isArray(buildingLCOEDataN)) {
        // If it's an array, assume it's already split
        buildingLCOEValuesN = buildingLCOEDataN;
      } else {
        // Handle other types accordingly, or throw an error
        console.error(
          "Unexpected data format for BuildingLCOE:",
          buildingLCOEDataN
        );
      }

      console.log("selfConsumptionValues", buildingLCOEValuesN);
      const renderedBValuesN = buildingLCOEValuesN.map((value, index) => (
        <Text key={index}>
          {index > 0 ? ", " : ""}
          {/* Add comma for all values except the first */}
          {value}
        </Text>
      ));

      const SELFDataN = fetchedDataN.data.outcomes.resultsGlobal;

      let selfConsumptionValuesN = [];

      if (typeof SELFDataN === "string") {
        // If it's a string, split it
        selfConsumptionValuesN = SELFDataN.split(",");
      } else if (Array.isArray(SELFDataN)) {
        // If it's an array, assume it's already split
        selfConsumptionValuesN = SELFDataN;
      } else {
        // Handle other types accordingly, or throw an error
        console.error("Unexpected data format for BuildingLCOE:", SELFDataN);
      }

      console.log("selfConsumptionValues", selfConsumptionValuesN);
      const renderedSValuesN = selfConsumptionValuesN.map((value, index) => (
        <Text key={index}>
          {index > 0 ? ", " : ""}
          {/* Add comma for all values except the first */}
          {value}
        </Text>
      ));
      console.log("ploto", this.props.optimizationsN);
      //console.log("ploto", ploto)
      const SIDN = fetchedDataN.data["optimizationID"];
      this.state.plotoN = this.props.optimizationsN["plots"];
    }

    const outcomes = [];
    console.log("outcomes", outcomes);

    if (sim) {
      console.log("ploto", this.props.simulations);
      this.state.plots = this.props.simulations["plots"];
      console.log("ploto", this.state.plots);
      const outcomes = this.props.results["outcomes"];
    }
    console.log("configguuu", this.props.configurations);
    const country = this.props.configurations["location"]["country"];
    const town = this.props.configurations["location"]["town"];
    const actionsPDF = this.props.actions;
    //console.log("plots", plots)
    console.log("plots IRR", actionsPDF);
    const exampleData = [
      {
        column1: "Row 1 Col 1",
        column2: "Row 1 Col 2",
        column3: "Row 1 Col 3",
        column4: "Row 1 Col 4",
      },
      {
        column1: "Row 2 Col 1",
        column2: "Row 2 Col 2",
        column3: "Row 2 Col 3",
        column4: "Row 2 Col 4",
      },
      {
        column1: "Row 3 Col 1",
        column2: "Row 3 Col 2",
        column3: "Row 3 Col 3",
        column4: "Row 3 Col 4",
      },
      {
        column1: "Row 4 Col 1",
        column2: "Row 4 Col 2",
        column3: "Row 4 Col 3",
        column4: "Row 4 Col 4",
      },
    ];

    return (
      <Document aria-label="pdf-document">
        <Page pageNumber={1} size="A4" style={styles.page}>
          <View style={styles.section}>
            <View style={styles.columnParent}>
              <View style={styles.columnStart}>
                <Text style={styles.heading}> Organization: RDIUP </Text>

                <Text style={styles.paragraph}>
                  User Name: {localStorage.getItem("user")}
                </Text>
                <Text style={styles.paragraph}>Contact</Text>
              </View>
              <View style={styles.columnEnd}>
                <Text style={styles.heading}>Replication</Text>

                {opt && (
                  <Text style={styles.paragraph}>
                    Result Number: {fetchedData.data["resultOPTID"]}
                  </Text>
                )}
                {opt && (
                  <Text style={styles.paragraph}>
                    Nedler Optimization Number:{" "}
                    {fetchedData.data["optimizationID"]}
                  </Text>
                )}
                {nsga && (
                  <Text style={styles.paragraph}>
                    {" "}
                    NSGA Optimization Number:{" "}
                    {fetchedDataN.data["optimizationID"]}
                  </Text>
                )}

                {sim && (
                  <Text style={styles.paragraph}>
                    Simulation Number: {this.props.simulationID}
                  </Text>
                )}

                <Text style={styles.paragraph}>
                  Configuration Number:{" "}
                  {this.props.configurations["configurationID"]}
                </Text>
              </View>
            </View>
            <View style={styles.divider}></View>
          </View>

          <View>
            <Text style={{ color: "red", textAlign: "center" }}>
              {" "}
              Report for {String(town)}{" "}
            </Text>
            <br></br>
            <Text style={styles.paragraph}>
              {" "}
              {"\n"}
              This report aims to present the configuration{" "}
              {String(this.props.configurations["configurationID"])} used for
              the simulation for the {String(town)}. Also it will provide the
              results of the simulation and the key figures generated. Then,
              main actions to be followed and best practices to improve their
              implementation experience. It will be concluded.{" "}
            </Text>
            <Text style={styles.section}>
              {" "}
              Section #1 Configuration of the replication{" "}
            </Text>
            <Text style={styles.paragraph}>
              {" "}
              The configuration{" "}
              {String(this.props.configurations["configurationID"])} of the
              Energy community is composed of various energy assets and loads.{" "}
            </Text>{" "}
            <Text style={styles.paragraph}>
              {" "}
              The EC is located in {String(town)}, {String(country)}, and the
              starting date of the historical weather data is from{" "}
              {String(this.props.configurations["date"])}{" "}
            </Text>
            <Text style={styles.paragraph}>
              {" "}
              The definitions of the technical and financial KPIs used for the
              study are: :
            </Text>
            <View style={styles.divider}></View>
            <Text style={styles.paragraph}>
              {" "}
              IRR (internal rate of return %), is a metric used in financial
              analysis to estimate the profitability of potential investments.
              IRR is a discount rate that makes the net present value (NPV) of
              all cash flows equal to zero. DECARB (kCO2) is the amount of
              emissions avoided by implementing green energy systems and storage
              capacities while considering its emissions during the lifecyle
            </Text>
            <Text style={styles.paragraph}>
              {" "}
              Return (%), is an anualized return on investment used to evaluate
              the forecasted profitability while considering the monetized
              inflation. PAYBACK (Year) is the duration time to refund the
              initial investement based on the yearly cashflow{" "}
            </Text>
            <Text style={styles.paragraph}>
              {" "}
              CASH (€), is the difference between the costs and the benefits
              each year. We assume that it is contant during the project
              lifetime without any degradation in this SPT. SAVINGS are the
              monetized benefits from the ESS during the porject life time while
              consdeting the cost of energy delivered from the grid
            </Text>
            <Text style={styles.paragraph}>
              {" "}
              SELF-consumption (%), is the percentage of energy consumption
              covered by the energy system installed. The SPT calculates this
              metric for each loads.{" "}
            </Text>
            <Text style={styles.paragraph}>
              {" "}
              Abatment cost is a cost spent by an organization to remove and/or
              reduce kCO2 emissions.{" "}
            </Text>
            <Text style={styles.paragraph}>
              {" "}
              CO2 footprint (%), is the amount of CO2 emissions avoided as the
              energy consumed by the is coming from clean generator. The Life
              cycle of CO2 emissions linked these generator are considered.{" "}
            </Text>
            <View>
              {(sim || opt || nsga) && (
                /*  <Image
                    style={styles.image}
                    src={`https://www.sptapp.eu/media/${localStorage.getItem(
                      "user"
                    )}/images/imageSIM/weather_${
                      this.props.configurations["configurationID"]
                    }.png`}
                  /> */

                <Image
                  style={styles.image}
                  src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                    "user"
                  )}/images/imageSIM/weather_${
                    this.props.configurations["configurationID"]
                  }.png`}
                />
              )}
              <Text style={styles.paragraph}>
                {" "}
                It displays the hourly data of temperature, the irradiance and
                wind speed during 1 year 2022 from an external API. Totally, we
                obtain 8760 measurements.
              </Text>
            </View>
            {sim && (
              <View>
                <Text style={styles.section}>
                  {" "}
                  Section # the simulation{" "}
                  {String(this.props.simulations["name"])}{" "}
                </Text>
                <Text style={styles.paragraph}>
                  {" "}
                  The simulation targets the objective function: "{" "}
                  {String(this.props.simulations["kpi"])} ".
                  {"\n"} This function aims to optimize the portfolio. Also, the
                  plots selected in this study are:
                </Text>

                {this.state.plots[0] && (
                  <Text style={styles.paragraph}> - EnergyDynamics </Text>
                )}
                {this.state.plots[1] && (
                  <Text style={styles.paragraph}> - State of Charge </Text>
                )}
                {this.state.plots[2] && (
                  <Text style={styles.paragraph}> - Cashflow </Text>
                )}
                {this.state.plots[3] && (
                  <Text style={styles.paragraph}> - NPV </Text>
                )}
                {this.state.plots[4] && (
                  <Text style={styles.paragraph}> - Usage </Text>
                )}
                {this.state.plots[5] && (
                  <Text style={styles.paragraph}> - Efficiency</Text>
                )}

                <Text style={styles.section}>
                  {" "}
                  Section # the results ID {String(this.props.resultID)}{" "}
                </Text>

                <Text style={styles.paragraph}>
                  {" "}
                  The key results of the simulations are illustrated in the
                  table below:
                </Text>

                {/* <View style={styles.section}>
								<Text>Simulation Results Table</Text>
								</View>
								<View style={styles.table}>
					
								<View style={styles.tableRow}>
									<View style={styles.tableCol}>
									<Text style={styles.tableCell}>Metric</Text>
									</View>
									<View style={styles.tableCol}>
									<Text style={styles.tableCell}>Value</Text>
									</View>
								</View>
					
								{Object.entries(this.props.results["outcomes"]).map(([key, value]) => (
									<View style={styles.tableRow} key={key}>
									<View style={styles.tableCol}>
										<Text style={styles.tableCell}>{key}</Text>
									</View>
									<View style={styles.tableCol}>
										<Text style={styles.tableCell}>{value}</Text>
									</View>
									</View>
								))}
								</View> */}

                <View style={styles.section}>
                  <Text>Results Table</Text>
                </View>
                <View style={styles.table}>
                  {/* Table Header */}
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Metric</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Value</Text>
                    </View>
                  </View>
                  {/* Table Content */}
                  {Object.entries(this.props.results["outcomes"]).map(
                    ([key, value]) =>
                      Array.isArray(value) ? (
                        value.map((val, index) => (
                          <View style={styles.tableRow} key={`${key}-${index}`}>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {index === 0 ? key : ""}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>{val}</Text>
                            </View>
                          </View>
                        ))
                      ) : (
                        <View style={styles.tableRow} key={key}>
                          <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{key}</Text>
                          </View>
                          <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{value}</Text>
                          </View>
                        </View>
                      )
                  )}
                </View>

                {/* 	<View style={styles.columnStart}>
                               <Text style={styles.paragraph}> IRR: "{String(this.props.results["outcomes"]["IRR"].toFixed(2))}" </Text>
                               <Text style={styles.paragraph}> DECARB: "{String(this.props.results["outcomes"]["DECRAB"].toFixed(2))}" </Text>
                               <Text style={styles.paragraph}> Initial Savings: "{String(this.props.results["outcomes"]["Savings"].toFixed(2))}"</Text>
							   <Text style={styles.paragraph}> Return: "{String(this.props.results["outcomes"]["Return"].toFixed(2))}" </Text>
                               <Text style={styles.paragraph}> LCOE: "{String(this.props.results["outcomes"]["LCOE"].toFixed(2))}"</Text>
							   
                               <Text style={styles.paragraph}> Payback: "{String(this.props.results["outcomes"]["Payback"])}" </Text>
							   <Text style={styles.paragraph}> BuildingLCOE: "{renderSplitValues(this.props.results.outcomes.BuildingLCOE)}" </Text>
							   <Text style={styles.paragraph}> Self-consumption: "{renderSplitValues(this.props.results.outcomes.resultsGlobal)}" </Text>
							   <Text style={styles.paragraph}> Abatment cost: "{String(this.props.results["outcomes"]["AbatCost"].toFixed(2))}" </Text>

                            </View> */}

                <View style={styles.divider}></View>

                <Text style={styles.section}> Section # Images </Text>
                <Text style={styles.paragraph}>
                  {" "}
                  The images below are generated based on the parameters above.{" "}
                </Text>

                {this.state.plots[0] && (
                  <View>
                    <Image
                      style={styles.image}
                      src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                        "user"
                      )}/images/imageSIM/dynamics_${
                        this.props.simulations["simulationID"]
                      }.png`}
                    />
                    <Text style={styles.paragraph}>
                      {" "}
                      This figure displays the behaviors and dynamics of the
                      generation system, storage capacities and loads. Over a
                      one year period, the total load requirement is
                      'system.totalLoad' kWh and the total generation is
                      'system.totalGeneration' kWh. This graph can be useful in
                      analyzing the delta between demand and supply of the
                      system, and for making further design decisions.
                    </Text>
                  </View>
                )}
                {this.state.plots[1] && (
                  <View>
                    <Image
                      style={styles.image}
                      src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                        "user"
                      )}/images/imageSIM/SoC_${
                        this.props.simulations["simulationID"]
                      }.png`}
                    />
                    <Text style={styles.paragraph}>
                      {" "}
                      The simplified models allow with less information to know
                      about the chemistry and dynamics of the specific storage
                      system (e.g. Battery, fuelcell) in order to perform
                      calculations for power output, capacity, and State of
                      Charge (SOC). The storage system contains two parts: a
                      charge model and a discharge model. The image SoC above is
                      generated based on the parameters entered in the
                      configuration step{" "}
                    </Text>
                  </View>
                )}
                {this.state.plots[2] && (
                  <View>
                    <Image
                      style={styles.image}
                      src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                        "user"
                      )}/images/imageSIM/cashflow_${
                        this.props.simulations["simulationID"]
                      }.png`}
                    />
                    <Text style={styles.paragraph}>
                      {" "}
                      The cashflow is calculated based on the CAPEX and OPEX
                      data of each energy assets. Also, it will consider the
                      energy generated by the portfolio as benefits and revenues
                      as not delivered by the Grid. The image cashflow above is
                      generated based on the parameters linked to the
                      configuration phase{" "}
                    </Text>
                  </View>
                )}
                {this.state.plots[3] && (
                  <View>
                    <Image
                      style={styles.image}
                      src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                        "user"
                      )}/images/imageSIM/NPV_${
                        this.props.simulations["simulationID"]
                      }.png`}
                    />
                    <Text style={styles.paragraph}>
                      {" "}
                      The net present value (NPV) is an economic indicator of
                      how an investment contributes to an organisation or
                      business during project life time. When the NPV is
                      positive, the investment might be profitable. The
                      intersection between the two axes (if exists) is the
                      Payback of the investment. The image NPV is generated
                      based on the parameters defined in the first configuration
                      phase{" "}
                    </Text>
                  </View>
                )}
                {this.state.plots[4] && (
                  <View>
                    <Image
                      style={styles.image}
                      src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                        "user"
                      )}/images/imageSIM/usage_${
                        this.props.simulations["simulationID"]
                      }.png`}
                    />
                    <Text style={styles.paragraph}>
                      {" "}
                      The image Usage above is generated based on the parameters
                      defined at the begining. It allows to display the use of
                      energy generation and storage hourly based on the
                      consumption data. Also, it can show the energy delivered
                      by the grid or th eneergy that could be exported to the
                      grid.{" "}
                    </Text>
                  </View>
                )}
                {this.state.plots[5] && (
                  <View>
                    <Image
                      style={styles.image}
                      src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                        "user"
                      )}/images/imageSIM/efficiency_${
                        this.props.simulations["simulationID"]
                      }.png`}
                    />
                    <Text style={styles.paragraph}>
                      {" "}
                      The image efficiency above is generated based on the
                      parameters entered in the first step. It is the efficiency
                      of the whole system configured.{" "}
                    </Text>
                  </View>
                )}
              </View>
            )}
            {opt && (
              <View>
                <Text style={styles.section}>
                  {" "}
                  Section # the optimisation results ID{" "}
                  {String(this.props.resultOPTID)}{" "}
                </Text>
                <Text style={styles.paragraph}>
                  {" "}
                  The definitions of the technical and financial KPIs used for
                  optimisation are: :
                </Text>
                <View style={styles.divider}></View>
                <Text style={styles.paragraph}>
                  {" "}
                  IRR (internal rate of return %), is a metric used in financial
                  analysis to estimate the profitability of potential
                  investments. IRR is a discount rate that makes the net present
                  value (NPV) of all cash flows equal to zero. DECARB (kCO2) is
                  the amount of emissions avoided by implementing green energy
                  systems and storage capacities while considering its emissions
                  during the lifecyle
                </Text>
                <Text style={styles.paragraph}>
                  {" "}
                  Return (%), is an anualized return on investment used to
                  evaluate the forecasted profitability while considering the
                  monetized inflation. PAYBACK (Year) is the duration time to
                  refund the initial investement based on the yearly cashflow{" "}
                </Text>
                <Text style={styles.paragraph}>
                  {" "}
                  CASH (€), is the difference between the costs and the benefits
                  each year. We assume that it is contant during the project
                  lifetime without any degradation in this SPT. SAVINGS are the
                  monetized benefits from the ESS during the porject life time
                  while consdeting the cost of energy delivered from the grid
                </Text>
                <Text style={styles.paragraph}>
                  {" "}
                  SELF-consumption (%), is the percentage of energy consumption
                  covered by the energy system installed. The SPT calculates
                  this metric for each loads. Abatment cost is a cost spent by
                  an organization to remove and/or reduce kCO2 emissions.
                </Text>

                <Text style={styles.paragraph}>
                  {" "}
                  The key results of the optimisations are:{" "}
                </Text>

                <View style={styles.section}>
                  <Text>Results Table</Text>
                </View>
                <View style={styles.table}>
                  {/* Table Header */}
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Metric</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Value</Text>
                    </View>
                  </View>
                  {/* Table Content */}
                  {Object.entries(fetchedData.data["outcomes"]).map(
                    ([key, value]) =>
                      Array.isArray(value) ? (
                        value.map((val, index) => (
                          <View style={styles.tableRow} key={`${key}-${index}`}>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {index === 0 ? key : ""}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>{val}</Text>
                            </View>
                          </View>
                        ))
                      ) : (
                        <View style={styles.tableRow} key={key}>
                          <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{key}</Text>
                          </View>
                          <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{value}</Text>
                          </View>
                        </View>
                      )
                  )}
                </View>

                {/* 	<View style={styles.columnStart}>
					<Text style={styles.paragraph}> IRR: "{String(fetchedData.data["outcomes"]["IRR"].toFixed(2))}" </Text>
					<Text style={styles.paragraph}> DECARB: "{String(fetchedData.data["outcomes"]["DECRAB"].toFixed(2))}" </Text>
					<Text style={styles.paragraph}> Savings: "{String(fetchedData.data["outcomes"]["Savings"].toFixed(2))}"</Text>
					<Text style={styles.paragraph}> Return: "{String(fetchedData.data["outcomes"]["Return"].toFixed(2))}" </Text>
					<Text style={styles.paragraph}> LCOE: "{String(fetchedData.data["outcomes"]["LCOE"].toFixed(2))}"</Text>
					<Text style={styles.paragraph}> Payback: "{String(fetchedData.data["outcomes"]["Payback"])}" </Text>
					<Text style={styles.paragraph}> BuildingLCOE: "{renderSplitValues(fetchedData.data.outcomes.BuildingLCOE)}" </Text>
					<Text style={styles.paragraph}> Self-consumption: "{renderSplitValues(fetchedData.data.outcomes.resultsGlobal)}" </Text>
					<Text style={styles.paragraph}> Abatment cost: "{String(fetchedData.data["outcomes"]["AbatCost"].toFixed(2))}" </Text>

					</View> */}

                <Text style={styles.section}>
                  {" "}
                  Section # plots of the optimization ID{" "}
                  {this.props.optimizations["optimizationID"]} and result ID{" "}
                  {String(this.props.resultOPTID)}{" "}
                </Text>

                <Text style={styles.paragraph}>
                  {" "}
                  The images below are generated based on the parameters above.{" "}
                </Text>

                {this.state.ploto[0] && (
                  <View>
                    <Image
                      style={styles.image}
                      src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                        "user"
                      )}/images/imageOPT/dynamics_${
                        this.props.optimizations["optimizationID"]
                      }.png`}
                    />
                    <Text style={styles.paragraph}>
                      {" "}
                      The figure displays the behaviors and dynamics of the
                      generation system, storage capacities and loads. Over a
                      one year period, the total load requirement is
                      'system.totalLoad' kWh and the total generation is
                      'system.totalGeneration' kWh. This graph can be useful in
                      analyzing the delta between demand and supply of the
                      system, and for making further design decisions.
                    </Text>
                  </View>
                )}
                {this.state.ploto[1] && (
                  <View>
                    <Image
                      style={styles.image}
                      src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                        "user"
                      )}/images/imageOPT/SoC_${
                        this.props.optimizations["optimizationID"]
                      }.png`}
                    />
                    <Text style={styles.paragraph}>
                      {" "}
                      The simplified models allow with less information to know
                      about the chemistry and dynamics of the specific storage
                      system (e.g. Battery, fuelcell) in order to perform
                      calculations for power output, capacity, and State of
                      Charge (SOC). The storage system contains two parts: a
                      charge model and a discharge model. The image SoC above is
                      generated based on the parameters entered in the
                      configuration step{" "}
                    </Text>
                  </View>
                )}
                {this.state.ploto[2] && (
                  <View>
                    <Image
                      style={styles.image}
                      src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                        "user"
                      )}/images/imageOPT/cashflow_${
                        this.props.optimizations["optimizationID"]
                      }.png`}
                    />
                    <Text style={styles.paragraph}>
                      {" "}
                      The cashflow is calculated based on the CAPEX and OPEX
                      data of each energy assets. Also, it will consider the
                      energy generated by the portfolio as benefits and revenues
                      as not delivered by the Grid. The image cashflow above is
                      generated based on the parameters linked to the
                      configuration phas{" "}
                    </Text>
                  </View>
                )}
                {this.state.ploto[3] && (
                  <View>
                    <Image
                      style={styles.image}
                      src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                        "user"
                      )}/images/imageOPT/NPV_${
                        this.props.optimizations["optimizationID"]
                      }.png`}
                    />
                    <Text style={styles.paragraph}>
                      {" "}
                      The net present value (NPV) is an economic indicator of
                      how an investment contributes to an organisation or
                      business during project life time. When the NPV is
                      positive, the investment might be profitable. The
                      intersection between the two axes (if exists) is the
                      Payback of the investment. The image NPV is generated
                      based on the parameters defined in the first configuration
                      phase.{" "}
                    </Text>
                  </View>
                )}
                {this.state.ploto[4] && (
                  <View>
                    <Image
                      style={styles.image}
                      src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                        "user"
                      )}/images/imageOPT/usage_${
                        this.props.optimizations["optimizationID"]
                      }.png`}
                    />
                    <Text style={styles.paragraph}>
                      {" "}
                      The Usage plot above is generated based on the parameters
                      defined at the begining. It allows to display the use of
                      energy generation and storage hourly based on the
                      consumption data. Also, it can show the energy delivered
                      by the grid or th eneergy that could be exported to the
                      grid.
                    </Text>
                  </View>
                )}
                {this.state.ploto[5] && (
                  <View>
                    <Image
                      style={styles.image}
                      src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                        "user"
                      )}/images/imageOPT/efficiency_${
                        this.props.optimizations["optimizationID"]
                      }.png`}
                    />
                    <Text style={styles.paragraph}>
                      {" "}
                      The image efficiency above is generated based on the
                      parameters entered in the first step. It is the efficiency
                      of the whole system configured.{" "}
                    </Text>
                  </View>
                )}
              </View>
            )}
            {nsga && (
              <View>
                <Text style={styles.section}>
                  {" "}
                  The NSGA optimisation results ID{" "}
                  {String(this.props.resultOPTIDN)}{" "}
                </Text>
                <Text style={styles.paragraph}>
                  {" "}
                  In this section we show the results of optimisations by using
                  NSGA or genetic algorithms
                </Text>

                <Text style={styles.paragraph}>
                  {" "}
                  The key results of the optimisations are:{" "}
                </Text>

                {/*  <View style={styles.columnStart}>
	 <Text style={styles.paragraph}> IRR: "{String(fetchedDataN.data["outcomes"]["IRR"].toFixed(2))}" </Text>
	 <Text style={styles.paragraph}> DECARB: "{String(fetchedDataN.data["outcomes"]["DECRAB"].toFixed(2))}" </Text>
	 <Text style={styles.paragraph}> Savings: "{String(fetchedDataN.data["outcomes"]["Savings"].toFixed(2))}"</Text>
	 <Text style={styles.paragraph}> Return: "{String(fetchedDataN.data["outcomes"]["Return"].toFixed(2))}" </Text>
	 <Text style={styles.paragraph}> LCOE: "{String(fetchedDataN.data["outcomes"]["LCOE"].toFixed(2))}"</Text>
	 <Text style={styles.paragraph}> Payback: "{String(fetchedDataN.data["outcomes"]["Payback"])}" </Text>
	 <Text style={styles.paragraph}> BuildingLCOE: "{renderSplitValues(fetchedDataN.data.outcomes.BuildingLCOE)}" </Text>
	 <Text style={styles.paragraph}> Self-consumption: "{renderSplitValues(fetchedDataN.data.outcomes.resultsGlobal)}" </Text>
	 <Text style={styles.paragraph}> Abatment cost: "{String(fetchedDataN.data["outcomes"]["AbatCost"].toFixed(2))}" </Text>

	 </View> */}

                <View style={styles.section}>
                  <Text>Results Table</Text>
                </View>
                <View style={styles.table}>
                  {/* Table Header */}
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Metric</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Value</Text>
                    </View>
                  </View>
                  {/* Table Content */}
                  {Object.entries(fetchedDataN.data["outcomes"]).map(
                    ([key, value]) =>
                      Array.isArray(value) ? (
                        value.map((val, index) => (
                          <View style={styles.tableRow} key={`${key}-${index}`}>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {index === 0 ? key : ""}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>{val}</Text>
                            </View>
                          </View>
                        ))
                      ) : (
                        <View style={styles.tableRow} key={key}>
                          <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{key}</Text>
                          </View>
                          <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{value}</Text>
                          </View>
                        </View>
                      )
                  )}
                </View>

                <Text style={styles.section}>
                  {" "}
                  Section # plots of the optimization ID{" "}
                  {this.props.optimizationsN["optimizationID"]} and result ID{" "}
                  {String(this.props.resultOPTIDN)}{" "}
                </Text>

                <Text style={styles.paragraph}>
                  {" "}
                  The images below are generated based on the parameters above.{" "}
                </Text>

                {this.state.plotoN[0] && (
                  <View>
                    <Image
                      style={styles.image}
                      src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                        "user"
                      )}/images/imageOPT/dynamics_${
                        this.props.optimizationsN["optimizationID"]
                      }.png`}
                    />
                    <Text style={styles.paragraph}>
                      {" "}
                      The figure displays the behaviors and dynamics of the
                      generation system, storage capacities and loads. Over a
                      one year period, the total load requirement is
                      'system.totalLoad' kWh and the total generation is
                      'system.totalGeneration' kWh. This graph can be useful in
                      analyzing the delta between demand and supply of the
                      system, and for making further design decisions.
                    </Text>
                  </View>
                )}
                {this.state.plotoN[1] && (
                  <View>
                    <Image
                      style={styles.image}
                      src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                        "user"
                      )}/images/imageOPT/SoC_${
                        this.props.optimizationsN["optimizationID"]
                      }.png`}
                    />
                    <Text style={styles.paragraph}>
                      {" "}
                      The simplified models allow with less information to know
                      about the chemistry and dynamics of the specific storage
                      system (e.g. Battery, fuelcell) in order to perform
                      calculations for power output, capacity, and State of
                      Charge (SOC). The storage system contains two parts: a
                      charge model and a discharge model. The image SoC above is
                      generated based on the parameters entered in the
                      configuration step{" "}
                    </Text>
                  </View>
                )}
                {this.state.plotoN[2] && (
                  <View>
                    <Image
                      style={styles.image}
                      src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                        "user"
                      )}/images/imageOPT/cashflow_${
                        this.props.optimizationsN["optimizationID"]
                      }.png`}
                    />
                    <Text style={styles.paragraph}>
                      {" "}
                      The cashflow is calculated based on the CAPEX and OPEX
                      data of each energy assets. Also, it will consider the
                      energy generated by the portfolio as benefits and revenues
                      as not delivered by the Grid. The image cashflow above is
                      generated based on the parameters linked to the
                      configuration phas{" "}
                    </Text>
                  </View>
                )}
                {this.state.plotoN[3] && (
                  <View>
                    <Image
                      style={styles.image}
                      src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                        "user"
                      )}/images/imageOPT/NPV_${
                        this.props.optimizationsN["optimizationID"]
                      }.png`}
                    />
                    <Text style={styles.paragraph}>
                      {" "}
                      The net present value (NPV) is an economic indicator of
                      how an investment contributes to an organisation or
                      business during project life time. When the NPV is
                      positive, the investment might be profitable. The
                      intersection between the two axes (if exists) is the
                      Payback of the investment. The image NPV is generated
                      based on the parameters defined in the first configuration
                      phase.{" "}
                    </Text>
                  </View>
                )}
                {this.state.plotoN[4] && (
                  <View>
                    <Image
                      style={styles.image}
                      src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                        "user"
                      )}/images/imageOPT/usage_${
                        this.props.optimizationsN["optimizationID"]
                      }.png`}
                    />
                    <Text style={styles.paragraph}>
                      {" "}
                      The Usage plot above is generated based on the parameters
                      defined at the begining. It allows to display the use of
                      energy generation and storage hourly based on the
                      consumption data. Also, it can show the energy delivered
                      by the grid or th eneergy that could be exported to the
                      grid.
                    </Text>
                  </View>
                )}
                {this.state.plotoN[5] && (
                  <View>
                    <Image
                      style={styles.image}
                      src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                        "user"
                      )}/images/imageOPT/efficiency_${
                        this.props.optimizationsN["optimizationID"]
                      }.png`}
                    />
                    <Text style={styles.paragraph}>
                      {" "}
                      The image efficiency above is generated based on the
                      parameters entered in the first step. It is the efficiency
                      of the whole system configured.{" "}
                    </Text>
                  </View>
                )}
                <View>
                  <Image
                    style={styles.image}
                    src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                      "user"
                    )}/images/imageOPT/callback_${
                      this.props.optimizationsN["optimizationID"]
                    }.png`}
                  />
                  <Text style={styles.paragraph}>
                    {" "}
                    This figure can be useful to track "savings" at each
                    generation, or even modify the algorithm iterations during
                    the run. This figure shows the convergence of savings over
                    the iterations and it is only recommended for experienced
                    users. The Callback allows to select only the information
                    necessary to be analyzed when the run has terminated.
                    Another good use case can be to visualize from which
                    iteration the minimization of cost becomes horizontal to
                    reduce time and memory computing.{" "}
                  </Text>
                </View>

                <View>
                  <Image
                    style={styles.image}
                    src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                      "user"
                    )}/images/imageOPT/Pareto_${
                      this.props.optimizationsN["optimizationID"]
                    }.png`}
                  />
                  <Text style={styles.paragraph}>
                    {" "}
                    The pareto above allows to provide a trade-off between
                    Carbon Intensity (gCO2e/kWh) and project life cost (€) that
                    will support making appropriate decisions. This illustrates
                    a dilemma: the more we aim to reduce carbon emissions, the
                    more the energy system needs to spend, thereby reducing the
                    cost savings.
                  </Text>
                </View>

                <View>
                  <Image
                    style={styles.image}
                    src={`https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
                      "user"
                    )}/images/imageOPT/plot_${
                      this.props.optimizationsN["optimizationID"]
                    }.png`}
                  />
                  <Text style={styles.paragraph}>
                    {" "}
                    The plot shows the optimal energy variables sorted by the
                    two objective functions "cost-emissions". It shows the
                    evolution of a set of solutions over the time. Also, it
                    highlights the best best cost and best emissions to help
                    managers make best decisions.
                  </Text>
                </View>
              </View>
            )}
            <Text> Section # Actions </Text>
            <br></br>
            <Text style={styles.paragraph}>
              {" "}
              In order to ensure an efficient green energy transition, you have
              to folllow to following actions:{" "}
            </Text>
            {this.props.actions &&
              this.props.actions.map((a) => {
                return (
                  <View>
                    <Text style={styles.paragraph}>
                      {" "}
                      - {a.title_ac}: {a.content_ac}
                    </Text>
                  </View>
                );
              })}
            <Text> Section # Best practices </Text>
            <br></br>
            <Text style={styles.paragraph}>
              {" "}
              In order to enhance the implementation practices and lessons
              learnt, it is recommended to consider the following lessons learnt
            </Text>
            {this.props.practices &&
              this.props.practices.map((a) => {
                return (
                  <View>
                    <Text style={styles.paragraph}>
                      {" "}
                      - {a.title_pr}: {a.content_pr}
                    </Text>
                  </View>
                );
              })}
            <Text> Section # Conclusions </Text>
            <br></br>
            <Text style={styles.paragraph}>
              {" "}
              In order to optimize and decarbonize your energy asstes and
              portfolio, you have to
              {"\n"}- Install new storage systems
              {"\n"}- Follow the suggested actions
              {"\n"}- Improve your practices
            </Text>
          </View>
          <View fixed>
            {" "}
            <Image
              style={styles.imagesmall}
              src={require("./imagesSIM/EU.png")}
              width={50}
              height={50}
            />{" "}
            <Text style={styles.textSmall}>
              This SIT tool has received funding from the European Union's \n
              Horizon 2020 and Europeresearch and innovation programme under
              grant agreement No 957852 and 101096836{" "}
            </Text>{" "}
          </View>
        </Page>
      </Document>
    );
  }
}

export default MyDocument;

import React from "react";
import OptimizationForm from "../components/FormOptimization";
import "antd/dist/antd.min.css";
import {Dropdown, Menu} from 'antd';


function Optimisations () {

const divStyle= {
    marging: 100,
    width:250
}
    return (
<div>
        
        <OptimizationForm 
        requestType="post"
        optimizationID={null}
        
        btnText="Run the Optimization"/>
        <br></br>
        {/* <div style={{
      display: 'block', width: 700, padding: 30
    }}>
      <h4> Select a simulation result for the optimization </h4>
      <>
        <Dropdown
          overlay={(
            <Menu>
              <Menu.Item key="0"> Menu Item One </Menu.Item>
              <Menu.Item key="1"> Menu Item Two </Menu.Item>
              <Menu.Item key="2"> Menu Item Three </Menu.Item>
            </Menu>
          )}
          trigger={['click']}>
          <button className="ant-dropdown-link" 
             onClick={e => e.preventDefault()}>
            Simulation ID
          </button>
        </Dropdown>
      </>
    </div> */}
</div>
    )
}

export default Optimisations;
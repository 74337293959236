import React from "react";
import PracticeList from "../containers/PracticeSListView";

function Practices() {

    return ( 
        <div>
        <PracticeList/> 
        </div>
    )
}

export default Practices;
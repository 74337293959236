import React from "react";
import {Button, Input} from 'antd'; 
import './App.css';
import ActionList from "../containers/ActionListView";
import ActionDetail from "../containers/ActionDetailView";
import BaseRouter from "../routes";



function Actions () {

    return (
      <div style={{width: '170vh', marginBottom: 10, padding: 5}}>
         <ActionList/> 
         
         
          
         </div>
    )
}

export default Actions;
// import React, {useState} from 'react';
// import { Steps, Button, message } from 'antd';
// import { Link } from 'react-router-dom';
// import { Route } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
// import BaseRouter from '../routes';
// import Config from '../pages/config';
// import Actions from '../pages/actions';
// import Kpis from '../pages/kpis';
// import Optimisations from '../pages/optimisations';
// import Planning from '../pages/planning';
// import Practices from '../pages/practices';
// import PDF from './pdf';
// const { Step } = Steps;


// const steps = [
//   {
//     title: 'Configuration',
//     content: 'First-step',
//   },
//   {
//     title: 'Kpis',
//     content: 'Next-step',
//   },
//   {
//     title: 'Optimisations',
//     content: 'Next-step',
//   },
//   {
//     title: 'Actions',
//     content: 'Next-step',
//   },
//   {
//     title: 'Best practices',
//     content: 'Next-step',
//   },
//   {
//     title: 'Planning',
//     content: 'Last-step',
//   },


// ];
// const Planification = () => {
//   let history = useHistory();
//   const [current, setCurrent] = React.useState(0);
//   const pages =['config','kpis','optimisations', 'actions', 'practices', 'planning'];
 
//   const next = () => {
//     setCurrent(current + 1);
   
   
//   };
//   const prev = () => {
//     setCurrent(current - 1);
   
//   };
//   return (
//     <>
//      <div className="steps-content">
//         {current === 0 ? <Config/> :
       
//         current === 1 ?   <Kpis/> :
//         current === 2 ? <Optimisations/> :
//         current === 3 ? <Actions/> :
//         current === 4 ? <Practices/> :
//         <Planning/>}
     
//      <br />
//       </div> 
//       <Steps current={current}>
//         {steps.map(item => (
       
//           <Step key={item.title} title={item.title} />
       
         
//         ))}
         
//       </Steps>
     
//       <div className="steps-action">
//       <br />
//         {current < steps.length -1 && (
//           <Button  type="primary" onClick={() => { next();} }>
//            <Link to={pages[current+1]}> Next</Link>
           
//           </Button>
//         )}
//         {current === steps.length -1 && (
//           <Button type="primary" onClick={() => message.success('Processing complete and saved!')}>
//             Save planning
//           </Button>
//         )}
//         {current > 0 && (
//           <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
//             <Link to={pages[current-1]}> Previous</Link>
           
//             </Button>
//         )}
//       </div>
//     </>
//   );
// };


// export default Planification;


import React, {useState} from 'react';
import { Steps, Button, message } from 'antd';
import { Link } from 'react-router-dom';
import { Route } from 'react-router-dom';

//import {  NavLink, useNavigate } from 'react-router-dom';
import BaseRouter from '../routes';
import Config from '../pages/config';
import Actions from '../pages/actionsS';
import Kpis from '../pages/kpis';
import Optimisations from '../pages/optimisations';
import Planning from '../pages/planning';
import Practices from '../pages/practicesS';
import './step.css'; 
import { useTranslation } from 'react-i18next';
const { Step } = Steps;
 
 

 
const Planification = () => {


  const {t,i18n}=useTranslation()
  const [navi, setNavi]=React.useState(0);
  const [current, setCurrent] = React.useState(navi);
  const pages =['/config','/kpis','/optimisations', 'actions', 'practices', 'planning'];
 
 // const navigate=useNavigate()
  const next = () => {
    setCurrent(current + 1);
   
   
  };
 

 
  const steps = [
    {
      title: t('Configuration'),
      content: 'First-step',
    },
    {
      title: 'Simulation',
      content: 'Next-step',
    },
    {
      title: 'Optimisation',
      content: 'Next-step',
    },
   
    {
      title: 'Actions',
      content: 'Next-step',
    },
    {
      title: 'Best practices',
      content: 'Next-step',
    },
    {
      title: 'Planning',
      content: 'Last-step',
    },
   
   
   
  ];


  const prev = () => {
    setCurrent(current - 1);
 
   
  };
 
  return (
  
    <div className="steps-container">
      <div className="steps-content">
        {current === 0 ? <Config/> :
          current === 1 ? <Kpis/> :
          current === 2 ? <Optimisations/> :
          current === 3 ? <Actions/> :
          current === 4 ? <Practices/> :
          <Planning/>
        }
      </div>
      <br></br>
      <br />  <div className="steps-action">
      <Steps current={current}>
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <br />
    
        {current > 0 && (
          <Button style={{ margin: '10 8px' }} onClick={() => prev()}>
           {t('Previous')}
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => { next(); }}>
            {t('Next')}
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={() => message.success('Processing complete!')}>
            Generate
          </Button>
        )}
      </div>
      </div>
    
  );
};
 
 
 
export default Planification;

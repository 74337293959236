import React, { useState, useEffect } from "react";
import SimulationForm from "../components/FormSimulation";
import axios from "axios";
import "antd/dist/antd.min.css";
//import "antd/dist/antd.min.css";
//import '~antd/dist/antd.less';
import { Dropdown, Menu } from "antd";
import { InfoCircleOutlined, InfoCircleTwoTone } from "@ant-design/icons";

function Kpis() {
  const [configurations, setConfigurations] = useState([]);
  const [showDetails, setShowDetails] = useState(false);

  const fetchUsers = async () => {
    const res = await axios.get(`https://www.sptapp.eu/api/v1/`);

    setConfigurations(res.data);
    console.log(setConfigurations);
  };

  useEffect(
    () => {
      fetchUsers(configurations);
    },
    [],
    console.log(configurations),

    <div>
      <Dropdown
        overlay={
          <Menu>
            <div>
              {" "}
              {configurations.map((items) => (
                <Menu.Item
                  style={{ minWidth: 0, flex: "auto" }}
                  key={items.configurationID}
                >
                  {" "}
                  Configuration {String(items.configurationID)}
                </Menu.Item>
              ))}{" "}
            </div>
          </Menu>
        }
        trigger={["click"]}
      >
        <button
          className="ant-dropdown-link"
          onClick={(e) => e.preventDefault()}
        >
          Configuration ID
        </button>
      </Dropdown>
    </div>
  );

  return (
    <div>
      <SimulationForm
        requestType="get"
        simulationID={null}
        btnText="Run simulation"
      />
      <br></br>

      <br></br>
    </div>
  );
}

export default Kpis;

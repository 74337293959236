import React from "react";
import PracticeList from "../containers/PracticeListView";

function Practices() {

    return ( 
        <div style={{width: '170vh', marginBottom: 10, padding: 5}}>
        <PracticeList/> 
        </div>
    )
}

export default Practices;